
export default function FooterSmall(props) {
  return (
    <>
      <footer
        className={
          (props.absolute
            ? "absolute w-full bottom-0 bg-slate-800"
            : "relative") + " pb-6"
        }
      >
        <div className="container px-4 mx-auto">
          <hr className="mb-6 border-b-1 border-slate-600" />
          <div className="flex flex-wrap items-center justify-center md:justify-around">
            <div className="px-4">
              <div className="py-1 text-sm font-semibold text-center text-white">
                Made with <i className="fas fa-heart"></i> by Brandon
              </div>
            </div>
            <div className="px-4">
              <ul className="flex flex-wrap justify-center list-none md:justify-end">
                <li>
                  <a
                    href="#"
                    className="block px-3 py-1 text-sm font-semibold text-white hover:text-gray-400"
                  >
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block px-3 py-1 text-sm font-semibold text-white hover:text-gray-400"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block px-3 py-1 text-sm font-semibold text-white hover:text-gray-400"
                  >
                    Code of Conduct
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
