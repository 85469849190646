import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const PlayerSquads = ({ squads }) => {
    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="card-body">
                <h2 className="text-white card-title">Teams</h2>
                {squads.isSuccess &&
                    <>
                        {squads.data.response.length > 0 &&
                            <>
                                {squads.data.response.map(team => (
                                    <Link to={`../team/${team.team.id}`} key={team.team.id}>
                                        <div className="border-b-2 border-slate-800">
                                            <div className="flex flex-row items-center justify-between px-3 py-5 rounded-xl hover:bg-slate-800">
                                                <div className="flex flex-row items-center">
                                                    <img src={team.team.logo} alt="Team" className="w-8 mr-2" />
                                                    {team.team.name}
                                                </div>
                                                <div>
                                                    {team.players[0].position}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </>
                        }
                    </>
                }
                {squads.isLoading &&
                    <Skeleton count={2} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div>
    );
}

export default PlayerSquads;