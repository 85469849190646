import Chart from "chart.js";
import { useEffect } from "react";

const RadarChart = ({ labels, data, id }) => {

    useEffect(() => {
        var config = {
            type: "radar",
            data: {
                labels: labels,
                datasets: [{
                    label: 'Home',
                    data: data.home,
                    fill: true,
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgb(255, 99, 132)',
                    pointBackgroundColor: 'rgb(255, 99, 132)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(255, 99, 132)'
                }, {
                    label: 'Away',
                    data: data.away,
                    fill: true,
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgb(54, 162, 235)',
                    pointBackgroundColor: 'rgb(54, 162, 235)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(54, 162, 235)'
                }]
            },
            options: {
                legend: {
                    labels: {
                        fontColor: "white",
                        fontSize: 12
                    }
                },
                scale: {
                    gridLines: {
                        color: 'white'
                    },
                    pointLabels: {
                        fontColor: "white",
                    },
                    ticks: {
                        display: false
                    }
                },
                tooltips: {
                    callbacks: {
                        title: (items, data) => {
                            if (!items.length) {
                                return '';
                            }
                            return `${data.labels[items[0].index]}`
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            }
        };

        var ctx = document.getElementById(id).getContext("2d");
        new Chart(ctx, config);

    }, []);

    return (
        <div className="h-96">
            <canvas id={id}></canvas>
        </div>
    );
}

export default RadarChart;