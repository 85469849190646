import { auth } from "firebase-configs/config";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createUserEmailPassword, redirectResult, signInGoogle } from '../../firebase-configs/auth';
var filter = require('leo-profanity');

export default function Register() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [receiveNews, setReceiveNews] = useState(false);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        redirectResult(() => navigate('/'), () => navigate('/auth/additionalinfo'));
    }, []);

    const submitRegistration = async (e) => {
        e.preventDefault();
        if (filter.check(username) || filter.check(name)) {
            toast.error("Please check your username. Profanity is not allowed in usernames.")
            return;
        }
        setLoading(true);
        try {
            await createUserEmailPassword(name, email, password, username, receiveNews);
            setLoading(false);
            navigate('/');
        } catch (error) {
            setLoading(false);
            if (error.code === "auth/email-already-in-use") {
                let providers = await fetchSignInMethodsForEmail(auth, email);
                if (providers.includes("google.com")) {
                    toast.error("You've signed in with Google previously. Please use Google sign in.");
                } else {
                    toast.error("That email already exists. Did you mean to login?")
                }
            }
        }
    }

    return (
        <>
            <div className="container h-full px-4 py-4 mx-auto">
                <div className="flex items-center content-center justify-center h-full">
                    <div className="w-full px-4 lg:w-6/12">
                        <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-slate-200">
                            <div className="px-6 py-6 mb-0 rounded-t">
                                <div className="mb-3 text-center">
                                    <h6 className="text-sm font-bold text-slate-500">
                                        Sign up with
                                    </h6>
                                </div>
                                <div className="text-center btn-wrapper">
                                    <button
                                        className="inline-flex items-center px-4 py-2 mb-1 mr-1 text-xs font-bold uppercase transition-all duration-150 ease-linear bg-white rounded shadow outline-none active:bg-slate-50 text-slate-700 focus:outline-none hover:shadow-md"
                                        type="button"
                                        onClick={(() => signInGoogle())}
                                    >
                                        <img
                                            alt="..."
                                            className="w-5 mr-1"
                                            src={require("assets/img/google.svg").default}
                                        />
                                        Google
                                    </button>
                                </div>
                                <hr className="mt-6 border-b-1 border-slate-300" />
                            </div>
                            <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
                                <div className="mb-3 font-bold text-center text-slate-400">
                                    <small>Or sign up with credentials</small>
                                </div>
                                <form
                                    onSubmit={submitRegistration}>
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block mb-2 text-xs font-bold uppercase text-slate-600"
                                            htmlFor="grid-password"
                                        >
                                            Full Name
                                        </label>
                                        <input
                                            required
                                            autoComplete="name"
                                            type="text"
                                            className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-slate-300 text-slate-600 focus:outline-none focus:ring"
                                            placeholder="Your full name..."
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        {filter.check(name) &&
                                            <label className="label">
                                                <span className="text-red-600 label-text-alt">Try something else...</span>
                                            </label>
                                        }
                                    </div>

                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block mb-2 text-xs font-bold uppercase text-slate-600"
                                            htmlFor="grid-password"
                                        >
                                            Username
                                        </label>
                                        <input
                                            required
                                            type="text"
                                            className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-slate-300 text-slate-600 focus:outline-none focus:ring"
                                            placeholder="Create a username..."
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                        <label className="label">
                                            <span className="label-text-alt"><i className="mr-1 fas fa-info-circle"></i>Usernames are <strong>not</strong> unique</span>
                                        </label>
                                        {filter.check(username) &&
                                            <label className="label">
                                                <span className="text-red-600 label-text-alt">Try something else...</span>
                                            </label>
                                        }
                                    </div>

                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block mb-2 text-xs font-bold uppercase text-slate-600"
                                            htmlFor="grid-password"
                                        >
                                            Email
                                        </label>
                                        <input
                                            required
                                            autoComplete="username"
                                            id="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            type="email"
                                            className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-slate-300 text-slate-600 focus:outline-none focus:ring"
                                            placeholder="Your email address..."
                                        />
                                    </div>

                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block mb-2 text-xs font-bold uppercase text-slate-600"
                                            htmlFor="grid-password"
                                        >
                                            Password
                                        </label>
                                        <input
                                            required
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            type="password"
                                            autoComplete="new-password"
                                            className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-slate-300 text-slate-600 focus:outline-none focus:ring"
                                            placeholder="Create a password..."
                                        />
                                    </div>
                                    <div className="my-1">
                                        <label className="inline-flex items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                value={receiveNews}
                                                onChange={(e) => setReceiveNews(e.target.checked)}
                                                className="w-5 h-5 ml-1 transition-all duration-150 ease-linear border-0 rounded form-checkbox text-slate-700"
                                            />
                                            <span className="ml-2 text-sm font-semibold text-slate-600">
                                                Receive occasional site news and promotions
                                            </span>
                                        </label>
                                    </div>
                                    <div className="mt-6 text-center">
                                        <button
                                            className="flex items-center justify-center w-full px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-slate-800 active:bg-slate-600 hover:shadow-lg focus:outline-none"
                                            type="submit"
                                            disabled={filter.check(name) || filter.check(username) || loading}
                                        >
                                            Create Account
                                            {loading &&
                                                <span className="my-auto ml-2 loading loading-spinner loading-sm"></span>
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="relative flex flex-wrap mt-6">
                            <div className="w-1/2">
                                <Link to="/auth/login" className="text-slate-200">
                                    <small>Sign in</small>
                                </Link>
                            </div>
                            <div className="w-1/2 text-right">
                                <Link to="/auth/forgotpassword" className="text-slate-200">
                                    <small>Forgot password?</small>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
