import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { db } from "firebase-configs/config";
import { collection, query, where } from 'firebase/firestore';
import Countdown from '../Other/Countdown';

const Countdowns = () => {

    const countdowns = useFirestoreQueryData("football_countdowns", query(collection(db, "countdowns"), where('type', '==', "football"), where('status', '==', "active")), { subscribe: true, idField: "_id" });

    return (
        <>
            {countdowns.isSuccess &&
                <>
                    <div className='ml-2 text-xl font-semibold text-white'>
                        Upcoming Events
                    </div>
                    <div className="flex gap-5 mt-3 mb-5 overflow-x-scroll overflow-y-hidden scrollbar-hide md:flex-wrap">
                        {countdowns.data.map(countdown => (
                            <Countdown key={countdown._id} title={countdown.title} date={countdown.date.toDate()} />
                        ))}
                    </div>
                </>
            }
        </>
    );
}

export default Countdowns;