import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { db } from "firebase-configs/config";
import { collection, query, where } from "firebase/firestore";
import { fetchFootballData } from "js/common";
import { UserContext } from "layouts/App";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useQueries } from "react-query";
import { Link } from "react-router-dom";

export default function Pools() {

    const user = useContext(UserContext);

    const pools = useFirestoreQuery(["pools", user?.userFirebase?.uid], query(collection(db, "pools"), where('userId', '==', user.userFirebase.uid)), {}, { enabled: !!user?.userFirebase?.uid });

    const leagues = useQueries(pools?.data?.docs?.map(pools => {
        let data = pools.data();
        return {
            queryKey: [user.userFirebase.uid, data.leagueId],
            queryFn: () => fetchFootballData('leagues', `?id=${data.leagueId}`),
        }
    }) || []);

    return (
        <div className="relative text-white">
            <Helmet>
                <title>Pools</title>
            </Helmet>
            <div className="flex">
                <span className="text-3xl font-semibold">Pools</span>
            </div>
            <div className="mt-24">
                <Link to="../pools/createapool">
                    <button className="text-white btn bg-violet-600">Create new Pool</button>
                </Link>
            </div>
            <div className="flex flex-col mt-10">
                <span className="mb-4 text-lg font-semibold">My Pools</span>
                {pools.isSuccess && leagues.every(item => item.isSuccess) &&
                    pools.data.docs.map((pool, index) => {
                        let data = pool.data();
                        let league = leagues[index];
                        return (
                            <Link to={`../pools/createapool/${league.data.response[0].league.id}`}>
                                <div className="border-b border-b-slate-400" key={pool.id}>
                                    <div className="flex items-center w-full py-2 cursor-pointer hover:bg-slate-700 rounded-xl">
                                        <img src={league.data.response[0].league.logo} className="mr-2 w-14" alt="League Logo" />
                                        <span>{league.data.response[0].league.name}</span>
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
            </div>
        </div>
    );
}