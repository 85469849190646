import { auth, db } from "firebase-configs/config";
import { EmailAuthProvider, GoogleAuthProvider, fetchSignInMethodsForEmail, linkWithCredential, reauthenticateWithCredential, reauthenticateWithPopup, sendEmailVerification, updateEmail, updatePassword } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { UserContext } from "layouts/App";
import { useContext, useRef, useState } from "react";
import { toast } from "react-toastify";
var filter = require('leo-profanity');


export default function CardSettings() {

    const user = useContext(UserContext);

    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");

    const [newPassword, setNewPassword] = useState("");

    const [openSignInModal, setOpenSignInModal] = useState(false);

    const modalSubmit = useRef();
    const modalCancel = useRef();
    const currentPassword = useRef();

    const promiseModal = () => {
        setOpenSignInModal(true);
        let password = currentPassword.current.value;
        return new Promise((resolve, reject) => {
            modalSubmit.current.addEventListener("submit", (e) => { e.preventDefault(); setOpenSignInModal(false); currentPassword.current.value = ''; resolve(password); });
            modalCancel.current.addEventListener("click", () => { setOpenSignInModal(false); reject(); });
        });
    }

    const reauthenticate = async () => {
        try {
            const providers = await fetchSignInMethodsForEmail(auth, user.userFirebase.email);
            if (providers.includes("password")) {
                let password = await promiseModal();
                await reauthenticateWithCredential(user.userFirebase, EmailAuthProvider.credential(
                    user.userFirebase.email,
                    password
                ));
            } else {
                await reauthenticateWithPopup(user.userFirebase, new GoogleAuthProvider());
            }
        } catch (error) {
            if (error?.code === "auth/wrong-password") {
                toast.error("That password is incorrect. Please try again.");
            }
            throw error;
        }
    }

    const handleUpdatePassword = async (e) => {
        e?.preventDefault();
        if (newPassword) {
            try {
                const providers = await fetchSignInMethodsForEmail(auth, user.userFirebase.email);
                //if they have a password provider, then simply update the password
                if (providers.includes("password")) {
                    await updatePassword(user.userFirebase, newPassword);
                    toast.success("Your password has been changed successfully!")
                } else {
                    //they do not have a password credential. Link the new password to the account
                    await linkWithCredential(user.userFirebase, EmailAuthProvider.credential(
                        user.userFirebase.email,
                        newPassword
                    ));
                    toast.success("This password has been successfully linked to your account!");
                    setNewPassword("");
                }
            } catch (error) {
                if (error.code === "auth/requires-recent-login") {
                    await reauthenticate();
                    await handleUpdatePassword();
                }
            }
        }
    }

    const handleUpdateEmail = async (e) => {
        e?.preventDefault();
        if (email) {
            try {
                await updateEmail(user.userFirebase, email);
                sendEmailVerification(user.userFirebase);
                toast.success("Your email has been successfully updated!");
                setEmail("");
            } catch (error) {
                if (error.code === "auth/requires-recent-login") {
                    await reauthenticate();
                    await handleUpdateEmail();
                }
                toast.error("There was a problem updating your email. Please try again.");
            }
        }
    }

    const handleUpdateUsername = async (e) => {
        e.preventDefault();
        if (filter.check(username)) {
            toast.error("Please check your username. Profanity is not allowed in usernames.")
            return;
        }
        try {
            await updateDoc(doc(db, "users", user.userFirebase.uid), {
                username: username
            });
            toast.success("Your username was successfully updated!");
        } catch (error) {
            toast.error("There was an error updating your username");
        } finally {
            setUsername("");
        }
    }

    return (
        <>
            <div className="relative flex flex-col w-full min-w-0 mb-6 text-white break-words border-0 rounded-lg shadow-lg bg-slate-900">
                <div className="px-6 py-6 mb-0 rounded-t bg-slate-700">
                    <div className="flex justify-between text-center">
                        <h6 className="text-xl font-bold">{user.userFirebase.displayName}</h6>
                    </div>
                </div>
                <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
                    <h6 className="mt-3 mb-6 text-sm font-bold uppercase">
                        User Information
                    </h6>
                    <div className="flex flex-wrap gap-9">
                        <form className="flex w-full mt-3" onSubmit={handleUpdateUsername}>
                            <div className="w-full px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block mb-2 text-xs font-bold uppercase"
                                        htmlFor="grid-password"
                                    >
                                        Username
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear border-0 rounded shadow placeholder-slate-300 bg-slate-700 focus:outline-none focus:ring disabled:bg-slate-500 disabled:cursor-not-allowed"
                                        value={username}
                                        placeholder={user.userFirestore?.username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                    />
                                    <button className="mt-2 text-xs text-white btn btn-sm bg-sky-700 hover:bg-sky-800" disabled={filter.check(username)}>Update Username</button>
                                    {filter.check(username) &&
                                        <label className="label">
                                            <span className="text-red-600 label-text-alt">Try something else...</span>
                                        </label>
                                    }
                                </div>
                            </div>
                        </form>
                        {user.userFirebase.providerData.find(provider => provider.providerId === 'password') &&
                            <div className="w-full px-4">
                                <div className="relative w-full mb-3">
                                    <form
                                        onSubmit={handleUpdateEmail}
                                    >
                                        <label
                                            className="block mb-2 text-xs font-bold uppercase"
                                            htmlFor="grid-password"
                                        >
                                            Email address
                                        </label>
                                        <input
                                            type="email"
                                            value={email}
                                            className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear border-0 rounded shadow placeholder-slate-300 bg-slate-700 focus:outline-none focus:ring disabled:bg-slate-500 disabled:cursor-not-allowed"
                                            placeholder={user.userFirebase.email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        {!user.userFirebase.emailVerified &&
                                            <label className="label">
                                                <span className="italic label-text-alt">
                                                    Unverified Email
                                                </span>
                                            </label>
                                        }

                                        <button className="mt-2 text-xs text-white btn btn-sm bg-sky-700 hover:bg-sky-800" type="submit">Change Email</button>
                                    </form>
                                </div>
                            </div>
                        }
                        <form
                            className="flex w-full"
                            onSubmit={handleUpdatePassword}
                        >
                            <div className="w-full px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block mb-2 text-xs font-bold uppercase"
                                        htmlFor="grid-password"
                                    >
                                        New Password
                                    </label>
                                    <input
                                        type="email"
                                        hidden
                                        autoComplete="username"
                                    />
                                    <input
                                        type="password"
                                        autoComplete="new-password"
                                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear border-0 rounded shadow placeholder-slate-300 bg-slate-700 focus:outline-none focus:ring disabled:bg-slate-500 disabled:cursor-not-allowed"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required
                                    />
                                    <button className="mt-2 text-xs text-white btn btn-sm bg-sky-700 hover:bg-sky-800">
                                        {user.userFirebase.providerData.length === 1 && !user.userFirebase.providerData.find(provider => provider.providerId === 'password') ?
                                            <>Add Password</>
                                            :
                                            <>Update Password</>}
                                    </button>
                                </div>
                            </div>
                        </form>
                        {user.userFirebase.providerData.length === 1 && !user.userFirebase.providerData.find(provider => provider.providerId === 'password') &&
                            <p className="ml-4 text-xs">You account is currently configured to sign in using a third party sign-in method. You can provide a password now to also enable email and password sign-in in addition to your third party sign in.</p>
                        }
                        {user.userFirebase.providerData.length > 1 &&
                            <p className="ml-4 text-xs">Please note you have multiple methods of signing in configured.</p>
                        }
                    </div>
                </div>
            </div >

            <div className={`text-white modal ${openSignInModal ? "modal-open" : ""}`}>
                <div className="modal-box">
                    <h3 className="text-lg font-bold">Sign In</h3>
                    <p className="py-4">Since your changing sensitive info, please re-enter your password.</p>
                    <div className="flex flex-col">
                        <form id="signInForm" ref={modalSubmit}>
                            <label className="label">
                                <span className="label-text">Password</span>
                            </label>
                            <input
                                type="email"
                                hidden
                                autoComplete="username"
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                className="w-full input input-bordered"
                                autoComplete="current-password"
                                required
                                ref={currentPassword}
                            />
                        </form>
                    </div>
                    <div className="modal-action">
                        <button className="btn" form="signInForm" type="submit" ref={modalCancel}>
                            Close
                        </button>
                        <button className="btn" form="signInForm" type="submit">
                            Sign in
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
