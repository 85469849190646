import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { db } from "firebase-configs/config";
import { addDoc, collection, getCountFromServer, limit, orderBy, query, serverTimestamp, where } from "firebase/firestore";
import { getFCMToken } from "js/common";
import { UserContext } from "layouts/App";
import moment from "moment";
import { useContext, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Forum = () => {

    const { categoryId } = useParams();
    const user = useContext(UserContext);

    const forumPosts = useFirestoreQueryData(["forum_posts", categoryId], query(collection(db, "forum_posts"), where('categoryId', '==', categoryId), orderBy('createdOn', 'desc'), limit(20)), { subscribe: true, idField: '_id' });

    const [newPostOpen, setNewPostOpen] = useState(false);

    const title = useRef();
    const description = useRef();
    const receiveNotification = useRef();

    const submitPost = async (event) => {
        event.preventDefault();
        if (!user || !title || !user.userFirestore?.username) return;
        if (receiveNotification.current.checked) {
            if (!await getFCMToken(user)) {
                return;
            }
        }
        try {
            setNewPostOpen(false);
            await addDoc(collection(db, "forum_posts"), {
                title: title.current.value,
                ...(description.current.value) && { text: description.current.value },
                categoryId: categoryId,
                createdOn: serverTimestamp(),
                userId: user.userFirebase.uid,
                author: user.userFirestore.username,
                receiveNotifications: receiveNotification.current.checked
            });
            title.current.value = '';
            description.current.value = '';
            receiveNotification.current.checked = false;
            toast.success("Your post has been successfully submitted!")
        } catch (error) {
            toast.error("There was a problem submitting your post. Please try again.")
        }
    };

    const ForumCount = useQuery(['ForumCount', categoryId], () => getCountFromServer(query(collection(db, "forum_posts"), where("categoryId", "==", categoryId))));
    const CommentsCount = useQuery(['ForumCommentsCount', categoryId], () => getCountFromServer(query(collection(db, "forum_posts_comments"), where("categoryId", "==", categoryId))));

    return (
        <div className="relative px-6 text-xl md:text-base 2xl:px-32 3xl:px-64">
            {/* <div className="w-full my-3 text-center">
                <span className="text-2xl font-bold text-white md:text-5xl">Welcome to the&nbsp;
                    <span className="bg-gradient-to-r from-orange-300 via-pink-500 to-orange-300 bg-clip-text bg-2xl animate-background-pan" style={{ WebkitTextFillColor: 'transparent' }}>
                        kicks.football
                    </span>
                    &nbsp;forums!
                </span>
            </div> */}
            <div className="flex flex-col gap-10 text-white">
                <div className="">
                    <span className="text-3xl font-semibold">
                        {
                            {
                                "general": "General Discussion",
                                "matches": "Matches",
                                "players": "Players",
                                "leaguesandcups": "Leagues & Cups",
                                "support": "Support",
                                "featuresuggestions": "Feature Suggestions"
                            }[categoryId]
                        }
                    </span>
                    <div className="flex flex-row mt-1">
                        <div className="flex flex-row items-center justify-center h-8 mr-2 text-sm bg-slate-800 w-36 rounded-xl">
                            <span className="font-bold">
                                {ForumCount.isSuccess &&
                                    ForumCount.data.data().count
                                }
                                &nbsp;post
                            </span>
                        </div>
                        <div className="flex flex-col items-center justify-center h-8 ml-2 text-sm bg-slate-800 w-36 rounded-xl">
                            <span className="font-bold">
                                {CommentsCount.isSuccess &&
                                    CommentsCount.data.data().count
                                }
                                &nbsp;comments
                            </span>
                        </div>
                    </div>
                    <div>
                        {/* <div className="justify-between mt-5 navbar bg-slate-800 rounded-xl">
                            <div className="flex">
                                <div className="form-control">
                                    <input type="text" placeholder="Search" className="text-white placeholder-gray-300 bg-transparent border-white input input-bordered" />
                                </div>
                            </div>
                            <div className="flex">
                                <div className="px-3 md:hidden">
                                    <label htmlFor="sort-by-modal">
                                        <i className="fas fa-sort-amount-down"></i>
                                    </label>
                                </div>
                                <div className="hidden dropdown dropdown-end md:block">
                                    <label tabIndex={0} className="m-1 text-white border-none btn bg-violet-500"><i className="fas fa-sort-amount-down"></i>Filter</label>
                                    <ul tabIndex={0} className="p-2 shadow dropdown-content menu bg-base-100 rounded-box w-52">
                                        <li><a className="active:bg-violet-500">Default</a></li>
                                        <li><a className="active:bg-violet-500">Newest to Oldest</a></li>
                                        <li><a className="active:bg-violet-500">Oldest to Newest</a></li>
                                        <li><a className="active:bg-violet-500">Most Comments</a></li>
                                    </ul>
                                </div>
                                <input type="checkbox" id="sort-by-modal" className="modal-toggle" />
                                <div className="modal modal-bottom sm:modal-middle">
                                    <div className="text-black bg-white modal-box">
                                        <ul className="w-full p-2">
                                            <li className="py-2 border-b-2 border-b-slate-100"><a className="active:bg-violet-500">Default</a></li>
                                            <li className="py-2 border-b-2 border-b-slate-100"><a className="active:bg-violet-500">Newest to Oldest</a></li>
                                            <li className="py-2 border-b-2 border-b-slate-100"><a className="active:bg-violet-500">Oldest to Newest</a></li>
                                            <li className="py-2 border-b-2 border-b-slate-100"><a className="active:bg-violet-500">Most Comments</a></li>
                                        </ul>
                                        <div className="modal-action">
                                            <label htmlFor="sort-by-modal" className="btn">Close</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="mt-4">
                            <div className="ml-auto tooltip tooltip-bottom" data-tip={!user ? "You need to be signed in to Post" : !user.userFirestore?.username ? "You need to set a username before you can post" : "Create a new post"}>
                                <button
                                    className="text-white border-none btn bg-violet-500"
                                    onClick={() => setNewPostOpen(true)}
                                    disabled={!user || !user.userFirestore?.username}
                                >
                                    New Post
                                </button>
                            </div>

                            <div className={`modal modal-bottom sm:modal-middle ${newPostOpen ? "modal-open" : ""}`}>
                                <div className="modal-box !max-w-none !w-[800px]">
                                    <h3 className="text-lg font-bold">Create a Post</h3>
                                    <form className="mt-4" onSubmit={(e) => submitPost(e)}>
                                        <label className="label">
                                            <span className="text-white label-text">Title<span className="text-red-500">*</span></span>
                                        </label>
                                        <input type="text" placeholder="What is this post about?" className="w-full text-white placeholder-gray-400 input input-bordered" ref={title} required />
                                        <label className="label">
                                            <span className="text-white label-text">Description</span>
                                        </label>
                                        <textarea rows={5} className="w-full text-base text-white placeholder-gray-400 textarea textarea-bordered" placeholder="Bio" ref={description}></textarea>
                                        <label className="justify-start inline my-1 cursor-pointer label">
                                            <input type="checkbox" className="checkbox checkbox-primary" ref={receiveNotification} />
                                            <span className="ml-3 label-text">Get notified when someone responds to your post</span>
                                        </label>
                                        <div className="modal-action">
                                            <button type="button" className="text-white bg-red-400 btn" onClick={() => {
                                                setNewPostOpen(false);
                                                title.current.value = '';
                                                description.current.value = '';
                                                receiveNotification.current.checked = false;
                                            }}>
                                                Cancel
                                            </button>
                                            <button type="submit" className="text-white btn bg-violet-500">
                                                Post
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full">
                    {forumPosts.isSuccess &&
                        <>
                            {forumPosts.data.length > 0 &&
                                <>
                                    {forumPosts.data.map((doc) => (
                                        <div className="flex py-2 border-b border-gray-400" key={doc._id}>
                                            <div className="w-full md:w-7/12">
                                                <Link to={`../${categoryId}/${doc._id}`} relative="path">
                                                    <span className="font-bold md:text-lg hover:underline">{doc.title}</span>
                                                </Link>
                                                <p className="mt-2 text-base text-white truncate">posted by&nbsp;
                                                    <span className="font-semibold text-lg bg-gradient-to-r from-pink-500 via-blue-500 to-pink-500 bg-clip-text bg-2xl animate-background-pan [-webkit-text-fill-color:transparent]">
                                                        {doc.author}
                                                    </span>
                                                    <span className="font-semibold">
                                                        &nbsp;on {moment(doc.createdOn?.toDate()).format('LLL')}
                                                    </span>
                                                </p>
                                                <p className="text-base truncate">{doc.text}</p>
                                            </div>
                                            {/* <div className="flex-row justify-end hidden mr-3 md:w-2/12 md:flex">
                                            <div className="flex flex-col items-center justify-center w-20 h-20 mx-2 text-sm rounded-xl">
                                                <span className="text-lg font-bold">18.8K</span>
                                                <span>comments</span>
                                            </div>
                                        </div>
                                        <div className="hidden md:block md:w-3/12">
                                            <div className="h-20 border-l-2 border-l-red-300">
                                                <div className="flex flex-col ml-5">
                                                    <span className="text-sm text-slate-300">Username 30 minutes ago</span>
                                                    <span className="truncate">thats acutally crazy tho...</span>
                                                </div>
                                            </div>
                                        </div> */}
                                        </div>
                                    ))}
                                </>
                            }
                            {forumPosts.data.length === 0 &&
                                <div className="flex items-center justify-center min-h-[100px]">
                                    <span className="text-sm text-gray-400">Nothing yet... Post something!</span>
                                </div>
                            }
                        </>
                    }
                    {forumPosts.isLoading &&
                        <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                    }
                    {forumPosts.isError &&
                        <div className="flex items-center justify-center min-h-[100px]">
                            <span className="text-sm text-gray-400">Sorry, something went wrong getting this data</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Forum;