import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { db } from "firebase-configs/config";
import { collection, query, where } from "firebase/firestore";
import Banner from '../Headers/Banner';


const WebsiteNotifications = () => {

    const websiteNotifications = useFirestoreQuery(["website_notifications"], query(collection(db, "website_notifications"), where("status", '==', 'active')), { subscribe: true });

    return (
        <>
            {websiteNotifications.isSuccess && websiteNotifications.data.size > 0 &&
                <>
                    {websiteNotifications.data.docs.map(notification => (
                        <Banner key={notification.id} content={notification.data().html} color={notification.data().color} />
                    ))}
                </>
            }
        </>

    );
}

export default WebsiteNotifications;