import FootballFieldImg from "assets/img/Football_field.svg";
import GoalImg from "assets/img/timeline/ball.svg";
import RedCardImg from "assets/img/timeline/red-card.svg";
import YellowCardImg from "assets/img/timeline/yellow-card.svg";

const FootballLineup = ({ lineups, events, players, hoveredPlayer }) => {

    //group the teams by position (array of arrays)
    const homeTeamConfig = Object.values(lineups[0].startXI?.reduce((acc, item) => {
        // Append the item to the array for each country
        acc[item.player.grid?.substring(0, 1)] = [...(acc[item.player.grid?.substring(0, 1)] || []), item];
        return acc;
    }, {}) || []);

    const awayTeamConfig = Object.values(lineups[1].startXI?.reduce((acc, item) => {
        // Append the item to the array for each country
        acc[item.player.grid?.substring(0, 1)] = [...(acc[item.player.grid?.substring(0, 1)] || []), item];
        return acc;
    }, {}) || []);

    return (
        <>
            {homeTeamConfig.length && awayTeamConfig.length && homeTeamConfig[0].length !== 11 && homeTeamConfig[1].length !== 11 &&
                <div className="flex items-center justify-center h-full my-auto">
                    <div
                        className="flex flex-col bg-contain h-[420px] w-[280px] 2xl:h-[628px] 2xl:w-[420px] bg-no-repeat border-2 border-slate-300"
                        style={{ backgroundImage: `url(${FootballFieldImg})` }}
                    >
                        <div className="flex h-[210px] 2xl:h-[314px] text-center flex-col">
                            {homeTeamConfig.map((positionGroup, index) => (
                                <div className="flex flex-row items-center justify-around w-full h-full" key={index}>
                                    {positionGroup.map(player => {
                                        { player.player.photo = players[0]?.players?.find(inner => inner.player.id === player.player.id)?.player?.photo || "https://media-2.api-sports.io/football/players/190650.png" }
                                        { player.player.events = (events.filter(inner => inner.player.id === player.player.id)) || [] }
                                        return (
                                            <div key={player.player.id}>
                                                <div className="tooltip" data-tip={player.player.name}>
                                                    <div
                                                        className={`w-9 h-9 2xl:w-12 2xl:h-12 bg-red-600 rounded-full mx-auto bg-contain border-2 border-slate-900 hover:scale-110 ${hoveredPlayer === player.player.id ? 'scale-110' : 0}`}
                                                        style={{ backgroundImage: `url(${player.player.photo})` }}
                                                    >
                                                        {player.player.events.find(event => event.detail === "Yellow Card") &&
                                                            <div className="absolute w-6 h-6 bg-contain bottom-5 left-4 2xl:bottom-6 2xl:left-6 2xl:w-8 2xl:h-8" style={{ backgroundImage: `url(${YellowCardImg})` }}></div>
                                                        }
                                                        {player.player.events.find(event => event.detail === "Red Card") &&
                                                            <div className="absolute w-6 h-6 bg-contain bottom-5 left-4 2xl:bottom-6 2xl:left-6 2xl:w-8 2xl:h-8" style={{ backgroundImage: `url(${RedCardImg})` }}></div>
                                                        }
                                                        {player.player.events.find(event => event.detail === "Normal Goal") &&
                                                            <div className="absolute w-4 h-4 bg-contain bottom-6 right-6 2xl:bottom-8 2xl:right-7 2xl:w-6 2xl:h-6" style={{ backgroundImage: `url(${GoalImg})` }}></div>
                                                        }
                                                        {player.player.events.find(event => event.type === "subst") &&
                                                            <i className="absolute w-6 h-6 text-red-500 top-6 right-4 2xl:top-9 2xl:right-7 fas fa-lg fa-long-arrow-alt-left"></i>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            ))}
                        </div>
                        <div className="flex h-[210px] 2xl:h-[314px] text-center flex-col">
                            {awayTeamConfig.reverse().map((positionGroup, index) => (
                                <div className="flex flex-row items-center justify-around w-full h-full" key={index}>
                                    {positionGroup.reverse().map(player => {
                                        { player.player.photo = (players[1]?.players?.find(inner => inner.player.id === player.player.id).player?.photo) || 'https://media-2.api-sports.io/football/players/190650.png' }
                                        { player.player.events = (events.filter(inner2 => inner2.player.id === player.player.id)) || [] }
                                        return (
                                            <div key={player.player.id}>
                                                <div className="tooltip" data-tip={player.player.name}>
                                                    <div
                                                        className={`w-9 h-9 2xl:w-12 2xl:h-12 bg-red-600 rounded-full mx-auto bg-contain border-2 border-slate-900 hover:scale-110 ${hoveredPlayer === player.player.id ? 'scale-110' : 0}`} style={{ backgroundImage: `url(${player.player.photo})` }}
                                                    >
                                                        {player.player.events.find(event => event.detail === "Yellow Card") &&
                                                            <div className="absolute w-6 h-6 bg-contain bottom-5 left-4 2xl:bottom-6 2xl:left-6 2xl:w-8 2xl:h-8" style={{ backgroundImage: `url(${YellowCardImg})` }}></div>
                                                        }
                                                        {player.player.events.find(event => event.detail === "Red Card") &&
                                                            <div className="absolute w-6 h-6 bg-contain bottom-5 left-4 2xl:bottom-6 2xl:left-6 2xl:w-8 2xl:h-8" style={{ backgroundImage: `url(${RedCardImg})` }}></div>
                                                        }
                                                        {player.player.events.find(event => event.detail === "Normal Goal") &&
                                                            <div className="absolute w-4 h-4 bg-contain animate-bounce bottom-6 right-6 2xl:bottom-8 2xl:right-7 2xl:w-6 2xl:h-6" style={{ backgroundImage: `url(${GoalImg})` }}></div>
                                                        }
                                                        {player.player.events.find(event => event.type === "subst") &&
                                                            <i className="absolute w-6 h-6 text-red-500 top-6 right-4 2xl:top-9 2xl:right-7 fas fa-lg fa-long-arrow-alt-left"></i>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default FootballLineup;