import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const Injuries = ({ injuries }) => {
    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="card-body">
                <h2 className="text-white card-title">Injuries</h2>
                {injuries.isSuccess &&
                    <>
                        {injuries.data.response.length > 0 &&
                            <>
                                <div className="columns-1 lg:columns-2 xl:columns-3 2xl:columns-4">
                                    {injuries.data.response.filter((v, i, a) => a.findIndex(i => i.player.id === v.player.id) === i).sort((a, b) => a.team.id - b.team.id).map(injury => (
                                        <Link to={`/football/player/${injury.player.id}`} key={injury.player.id}>
                                            <div className="text-white cursor-pointer hover:bg-slate-800 rounded-xl">
                                                <div className="flex items-center px-2 py-1">
                                                    <div className="w-2/12 mr-2">
                                                        <img className="w-10 rounded-full" src={injury.player.photo} alt="player-image" loading="lazy" />
                                                    </div>
                                                    <div className="flex flex-col w-7/12 truncate">
                                                        <span>{injury.player.name}</span>
                                                        <small><span className="font-semibold">{injury.player.reason}</span></small>
                                                    </div>
                                                    <div className="flex flex-row items-center justify-center w-3/12 text-right">
                                                        {injury.team.logo &&
                                                            <div className="tooltip" data-tip={injury.team.name}>
                                                                <img src={injury.team.logo} className="w-8 mr-1"></img>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </>
                        }
                        {injuries.data.response.length === 0 &&
                            <div className="flex items-center justify-center min-h-[100px]">
                                <span className="text-sm text-gray-400">No Data Available</span>
                            </div>
                        }
                    </>
                }
                {injuries.isLoading &&
                    <Skeleton count={5} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div>
    );
}

export default Injuries;