import { db } from "firebase-configs/config";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { confetti } from "js/common";
import { UserContext } from "layouts/App";
import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

const BugReport = () => {

    const [subject, setSubject] = useState("");
    const [page, setPage] = useState("");
    const [additionalInfo, setAdditionalInfo] = useState("");

    const user = useContext(UserContext);

    const submitBug = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, "bug_report"), {
                subject: subject,
                ...(page) && { page: page },
                ...(additionalInfo) && { description: additionalInfo },
                createdOn: serverTimestamp(),
                ...(user) && { userId: user.userFirebase.uid }
            });
            toast.success("Your bug report has been successfully submitted! Thanks! 🐛🪲🦗");
            setSubject("");
            setPage("");
            setAdditionalInfo("");
            confetti.addConfetti({
                emojis: ['🐛', '🪲', '🦗', '🦟', '🐜', '🐞'],
            });
        } catch (error) {
            toast.error("There was an error submitting you bug report. That is ironic. Maybe try again?");
        }
    }

    return (
        <div className="relative flex flex-col items-center justify-center w-full px-4 text-white">
            <Helmet>
                <title>Report a Bug</title>
            </Helmet>
            <div>
                <div className="flex flex-row items-center">
                    <h6 className="text-xl font-bold">Report a Bug</h6>
                    <span className="ml-2 text-2xl cursor-pointer" onClick={() => confetti.addConfetti({
                        emojis: ['🐛', '🪲', '🦗', '🦟', '🐜', '🐞'],
                    })}>🐞</span>
                </div>
                <p>Something not working correctly? League, player, match not loading? Let me know so I can fix it!</p>
            </div>
            <form className="w-8/12 mt-16" onSubmit={(e) => submitBug(e)}>
                <div className="w-full form-control">
                    <label className="label">
                        <span className="text-white label-text">What is not working correctly?<span className="text-red-500">*</span></span>
                    </label>
                    <input
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        type="text"
                        placeholder="Type here"
                        className="w-full text-white input input-bordered"
                        required
                    />
                </div>
                <div className="w-full mt-6 form-control">
                    <label className="label">
                        <span className="label-text">What page did you encouter the bug?</span>
                    </label>
                    <select className="select select-bordered" value={page} onChange={(e) => setPage(e.target.value)}>
                        <option disabled value={""}>Select an option</option>
                        <option value={"Dashboard"}>Dashboard</option>
                        <option value={"Player"}>Player</option>
                        <option value={"Match"}>Match</option>
                        <option value={"Pools"}>Pools</option>
                        <option value={"Forums"}>Forums</option>
                        <option value={"Report A Bug"}>Report a Bug (now that would be hilarious)</option>
                        <option value={"Other"}>Other</option>
                    </select>
                </div>
                <div className="mt-6 form-control">
                    <label className="label">
                        <span className="label-text">Additional Information</span>
                    </label>
                    <textarea value={additionalInfo} onChange={(e) => setAdditionalInfo(e.target.value)} className="h-24 textarea textarea-bordered" placeholder="Provide additional info..."></textarea>
                    <label className="label">
                        <span className="label-text-alt">If there is any additional information you'd like to provide to make my life easier. ie, specific match or player you found the bug on</span>
                    </label>
                </div>
                <div className="flex">
                    <button type="submit" className="ml-auto text-white btn btn-primary">Submit</button>
                </div>
            </form>
        </div>
    );
}

export default BugReport;