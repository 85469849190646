import { useFirestoreQuery } from "@react-query-firebase/firestore";
import HeadToHead from "components/Cards/Football/HeadToHead";
import LiveChat from "components/Cards/Football/LiveChat";
import MatchEvents from "components/Cards/Football/MatchEvents";
import MatchHeader from "components/Cards/Football/MatchHeader";
import MatchLineups from "components/Cards/Football/MatchLineups";
import MatchOdds from "components/Cards/Football/MatchOdds";
import MatchPlayerStats from "components/Cards/Football/MatchPlayerStats";
import MatchPredictions from "components/Cards/Football/MatchPredictions";
import MatchStatistics from "components/Cards/Football/MatchStatistics";
import MatchVideoHighlights from "components/Cards/Football/MatchVideoHighlights";
import { db } from "firebase-configs/config";
import { collection, orderBy, query, where } from "firebase/firestore";
import { fetchFootballData } from "js/common";
import { useQuery } from "react-query";
import { useParams } from "react-router";


const Match = () => {
    const { fixtureId } = useParams();

    const chatMessages = useFirestoreQuery(["chat_messages", fixtureId], query(collection(db, "chat_message"), where("fixture", "==", fixtureId), orderBy('createdOn', 'asc')), { subscribe: true });

    const match = useQuery(["match", fixtureId], () => fetchFootballData("fixtures", `?id=${fixtureId}`));
    const odds = useQuery(["odds", fixtureId], () => fetchFootballData("odds", `?fixture=${fixtureId}`));
    const predictions = useQuery(["predictions", fixtureId], () => fetchFootballData("predictions", `?fixture=${fixtureId}`));

    const headToHead = useQuery(["HeadToHead", fixtureId], () => fetchFootballData("fixtures/headtohead", `?h2h=${match.data.response[0].teams.home.id}-${match.data.response[0].teams.away.id}`), {
        enabled: match.isSuccess && match?.data?.response?.length > 0
    });

    return (
        <div className="flex flex-col px-4">
            {/* header start */}
            <div className="w-full" >
                <MatchHeader match={match} predictions={predictions} />
            </div>
            {/* start content */}
            <div className="flex flex-col 2xl:flex-row xl:my-4">
                <div className="w-full 2xl:w-8/12">
                    <div className="w-full my-2">
                        <MatchVideoHighlights match={match} />
                    </div>
                    <div className="flex flex-col my-4 xl:flex-row">
                        <div className="w-full my-2 xl:w-6/12 xl:mr-2 xl:my-0">
                            <MatchOdds match={match} odds={odds} />
                        </div>
                        <div className="w-full my-2 xl:w-6/12 xl:ml-2 xl:my-0">
                            <LiveChat chatMessages={chatMessages} relatedEntity={fixtureId} />
                        </div>
                    </div>
                    <div className="w-full my-4">
                        <MatchPredictions predictions={predictions} />
                    </div>
                    <div className="w-full my-4">
                        <MatchLineups match={match} />
                    </div>
                    <div className="w-full my-4">
                        <MatchStatistics match={match} />
                    </div>
                    <div className="w-full my-4">
                        <MatchPlayerStats match={match} />
                    </div>
                </div>
                <div className="flex flex-col 2xl:w-4/12 xl:ml-2">
                    <div className="w-full xl:w-6/12 2xl:w-full">
                        <MatchEvents match={match} />
                    </div>
                    <div className="w-full my-4 xl:w-6/12 2xl:w-full">
                        <HeadToHead headToHead={headToHead} />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Match;