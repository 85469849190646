import moment from "moment";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";

const PlayerHeader = ({ player, trophies, squads, transfers }) => {

    const generatePlayerDescription = () => {
        var playerData = player?.data.response?.[0];
        var trophiesData = trophies?.data?.response;
        var squadsData = squads?.data?.response;
        var transfersData = transfers?.data?.response?.[0];
        var description;
        if (playerData === null || playerData === undefined) {
            return;
        }
        if (playerData.player.birth.place !== null && playerData.player.birth.country !== null) {
            description = `${playerData.player.name} is a football player from ${playerData.player.birth.place}, ${playerData.player.birth.country}.`
        } else if (playerData.player.birth.place === null && playerData.player.birth.country === null) {
            description = `${playerData.player.name} is a football player.`
        } else if (playerData.player.birth.country !== null) {
            description = `${playerData.player.name} is a football player from ${playerData.player.birth.country}.`
        } else if (playerData.player.birth.place !== null) {
            description = `${playerData.player.name} is a football player from ${playerData.player.birth.place}.`
        }
        switch (squadsData.length) {
            case 2:
                if (squadsData[0].team.name === playerData.player.birth.country) {
                    description += ` They currently play for the ${squadsData[0].team.name} national team and ${squadsData[1].team.name} as ${squadsData[1].players[0].position === "Attacker" ? "an" : "a"} ${squadsData[1].players[0].position}.`
                } else {
                    description += ` They currently play for the ${squadsData[1].team.name} national team and ${squadsData[0].team.name} as ${squadsData[0].players[0].position === "Attacker" ? "an" : "a"} ${squadsData[0].players[0].position}.`
                }
                break;
            case 1:
                description += ` They currently play for ${squadsData[0].team.name} as ${squadsData[0].players[0].position === "Attacker" ? "an" : "a"} ${squadsData[0].players[0].position}.`
                break;
        }
        if (transfersData?.transfers.length > 0) {
            if (transfersData.transfers[0].type !== "Free" && transfersData.transfers[0].type !== "Loan" && transfersData.transfers[0].type !== "N/A") {
                description += ` They joined ${transfersData.transfers[0].teams.in.name} on ${moment(transfersData.transfers[0].date).format('LL')} for a transfer fee of ${transfersData.transfers[0].type}.`
            } else if (transfersData.transfers[0].type === "Free") {
                description += ` They joined ${transfersData.transfers[0].teams.in.name} on ${moment(transfersData.transfers[0].date).format('LL')} on a free transfer.`
            } else if (transfersData.transfers[0].type === "Loan") {
                description += ` They joined ${transfersData.transfers[0].teams.in.name} on ${moment(transfersData.transfers[0].date).format('LL')} on loan from ${transfersData.transfers[0].teams.out.name}.`
            } else if (transfersData.transfers[0].type === "N/A") {
                if (transfersData.transfers[1]?.type === "Loan") {
                    if (moment(transfersData.transfers[0].date) < moment()) {
                        description += ` They returned to ${transfersData.transfers[0].teams.in.name} on ${moment(transfersData.transfers[0].date).format('LL')} after being on loan to ${transfersData.transfers[0].teams.out.name} since ${moment(transfersData.transfers[1].date).format('LL')}.`
                    } else {
                        description += ` They joined ${transfersData.transfers[1].teams.in.name} on ${moment(transfersData.transfers[1].date).format('LL')} on loan from ${transfersData.transfers[1].teams.out.name}.`
                    }
                } else {
                    description += ` They joined ${transfersData.transfers[0].teams.in.name} on ${moment(transfersData.transfers[0].date).format('LL')}.`
                }
            }
            let totalTransferFee = 0;
            var numb;
            transfersData?.transfers.forEach(function (item) {
                if (item.type !== "Free" && item.type !== "Loan" && item.type !== "N/A" && item.type !== "Swap" && item.type !== null) {
                    numb = item.type.replace("M", '').replace("€", "").replace("K", "");
                    if (item.type.includes('K')) {
                        numb = numb / 1000;
                    }
                    totalTransferFee += +numb;
                }
            });
            description += ` They have a total known transfer fee of €${parseFloat(totalTransferFee).toFixed(1).replace(/[.,]0$/, "")} ${numb < 1 ? "K" : "M"}.`
        }
        let trophiesCount = 0;
        trophiesCount = trophiesData.filter(function (value) {
            return value.place == "Winner";
        }).length;

        if (trophiesCount > 0) {
            description += ` ${trophiesCount === 1 ? "They have one trophy win for club and country" : `They have a combined ${trophiesCount} trophy wins for club and country`}`
            if (trophiesCount > 30) {
                description += ", making him one of the highest trophy earners in professional football."
            } else {
                description += ".";
            }
        }
        return description;
    };

    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="card-body">
                {player.isSuccess && squads.isSuccess && transfers.isSuccess && trophies.isSuccess &&
                    <>
                        {player.data.response.length > 0 &&
                            <>
                                <Helmet>
                                    <title>{player.data.response[0].player.name}</title>
                                </Helmet>
                                <div className="flex flex-col md:flex-row">
                                    <div className="w-full mb-4 md:w-3/12 md:mb-0">
                                        <img src={player.data.response[0].player.photo} className="w-48 mx-auto rounded-full" alt="Player" />
                                    </div>
                                    <div className="w-full md:w-9/12">
                                        <span className="text-2xl font-bold">{player.data.response[0].player.name}</span>
                                        <div>
                                            {generatePlayerDescription()}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
                {(player.isLoading || squads.isLoading || trophies.isLoading || transfers.isLoading) &&
                    <>
                        <Skeleton count={1} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"25px"} style={{ marginTop: "6px", marginBottom: "10px" }} />
                        <Skeleton count={5} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                    </>
                }
            </div>
        </div >
    );
}

export default PlayerHeader;