import FootballLineup from "components/Other/FootballLineup";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const MatchLineups = ({ match }) => {

    const [selectedLineupTeam, setSelectedLineupTeam] = useState(0);
    const [hoveredPlayer, setHoveredPlayer] = useState();

    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="card-body">
                <h2 className="text-white card-title">Lineups</h2>
                {match.isSuccess &&
                    <>
                        {match.data.response.length > 0 && match.data.response[0].lineups.length > 0 &&
                            <>
                                <div className="flex justify-center mx-auto my-3 text-white tabs tabs-boxed">
                                    <a
                                        className={`tab lg:text-lg text-white ${selectedLineupTeam === 0 ? 'tab-active' : ''}`}
                                        onClick={() => setSelectedLineupTeam(0)}
                                    >
                                        <img src={match.data.response[0].teams.home.logo} alt="Home Team" className="w-6 mr-2" />
                                        {match.data.response[0].teams.home.name}
                                    </a>
                                    <a className={`tab lg:text-lg text-white ${selectedLineupTeam === 1 ? 'tab-active' : ''}`}
                                        onClick={() => setSelectedLineupTeam(1)}
                                    >
                                        <img src={match.data.response[0].teams.away.logo} alt="Away Team" className="w-6 mr-2" />
                                        {match.data.response[0].teams.away.name}
                                    </a>
                                </div>
                                <div className="flex flex-col lg:flex-row">
                                    <div className="w-full lg:w-5/12">
                                        <div className="flex flex-col lg:flex-col">
                                            <div className="w-full lg:w-full">
                                                <h2 className="my-2 text-lg font-bold">Manager</h2>
                                                <div className="py-2 border-b-2 border-b-gray-500">
                                                    <span>{match.data.response[0].lineups[selectedLineupTeam]?.coach?.name}</span>
                                                </div>
                                                <h2 className="my-2 text-lg font-bold">Starting 11</h2>
                                                {match.data.response[0].lineups[selectedLineupTeam].startXI?.map(player => (
                                                    <Link to={`../player/${player.player.id}`} key={player.player.id}>
                                                        <div
                                                            className="border-b-2 border-b-gray-500"
                                                            onMouseOver={() => setHoveredPlayer(player.player.id)}
                                                            onMouseOut={() => setHoveredPlayer(null)}
                                                        >
                                                            <div className="flex justify-between px-2 py-2 cursor-pointer hover:bg-slate-800 rounded-xl">
                                                                <div>
                                                                    <span className="mr-3">{player.player.number}</span>
                                                                    <span>{player.player.name}</span>
                                                                </div>
                                                                {player.player.pos &&
                                                                    <div className="flex flex-row items-center">
                                                                        {{ "G": "Goalkeeper", "D": "Defender", "M": "Midfielder", "F": "Forward" }[player.player.pos]}
                                                                        <img src={
                                                                            {
                                                                                "G": require("assets/img/timeline/yellow-shirt.svg").default,
                                                                                "D": require("assets/img/timeline/blue-shirt.svg").default,
                                                                                "M": require("assets/img/timeline/green-shirt.svg").default,
                                                                                "F": require("assets/img/timeline/red-shirt.svg").default
                                                                            }
                                                                            [player.player.pos]
                                                                        }
                                                                            className="w-6 ml-2"
                                                                            alt='Shirt Color' />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                            <div className="w-full lg:w-full">
                                                <h2 className="my-2 text-lg font-bold">Substitutes</h2>
                                                {match.data.response[0].lineups[selectedLineupTeam].substitutes?.map(player => (
                                                    <Link to={`../player/${player.player.id}`} key={player.player.id}>
                                                        <div className="border-b-2 border-b-gray-500">
                                                            <div className="flex justify-between px-2 py-2 cursor-pointer hover:bg-slate-800 rounded-xl">
                                                                <div>
                                                                    <span className="mr-3">{player.player.number}</span>
                                                                    <span>{player.player.name}</span>
                                                                </div>
                                                                {player.player.pos &&
                                                                    <div className="flex flex-row items-center">
                                                                        {{ "G": "Goalkeeper", "D": "Defender", "M": "Midfielder", "F": "Forward" }[player.player.pos]}
                                                                        <img src={
                                                                            {
                                                                                "G": require("assets/img/timeline/yellow-shirt.svg").default,
                                                                                "D": require("assets/img/timeline/blue-shirt.svg").default,
                                                                                "M": require("assets/img/timeline/green-shirt.svg").default,
                                                                                "F": require("assets/img/timeline/red-shirt.svg").default
                                                                            }
                                                                            [player.player.pos]
                                                                        }
                                                                            className="w-6 ml-2"
                                                                            alt='Shirt Color' />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full mt-4 lg:w-7/12 md:mt-0">
                                        <FootballLineup
                                            lineups={match.data.response[0].lineups}
                                            events={match.data.response[0].events}
                                            players={match.data.response[0].players}
                                            hoveredPlayer={hoveredPlayer}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        {match.data.response.length > 0 && match.data.response[0].lineups.length === 0 &&
                            <div className="flex items-center justify-center min-h-[100px]">
                                <span className="text-sm text-gray-400">No Data Available (Yet!)</span>
                            </div>
                        }
                    </>
                }
                {match.isLoading &&
                    <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div>
    );
}

export default MatchLineups;