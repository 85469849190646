import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const Standings = ({ standings }) => {

    //get all the unique promotion descriptions and assign them a color to be displayed next to the team
    var promotionColors;
    if (standings.isSuccess && standings.data.response.length > 0) {
        const promotionColorsUnique = [...new Set(standings.data.response[0].league.standings[0].map(item => item.description))];

        const colors = ["border-green-500", "border-blue-500", "border-purple-500", "border-orange-500", "border-yellow-500"]
        promotionColors = promotionColorsUnique.map((item, index) => {
            let color;
            if (item?.includes("Relegation")) {
                color = "border-red-500";
            } else if (item === null) {
                color = "border-transparent";
            } else {
                color = colors[index];
            }
            let itemWithColor = {
                name: item,
                color: color
            }
            return itemWithColor;
        });
    }

    return (
        <div className="w-full card bg-slate-900">
            <div className="gap-0 px-6 card-body">
                <h2 className="text-white card-title">Standings</h2>
                {standings.isSuccess &&
                    <>
                        {standings.data.response.length > 0 &&
                            <>
                                <small>
                                    <div className="flex flex-col text-white">
                                        {standings.data.response[0].league.standings.map((standing, index) => (
                                            <span key={standing[0].team.id}>{standing[0].team.name} leads the {standing[0].group} by {standing[0].points - standing[1].points} point(s)</span>
                                        ))}
                                    </div>
                                </small>
                                <div className="mt-3">
                                    {standings.data.response[0].league.standings.map((standingsGroup, index) => (
                                        <React.Fragment key={standingsGroup[0].group}>
                                            <div className={`flex text-white py-2 items-center hover:bg-slate-800 rounded-xl cursor-pointer ${index > 0 ? 'mt-5' : ''}`}>
                                                <div className="w-5/12">
                                                    {standingsGroup.length > 1 &&
                                                        <h2 className="text-white text-bold">{standingsGroup[0].group}</h2>
                                                    }
                                                </div>
                                                <div className="flex flex-row justify-between w-4/12">
                                                    <span>MP</span>
                                                    <span>W</span>
                                                    <span>D</span>
                                                    <span>L</span>
                                                    <span>GF</span>
                                                    <span>GA</span>
                                                    <span>GD</span>
                                                </div>
                                                <div className="w-2/12 text-center">LF</div>
                                                <div className="w-1/12 text-center">P</div>
                                            </div>
                                            {
                                                standingsGroup.map(standing => (
                                                    <Link to={`/football/team/${standing.team.id}`} key={standing.team.id}>
                                                        <div className="border-b-2 border-gray-700">
                                                            <div className="flex items-center py-2 text-white cursor-pointer hover:bg-slate-800 rounded-xl">
                                                                <div
                                                                    className={`w-1/12 border-l-4 ${promotionColors.find(item => item.name === standing.description).color}`}
                                                                >
                                                                    <img src={standing.team.logo} className="w-6 mx-auto" alt="teamLogo" />
                                                                </div>
                                                                <div className="w-4/12 text-sm truncate 2xl:text-base">
                                                                    {standing.team.name}
                                                                </div>
                                                                <div className="flex flex-row justify-between w-4/12 text-sm 2xl:text-base">
                                                                    <span>{standing.away.played + standing.home.played}</span>
                                                                    <span>{standing.away.win + standing.home.win}</span>
                                                                    <span>{standing.away.draw + standing.home.draw}</span>
                                                                    <span>{standing.away.lose + standing.home.lose}</span>
                                                                    <span>{standing.away.goals.for + standing.home.goals.for}</span>
                                                                    <span>{standing.away.goals.against + standing.home.goals.against}</span>
                                                                    <span>{standing.goalsDiff}</span>
                                                                </div>
                                                                <div className="flex justify-center w-2/12 align-center">
                                                                    {standing.form?.split('').map((form, index) => (
                                                                        form === "W"
                                                                            ?
                                                                            <i className="text-green-500 fas fa-check-circle fa-sm rounded-xl" key={index}></i>
                                                                            :
                                                                            <i className="text-red-500 fas fa-times-circle fa-sm rounded-xl" key={index}></i>
                                                                    ))}
                                                                </div>
                                                                <div className="w-1/12 text-center">
                                                                    {standing.points}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))
                                            }
                                        </React.Fragment>
                                    ))}
                                    <div className="mt-6">
                                        {promotionColors.map(promotionDescription => (
                                            <React.Fragment key={promotionDescription.name}>
                                                {promotionDescription.name &&
                                                    <div key={promotionDescription.name} className={`border-l-4 pl-2 py-2 my-2 text-white ${promotionDescription.color}`}>{promotionDescription.name}</div>
                                                }
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </>
                        }
                        {standings.data.response.length === 0 &&
                            <div className="flex items-center justify-center min-h-[100px]">
                                <span className="text-sm text-gray-400">No Data Available</span>
                            </div>
                        }
                    </>
                }
                {standings.isLoading &&
                    <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div >
    );
}

export default Standings;