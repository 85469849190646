import { useState } from "react";
import Skeleton from "react-loading-skeleton";

const MatchEvents = ({ match }) => {
    const [selectedTimelineFilter, setSelectedTimelineFilter] = useState();

    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="card-body">
                <h2 className="text-white card-title">Timeline</h2>
                <div className="flex flex-row justify-between mx-4 my-2">
                    <div className="tooltip" data-tip="Cards">
                        <img className={`w-8 cursor-pointer ${selectedTimelineFilter !== "Card" ? "opacity-50" : null}`} src={require("assets/img/timeline/yellow-card.svg").default} onClick={() => setSelectedTimelineFilter(s => s !== "Card" ? "Card" : undefined)} />
                    </div>
                    <div className="tooltip" data-tip="Goals">
                        <img className={`w-8 cursor-pointer ${selectedTimelineFilter !== "Goal" ? "opacity-50" : null}`} src={require("assets/img/timeline/goal.svg").default} onClick={() => setSelectedTimelineFilter(s => s !== "Goal" ? "Goal" : undefined)} />
                    </div>
                    <div className="tooltip" data-tip="Substitutions">
                        <img className={`w-8 cursor-pointer ${selectedTimelineFilter !== "subst" ? "opacity-50" : null}`} src={require("assets/img/timeline/substitution.svg").default} onClick={() => setSelectedTimelineFilter(s => s !== "subst" ? "subst" : undefined)} />
                    </div>
                    <div className="tooltip" data-tip="VAR Decisions">
                        <img className={`w-8 cursor-pointer ${selectedTimelineFilter !== "Var" ? "opacity-50" : null}`} src={require("assets/img/timeline/var.svg").default} onClick={() => setSelectedTimelineFilter(s => s !== "Var" ? "Var" : undefined)} />
                    </div>
                </div>
                {match.isSuccess &&
                    <>
                        {match.data.response.length > 0 && match.data.response[0].events.length > 0 &&
                            <>
                                {match.data.response[0].events.filter(event => !selectedTimelineFilter || event.type === selectedTimelineFilter).slice().sort((a, b) => (b.time.elapsed + b.time.extra) - (a.time.elapsed + a.time.extra)).map(event => (
                                    <div className="flex min-h-20" key={`${event.detail}${event.player.id}${event.time.elapsed}`}>
                                        <div className="w-16 text-right">
                                            <span>{event.time.elapsed}'
                                                {event.time.extra !== null &&
                                                    <>
                                                        + {event.time.extra}'
                                                    </>
                                                }
                                            </span>
                                        </div>
                                        <div
                                            className={
                                                {
                                                    'Card': {
                                                        'Yellow Card': 'border-r-4 border-r-yellow-300 mx-4',
                                                        'Red Card': 'border-r-4 border-r-red-500 mx-4',
                                                    }[event.detail],
                                                    'Goal': {
                                                        'Normal Goal': 'border-r-4 border-r-green-400 mx-4',
                                                        'Own Goal': 'border-r-4 border-r-red-400 mx-4',
                                                        'Penalty': 'border-r-4 border-r-green-400 mx-4',
                                                        'Missed Penalty': 'border-r-4 border-r-red-400 mx-4',
                                                    }[event.detail],
                                                    'subst': 'border-r-4 border-r-blue-400 mx-4',
                                                    'Var': 'border-r-4 border-r-orange-400 mx-4',
                                                }[event.type]
                                            }>
                                        </div>
                                        <div className="flex flex-col my-2 text-left">
                                            <img
                                                src={
                                                    {
                                                        'Card': {
                                                            'Yellow Card': require("assets/img/timeline/yellow-card.svg").default,
                                                            'Red Card': require("assets/img/timeline/red-card.svg").default,
                                                        }[event.detail],
                                                        'Goal': {
                                                            'Normal Goal': require("assets/img/timeline/goal.svg").default,
                                                            'Own Goal': require("assets/img/timeline/missed-penalty.svg").default,
                                                            'Penalty': require("assets/img/timeline/penalty.svg").default,
                                                            'Missed Penalty': require("assets/img/timeline/missed-penalty.svg").default,
                                                        }[event.detail],
                                                        'subst': require("assets/img/timeline/substitution.svg").default,
                                                        'Var': require("assets/img/timeline/var.svg").default,
                                                    }[event.type]
                                                }
                                                className="w-8"
                                            />
                                            <span className="text-lg font-bold">{event.detail}</span>
                                            <span>{event.team.name} <img src={event.team.logo} className="inline w-6" /></span>
                                            {event.type === 'subst' ?
                                                <div className="flex flex-col">
                                                    <div>
                                                        <i className="mr-2 text-green-500 fas fa-long-arrow-alt-right"></i>
                                                        <span className="font-bold">{event.assist.name}</span>
                                                    </div>
                                                    <div>
                                                        <i className="mr-2 text-red-500 fas fa-long-arrow-alt-left"></i>
                                                        <span className="font-bold">{event.player.name}</span>
                                                    </div>
                                                </div>
                                                :
                                                <span className="font-bold">{event.player.name}</span>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </>
                        }
                        {match.data.response.length > 0 && match.data.response[0].events.length === 0 &&
                            <div className="flex items-center justify-center min-h-[100px]">
                                <span className="text-sm text-gray-400">No Data Available (Yet!)</span>
                            </div>
                        }
                    </>
                }
                {match.isLoading &&
                    <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div>
    );
}

export default MatchEvents;