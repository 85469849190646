import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const TopStats = ({ topScorers, topAssists, topYellowCards, topRedCards }) => {

    return (
        <div className="w-full card bg-slate-900 2xl:mt-0">
            <div className="gap-0 px-6 card-body">
                <div className="flex flex-wrap lg:flex-row">
                    <div className="w-full mt-2 lg:w-6/12 2xl:w-3/12 lg:px-2">
                        <h2 className="font-bold text-white">Top Scorers</h2>
                        {topScorers.isSuccess &&
                            <>
                                {topScorers.data.response.length > 0 &&
                                    <>
                                        <small>
                                            <p className="text-white">
                                                {topScorers.data.response[0].player.name} leads by&nbsp;
                                                {topScorers.data.response[0].statistics[0].goals.total - topScorers.data.response[1].statistics[0].goals.total} goal(s)
                                            </p>
                                        </small>
                                        <div className="mt-2">
                                            {topScorers.data.response.slice(0, 10).map(player => (
                                                <Link to={`/football/player/${player.player.id}`} key={player.player.id}>
                                                    <div className="flex items-center px-2 py-1 text-white cursor-pointer hover:bg-slate-800 rounded-xl">
                                                        <div className="w-2/12 mr-2">
                                                            <img className="w-10 rounded-full" src={player.player.photo} alt="player-image" />
                                                        </div>
                                                        <div className="w-7/12 truncate">
                                                            <span>{player.player.name}</span>
                                                        </div>
                                                        <div className="flex flex-row items-center justify-center w-3/12 text-right">
                                                            {player?.statistics?.[0]?.team?.logo &&
                                                                <div className="tooltip" data-tip={player.statistics[0].team.name}>
                                                                    <img src={player.statistics[0].team.logo} className="w-8 mr-1"></img>
                                                                </div>
                                                            }
                                                            <span className="font-bold">{player.statistics[0].goals.total}</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </>
                                }
                                {topScorers.data.response.length === 0 &&
                                    <div className="flex items-center justify-center min-h-[100px]">
                                        <span className="text-sm text-gray-400">No Data Available</span>
                                    </div>
                                }
                            </>
                        }
                        {topScorers.isLoading &&
                            <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                        }
                    </div>
                    <div className="w-full mt-2 lg:w-6/12 2xl:w-3/12 lg:px-2">
                        <h2 className="font-bold text-white">Top Assists</h2>
                        {topAssists.isSuccess &&
                            <>
                                {topAssists.data.response.length > 0 &&
                                    <>
                                        <small>
                                            <p className="text-white">
                                                {topAssists.data.response[0].player.name} leads by&nbsp;
                                                {topAssists.data.response[0].statistics[0].goals.assists - topAssists.data.response[1].statistics[0].goals.assists} assist(s)
                                            </p>
                                        </small>
                                        <div className="mt-2">
                                            {topAssists.data.response.slice(0, 10).map(player => (
                                                <Link to={`/football/player/${player.player.id}`} key={player.player.id}>
                                                    <div className="flex items-center px-2 py-1 text-white cursor-pointer hover:bg-slate-800 rounded-xl">
                                                        <div className="w-2/12 mr-2">
                                                            <img className="w-10 rounded-full" src={player.player.photo} alt="player-image" />
                                                        </div>
                                                        <div className="w-7/12 truncate">
                                                            <span>{player.player.name}</span>
                                                        </div>
                                                        <div className="flex flex-row items-center justify-center w-3/12 text-right">
                                                            {player?.statistics?.[0]?.team?.logo &&
                                                                <div className="tooltip" data-tip={player.statistics[0].team.name}>
                                                                    <img src={player.statistics[0].team.logo} className="w-8 mr-1"></img>
                                                                </div>
                                                            }
                                                            <span className="font-bold">{player.statistics[0].goals.assists}</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </>
                                }
                                {topAssists.data.response.length === 0 &&
                                    <div className="flex items-center justify-center min-h-[100px]">
                                        <span className="text-sm text-gray-400">No Data Available</span>
                                    </div>
                                }
                            </>
                        }
                        {topAssists.isLoading &&
                            <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                        }
                    </div>
                    <div className="w-full mt-2 lg:w-6/12 2xl:w-3/12 lg:px-2">
                        <h2 className="font-bold text-white">Top Yellow Cards</h2>
                        {topYellowCards.isSuccess &&
                            <>
                                {topYellowCards.data.response.length > 0 &&
                                    <>
                                        <small>
                                            <p className="text-white">
                                                {topYellowCards.data.response[0].player.name} leads by&nbsp;
                                                {topYellowCards.data.response[0].statistics[0].cards.yellow - topYellowCards.data.response[1].statistics[0].cards.yellow} yellow card(s).
                                            </p>
                                        </small>
                                        <div className="mt-2">
                                            {topYellowCards.data.response.slice(0, 10).map(player => (
                                                <Link to={`/football/player/${player.player.id}`} key={player.player.id}>
                                                    <div className="flex items-center px-2 py-1 text-white cursor-pointer hover:bg-slate-800 rounded-xl">
                                                        <div className="w-2/12 mr-2">
                                                            <img className="w-10 rounded-full" src={player.player.photo} alt="player-image" />
                                                        </div>
                                                        <div className="w-7/12 truncate">
                                                            <span>{player.player.name}</span>
                                                        </div>
                                                        <div className="flex flex-row items-center justify-center w-3/12 text-right">
                                                            {player?.statistics?.[0]?.team?.logo &&
                                                                <div className="tooltip" data-tip={player.statistics[0].team.name}>
                                                                    <img src={player.statistics[0].team.logo} className="w-8 mr-1"></img>
                                                                </div>
                                                            }
                                                            <span className="font-bold">{player.statistics[0].cards.yellow}</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </>
                                }
                                {topYellowCards.data.response.length === 0 &&
                                    <div className="flex items-center justify-center min-h-[100px]">
                                        <span className="text-sm text-gray-400">No Data Available</span>
                                    </div>
                                }
                            </>
                        }
                        {topYellowCards.isLoading &&
                            <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                        }
                    </div>

                    <div className="w-full mt-2 lg:w-6/12 2xl:w-3/12 lg:px-2">
                        <h2 className="font-bold text-white">Top Red Cards</h2>
                        {topRedCards.isSuccess &&
                            <>
                                {topRedCards.data.response.length > 0 &&
                                    <>
                                        <small>
                                            <p className="text-white">
                                                {topRedCards.data.response[0].player.name} determined to play the least amount of football
                                            </p>
                                        </small>
                                        <div className="mt-2">
                                            {topRedCards.data.response.slice(0, 10).map(player => (
                                                <Link to={`/football/player/${player.player.id}`} key={player.player.id}>
                                                    <div className="flex items-center px-2 py-1 text-white cursor-pointer hover:bg-slate-800 rounded-xl">
                                                        <div className="w-2/12 mr-2">
                                                            <img className="w-10 rounded-full" src={player.player.photo} alt="player-image" />
                                                        </div>
                                                        <div className="w-7/12 truncate">
                                                            <span>{player.player.name}</span>
                                                        </div>
                                                        <div className="flex flex-row items-center justify-center w-3/12 text-right">
                                                            {player?.statistics?.[0]?.team?.logo &&
                                                                <div className="tooltip" data-tip={player.statistics[0].team.name}>
                                                                    <img src={player.statistics[0].team.logo} className="w-8 mr-1"></img>
                                                                </div>
                                                            }
                                                            <span className="font-bold">{player.statistics[0].cards.red}</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </>
                                }
                                {topRedCards.data.response.length === 0 &&
                                    <div className="flex items-center justify-center min-h-[100px]">
                                        <span className="text-sm text-gray-400">No Data Available</span>
                                    </div>
                                }
                            </>
                        }
                        {topRedCards.isLoading &&
                            <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                        }
                    </div>


                </div>
            </div>
        </div>
    );
}

export default TopStats;