import Skeleton from "react-loading-skeleton";

const PlayerPhysicalAtributes = ({ player }) => {
    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="card-body">
                <h2 className="text-white card-title">Physical Attributes</h2>
                {player.isSuccess &&
                    <>
                        {player.data.response.length > 0 &&
                            <>
                                {player.data.response[0].player.age &&
                                    <div className="py-2">
                                        <div className="mb-2">Age</div>
                                        <div className="relative w-full h-1 bg-slate-600 rounded-r-xl">
                                            <div className="absolute h-1 transition-all bg-red-500 rounded-r-xl"
                                                style={{ width: `${(parseInt(player.data.response[0].player.age / 70 * 100)).toFixed(0)}%` }}
                                            >
                                            </div>
                                            <span className="absolute text-right top-2"
                                                style={{ width: `${player.data.response[0].player.age / 70 * 100 + 10}%` }}
                                            >{player.data.response[0].player.age} years old</span>
                                        </div>
                                    </div>
                                }
                                {player.data.response[0].player.weight &&
                                    <div className="py-2">
                                        <div className="mb-2">Weight</div>
                                        <div className="relative w-full h-1 bg-slate-600 rounded-r-xl">
                                            <div className="absolute h-1 transition-all bg-red-500 w-80 rounded-r-xl"
                                                style={{ width: `${(parseInt(player.data.response[0].player.weight.replace(' kg', '') / 120 * 100)).toFixed(0)}%` }}
                                            >
                                            </div>
                                            <span className="absolute text-right top-2"
                                                style={{ width: `${eval((parseInt(player.data.response[0].player.weight.replace(' kg', '') / 120 * 100)).toFixed(0)) + 5}%` }}
                                            >{player.data.response[0].player.weight}</span>
                                        </div>
                                    </div>
                                }
                                {player.data.response[0].player.height &&
                                    <div className="py-2">
                                        <div className="mb-2">Height</div>
                                        <div className="relative w-full h-1 bg-slate-600 rounded-r-xl">
                                            <div className="absolute h-1 transition-all bg-red-500 rounded-r-xl"
                                                style={{ width: `${(parseInt(player.data.response[0].player.height.replace(' cm', '') / 210 * 100)).toFixed(0)}%` }}
                                            >
                                            </div>
                                            <span className="absolute text-right top-2"
                                                style={{ width: `${eval((parseInt(player.data.response[0].player.height.replace(' cm', '') / 210 * 100)).toFixed(0)) + 5}%` }}
                                            >{player.data.response[0].player.height}</span>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </>
                }
                {player.isLoading &&
                    <Skeleton count={3} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div>
    );
}

export default PlayerPhysicalAtributes;