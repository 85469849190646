import { sanitize } from "js/common";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const RecentForumPosts = ({ recentForumPosts }) => {

    return (
        <div className="w-full card bg-slate-900">
            <div className="gap-0 card-body">
                <h2 className="mb-2 text-white card-title">My Recent Posts</h2>
                {recentForumPosts.isSuccess &&
                    <>
                        {recentForumPosts.data.size > 0 &&
                            <>
                                {recentForumPosts.data.docs.map(post => (
                                    <Link to={`/forums/${post.data().categoryId}/${post.id}`} key={post.id}>
                                        <div className="border-b-2 border-gray-700">
                                            <div className="text-white py-1 hover:bg-slate-800 text-sm xl:text-base rounded-xl cursor-pointer h-[60px] text-clip">
                                                <div className="flex flex-col justify-center px-3">
                                                    <h6 className='text-lg font-bold truncate'>
                                                        {sanitize(post.data().title)}
                                                    </h6>
                                                    <p className="truncate">{sanitize(post.data().text)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </>
                        }
                        {recentForumPosts.data.size === 0 &&
                            <div className="flex items-center justify-center min-h-[100px]">
                                <span className="text-sm text-gray-400">Nothing yet... Post something!</span>
                            </div>
                        }
                    </>
                }
                {recentForumPosts.isLoading &&
                    <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
                {recentForumPosts.isError &&
                    <div className="flex items-center justify-center min-h-[100px]">
                        <span className="text-sm text-gray-400">Sorry, something went wrong getting this data</span>
                    </div>
                }
            </div>
        </div>
    );
}

export default RecentForumPosts;