import Skeleton from "react-loading-skeleton";

const MatchStatistics = ({ match }) => {
    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="gap-0 card-body">
                <h2 className="text-white card-title">Match Statistics</h2>
                <small><p className="text-white">View Statistics about this match</p></small>
                {match.isSuccess &&
                    <>
                        {match.data.response.length > 0 && match.data.response[0].statistics.length > 0 &&
                            <div className="flex flex-col">
                                {match.data.response[0].statistics[0].statistics.map((stat, index) => (
                                    <div key={stat.type}>
                                        <div className="text-lg font-bold text-center">
                                            {stat.type}
                                        </div>
                                        <div className="flex flex-row items-center w-full">
                                            <div className="flex flex-row items-center w-full">
                                                <div className="self-start mr-auto text-lg font-bold">
                                                    {stat.value}
                                                </div>
                                                <div
                                                    className="h-2 ml-3 bg-red-400 rounded-l-xl"
                                                    style={{ width: `${stat.value?.toString().includes("%") ? stat.value.replace('%', '') : stat.value * 10}%` }}
                                                >
                                                </div>
                                            </div>
                                            <div className="flex flex-row items-center w-full">
                                                <div
                                                    className="h-2 mr-3 bg-blue-400 rounded-r-xl"
                                                    style={{ width: `${match.data.response[0].statistics[1].statistics[index].value?.toString().includes("%") ? match.data.response[0].statistics[1].statistics[index].value.replace('%', '') : match.data.response[0].statistics[1].statistics[index].value * 10}%` }}
                                                >
                                                </div>
                                                <div className="self-start ml-auto text-lg font-bold">
                                                    {match.data.response[0].statistics[1].statistics[index].value}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                        {match.data.response.length > 0 && match.data.response[0].statistics.length === 0 &&
                            <div className="flex items-center justify-center min-h-[100px]">
                                <span className="text-sm text-gray-400">No Data Available (Yet!)</span>
                            </div>
                        }
                    </>
                }
                {match.isLoading &&
                    <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div>
    );
}

export default MatchStatistics;