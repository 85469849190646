import { db } from "firebase-configs/config";
import { collection, getCountFromServer, query, where } from "firebase/firestore";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

const Forums = () => {

    const GeneralForumCount = useQuery(['ForumCount', 'general'], () => getCountFromServer(query(collection(db, "forum_posts"), where("categoryId", "==", "general"))));
    const MatchForumCount = useQuery(['ForumCount', 'matches'], () => getCountFromServer(query(collection(db, "forum_posts"), where("categoryId", "==", "matches"))));
    const PlayerForumCount = useQuery(['ForumCount', 'players'], () => getCountFromServer(query(collection(db, "forum_posts"), where("categoryId", "==", "players"))));
    const LeaguesAndCupsForumCount = useQuery(['ForumCount', "leaguesandcups"], () => getCountFromServer(query(collection(db, "forum_posts"), where("categoryId", "==", "leaguesandcups"))));
    const SupportForumCount = useQuery(['ForumCount', 'support'], () => getCountFromServer(query(collection(db, "forum_posts"), where("categoryId", "==", "support"))));
    const SuggestionsForumCount = useQuery(['ForumCount', 'suggestions'], () => getCountFromServer(query(collection(db, "forum_posts"), where("categoryId", "==", "featuresuggestions"))));

    const GeneralForumCommentsCount = useQuery(['ForumCommentsCount', 'general'], () => getCountFromServer(query(collection(db, "forum_posts_comments"), where("categoryId", "==", "general"))));
    const MatchForumCommentsCount = useQuery(['ForumCommentsCount', 'matches'], () => getCountFromServer(query(collection(db, "forum_posts_comments"), where("categoryId", "==", "matches"))));
    const PlayerForumCommentsCount = useQuery(['ForumCommentsCount', 'players'], () => getCountFromServer(query(collection(db, "forum_posts_comments"), where("categoryId", "==", "players"))));
    const LeaguesAndCupsForumCommentsCount = useQuery(['ForumCommentsCount', 'leaguesandcups'], () => getCountFromServer(query(collection(db, "forum_posts_comments"), where("categoryId", "==", "leaguesandcups"))));
    const SupportForumCommentsCount = useQuery(['ForumCommentsCount', 'support'], () => getCountFromServer(query(collection(db, "forum_posts_comments"), where("categoryId", "==", "support"))));
    const SuggestionsForumCommentsCount = useQuery(['ForumCommentsCount', 'suggestions'], () => getCountFromServer(query(collection(db, "forum_posts_comments"), where("categoryId", "==", "featuresuggestions"))));

    return (
        <div className="relative px-6 text-xl md:text-base 2xl:px-32 3xl:px-64">
            <Helmet>
                <title>Forums</title>
            </Helmet>
            {/* <div className="w-full my-3 text-center">
                <span className="text-2xl font-bold text-white md:text-5xl">Welcome to the&nbsp;
                    <span className="bg-gradient-to-r from-orange-300 via-pink-500 to-orange-300 bg-clip-text bg-2xl animate-background-pan" style={{ WebkitTextFillColor: 'transparent' }}>
                        kicks.football
                    </span>
                    &nbsp;forums!
                </span>
            </div> */}
            <div className="flex flex-col items-center mb-10">
                <p className="text-2xl font-bold text-white">Welcome to the&nbsp;
                    <span className="bg-gradient-to-r from-pink-500 via-blue-500 to-pink-500 bg-clip-text bg-2xl animate-background-pan [-webkit-text-fill-color:transparent]">
                        kicks.football
                    </span> forums!
                </p>
                <p className="text-lg text-center text-white">You must be signed in to participate. Please keep it civil. Obscene posts will result in a ban.</p>
            </div>
            <div className="flex gap-10 px-10 py-10 text-white">
                <div className="flex flex-col w-full">
                    <div className="flex justify-between py-5 border-b border-white">
                        <div className="w-full lg:w-6/12">
                            <Link to="../forums/general">
                                <span className="font-bold text-gray-300 md:text-3xl hover:text-white">General Discussion</span>
                            </Link>
                            <p className="mt-3 text-base">Talk about whatever you want</p>
                        </div>
                        <div className="flex-row hidden w-6/12 lg:flex">
                            <div className="flex flex-row justify-end w-full mr-3">
                                <div className="flex flex-col items-center justify-center w-20 h-20 mx-2 rounded-xl">
                                    <span className="text-xl font-bold">
                                        {GeneralForumCount.isSuccess &&
                                            GeneralForumCount.data.data().count
                                        }
                                    </span>
                                    <span>posts</span>
                                </div>
                                <div className="flex flex-col items-center justify-center w-20 h-20 mx-2 rounded-xl">
                                    <span className="text-xl font-bold">
                                        {GeneralForumCommentsCount.isSuccess &&
                                            GeneralForumCommentsCount.data.data().count
                                        }
                                    </span>
                                    <span>comments</span>
                                </div>
                            </div>
                            {/* <div className="hidden md:block md:w-6/12">
                                <div className="h-20 border-l-2 border-l-red-300">
                                    <div className="flex flex-col ml-5">
                                        <span className="text-sm text-slate-300">Username 30 minutes ago</span>
                                        <span className="truncate">thats acutally crazy tho...</span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="flex justify-between py-5 border-b border-white">
                        <div className="w-full lg:w-6/12">
                            <Link to="../forums/matches">
                                <span className="font-bold text-gray-300 md:text-3xl hover:text-white">Matches</span>
                            </Link>
                            <p className="mt-3 text-base">Talk about upcoming, ongoing and past matches</p>
                        </div>
                        <div className="flex-row hidden w-6/12 lg:flex">
                            <div className="flex flex-row justify-end w-full mr-3">
                                <div className="flex flex-col items-center justify-center w-20 h-20 mx-2 rounded-xl">
                                    <span className="text-xl font-bold">
                                        {MatchForumCount.isSuccess &&
                                            MatchForumCount.data.data().count
                                        }
                                    </span>
                                    <span>posts</span>
                                </div>
                                <div className="flex flex-col items-center justify-center w-20 h-20 mx-2 rounded-xl">
                                    <span className="text-xl font-bold">
                                        {MatchForumCommentsCount.isSuccess &&
                                            MatchForumCommentsCount.data.data().count
                                        }
                                    </span>
                                    <span>comments</span>
                                </div>
                            </div>
                            {/* <div className="hidden md:block md:w-6/12">
                                <div className="h-20 border-l-2 border-l-red-300">
                                    <div className="flex flex-col ml-5">
                                        <span className="text-sm text-slate-300">Username 30 minutes ago</span>
                                        <span className="truncate">thats acutally crazy tho...</span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="flex justify-between py-5 border-b border-white">
                        <div className="w-full lg:w-6/12">
                            <Link to="../forums/players">
                                <span className="font-bold text-gray-300 md:text-3xl hover:text-white">Players</span>
                            </Link>
                            <p className="mt-3 text-base">Talk about players, transfers, etc.</p>
                        </div>
                        <div className="flex-row hidden w-6/12 lg:flex">
                            <div className="flex flex-row justify-end w-full mr-3">
                                <div className="flex flex-col items-center justify-center w-20 h-20 mx-2 rounded-xl">
                                    <span className="text-xl font-bold">
                                        {PlayerForumCount.isSuccess &&
                                            PlayerForumCount.data.data().count
                                        }
                                    </span>
                                    <span>posts</span>
                                </div>
                                <div className="flex flex-col items-center justify-center w-20 h-20 mx-2 rounded-xl">
                                    <span className="text-xl font-bold">
                                        {PlayerForumCommentsCount.isSuccess &&
                                            PlayerForumCommentsCount.data.data().count
                                        }
                                    </span>
                                    <span>comments</span>
                                </div>
                            </div>
                            {/* <div className="hidden md:block md:w-6/12">
                                <div className="h-20 border-l-2 border-l-red-300">
                                    <div className="flex flex-col ml-5">
                                        <span className="text-sm text-slate-300">Username 30 minutes ago</span>
                                        <span className="truncate">thats acutally crazy tho...</span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="flex justify-between py-5 border-b border-white">
                        <div className="w-full lg:w-6/12">
                            <Link to="../forums/leaguesandcups">
                                <span className="font-bold text-gray-300 md:text-3xl hover:text-white">Leagues and Cups</span>
                            </Link>
                            <p className="mt-3 text-base">Talk about current and upcoming leagues and cups</p>
                        </div>
                        <div className="flex-row hidden w-6/12 lg:flex">
                            <div className="flex flex-row justify-end w-full mr-3">
                                <div className="flex flex-col items-center justify-center w-20 h-20 mx-2 rounded-xl">
                                    <span className="text-xl font-bold">
                                        {LeaguesAndCupsForumCount.isSuccess &&
                                            LeaguesAndCupsForumCount.data.data().count
                                        }
                                    </span>
                                    <span>posts</span>
                                </div>
                                <div className="flex flex-col items-center justify-center w-20 h-20 mx-2 rounded-xl">
                                    <span className="text-xl font-bold">
                                        {LeaguesAndCupsForumCommentsCount.isSuccess &&
                                            LeaguesAndCupsForumCommentsCount.data.data().count
                                        }
                                    </span>
                                    <span>comments</span>
                                </div>
                            </div>
                            {/* <div className="hidden md:block md:w-6/12">
                                <div className="h-20 border-l-2 border-l-red-300">
                                    <div className="flex flex-col ml-5">
                                        <span className="text-sm text-slate-300">Username 30 minutes ago</span>
                                        <span className="truncate">thats acutally crazy tho...</span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="flex justify-between py-5 border-b border-white">
                        <div className="w-full lg:w-6/12">
                            <Link to="../forums/support">
                                <span className="font-bold text-gray-300 md:text-3xl hover:text-white">Support</span>
                            </Link>
                            <p className="mt-3 text-base">Post your technical problems</p>
                        </div>
                        <div className="flex-row hidden w-6/12 lg:flex">
                            <div className="flex flex-row justify-end w-full mr-3">
                                <div className="flex flex-col items-center justify-center w-20 h-20 mx-2 rounded-xl">
                                    <span className="text-xl font-bold">
                                        {SupportForumCount.isSuccess &&
                                            SupportForumCount.data.data().count
                                        }
                                    </span>
                                    <span>posts</span>
                                </div>
                                <div className="flex flex-col items-center justify-center w-20 h-20 mx-2 rounded-xl">
                                    <span className="text-xl font-bold">
                                        {SupportForumCommentsCount.isSuccess &&
                                            SupportForumCommentsCount.data.data().count
                                        }
                                    </span>
                                    <span>comments</span>
                                </div>
                            </div>
                            {/* <div className="hidden md:block md:w-6/12">
                                <div className="h-20 border-l-2 border-l-red-300">
                                    <div className="flex flex-col ml-5">
                                        <span className="text-sm text-slate-300">Username 30 minutes ago</span>
                                        <span className="truncate">thats acutally crazy tho...</span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="flex justify-between py-5 border-b border-white">
                        <div className="w-full lg:w-6/12">
                            <Link to="../forums/suggestions">
                                <span className="font-bold text-gray-300 md:text-3xl hover:text-white">Feature Suggestions</span>
                            </Link>
                            <p className="mt-3 text-base">Have an idea for a new feature? Let me know</p>
                        </div>
                        <div className="flex-row hidden w-6/12 lg:flex">
                            <div className="flex flex-row justify-end w-full mr-3">
                                <div className="flex flex-col items-center justify-center w-20 h-20 mx-2 rounded-xl">
                                    <span className="text-xl font-bold">
                                        {SuggestionsForumCount.isSuccess &&
                                            SuggestionsForumCount.data.data().count
                                        }
                                    </span>
                                    <span>posts</span>
                                </div>
                                <div className="flex flex-col items-center justify-center w-20 h-20 mx-2 rounded-xl">
                                    <span className="text-xl font-bold">
                                        {SuggestionsForumCommentsCount.isSuccess &&
                                            SuggestionsForumCommentsCount.data.data().count
                                        }
                                    </span>
                                    <span>comments</span>
                                </div>
                            </div>
                            {/* <div className="hidden md:block md:w-6/12">
                                <div className="h-20 border-l-2 border-l-red-300">
                                    <div className="flex flex-col ml-5">
                                        <span className="text-sm text-slate-300">Username 30 minutes ago</span>
                                        <span className="truncate">thats acutally crazy tho...</span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Forums;