// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getMessaging } from 'firebase/messaging';
import { getPerformance } from "firebase/performance";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB22OHufIQLBoU5zk1fpSO4-p3pdyU4Nn0",
    authDomain: "kicks.football",
    projectId: "football-app-cf6c7",
    storageBucket: "football-app-cf6c7.appspot.com",
    messagingSenderId: "28548056435",
    appId: "1:28548056435:web:44fd3cbef8a9f72a623106",
    measurementId: "G-9NDNEZLRTX"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const performance = getPerformance(app);
export const messaging = getMessaging(app);

const functions = getFunctions();
export const API = httpsCallable(functions, "api");
// connectFunctionsEmulator(functions, "localhost", 5001);
// connectFirestoreEmulator(db, "localhost", 8080);








