import { useFirestoreDocumentData, useFirestoreQueryData } from "@react-query-firebase/firestore";
import { db } from "firebase-configs/config";
import { addDoc, collection, doc, orderBy, query, serverTimestamp, where } from "firebase/firestore";
import { confetti } from "js/common";
import { UserContext } from "layouts/App";
import moment from "moment";
import { useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router";
import { toast } from "react-toastify";
var filter = require('leo-profanity');


const Post = () => {
    const { postId, categoryId } = useParams();
    const user = useContext(UserContext);

    const forumPost = useFirestoreDocumentData(["forum_posts", postId], doc(db, "forum_posts", postId), { subscribe: true });
    const comments = useFirestoreQueryData(['forum_posts_comments', postId], query(collection(db, "forum_posts_comments"), where('postId', '==', postId), orderBy('createdOn', 'desc')), { subscribe: true, idField: '_id' });

    const commentBox = useRef();

    const submitComment = async (comment) => {
        if (!user || !user.userFirestore?.username) return;
        commentBox.current.value = '';
        try {
            if (filter.check(comment)) {
                confetti.addConfetti({ emojis: ['👎', '💩', '🙍'] });
                toast.warning("Please chill with the profanity. Excessive profanity will result in a ban! 🔨")
            }
            await addDoc(collection(db, "forum_posts_comments"), {
                text: comment,
                postId: postId,
                createdOn: serverTimestamp(),
                userId: user.userFirebase.uid,
                author: user.userFirestore.username,
                categoryId: categoryId
            });
            confetti.addConfetti({ emojis: ['😃'], confettiNumber: 5, });
            toast.success("Your comment has been successfully submitted!")
        } catch (error) {
            toast.error("There was a problem submitting your comment. Please try again.")
        }
    };

    return (
        <div className="relative flex flex-col px-10 text-white 2xl:px-32 3xl:px-64">
            {forumPost.isSuccess &&
                <>
                    <Helmet>
                        <title>{forumPost.data.title}</title>
                    </Helmet>
                    <div className="flex flex-col">
                        <span className="text-3xl font-semibold">{forumPost.data.title}</span>
                        <div className="flex flex-row items-center justify-center h-auto px-2 py-2 mt-2 mr-2 text-sm w-36 bg-slate-800 rounded-xl">
                            <span>
                                {
                                    {
                                        "general": "General Discussion",
                                        "matches": "Matches",
                                        "players": "Players",
                                        "leaguesandcups": "Leagues & Cups",
                                        "support": "Support",
                                        "featuresuggestions": "Feature Suggestions"
                                    }[categoryId]
                                }
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col pb-10 my-10 border-b border-slate-400">
                        <p className="text-base text-white truncate">posted by&nbsp;
                            <span className="font-semibold text-lg bg-gradient-to-r from-pink-500 via-blue-500 to-pink-500 bg-clip-text bg-2xl animate-background-pan [-webkit-text-fill-color:transparent]">
                                {forumPost.data.author}
                            </span>
                            <span className="font-semibold">
                                &nbsp;on {moment(forumPost.data.createdOn?.toDate()).format('LLL')}
                            </span>
                        </p>
                        <p className="mt-2 break-words">{forumPost.data.text}</p>
                    </div>
                    <div className="relative pb-10 border-l-2 border-sky-700">
                        <div className="flex flex-col ml-10">
                            <textarea ref={commentBox} rows="2" placeholder="Say something..." className="border border-white rounded-lg bg-slate-800" />
                            <div className="ml-auto tooltip tooltip-bottom" data-tip={!user ? "You need to be signed in to Post" : !user.userFirestore?.username ? "You need to set a username before you can post" : "Post a Comment"}>
                                <button
                                    className="inline w-20 mt-2 text-white border-none btn bg-violet-500"
                                    onClick={() => submitComment(commentBox.current.value)}
                                    disabled={!user || !user.userFirestore?.username}
                                >
                                    Post
                                </button>
                            </div>

                        </div>
                        <span className="absolute top-0 w-4 h-4 rounded-full -left-2 bg-sky-700"></span>
                    </div>
                    <div className="flex flex-col">
                        {comments.isSuccess &&
                            <>
                                {comments.data.length > 0 &&
                                    <>
                                        {comments.data.map((doc, index) => (
                                            <div className="relative flex-col pb-10 border-l-2 border-sky-700" key={doc._id}>
                                                <div className="flex flex-col pb-10 ml-10 border-b border-slate-400">
                                                    <div className="flex flex-row justify-between">
                                                        <span><span className="font-semibold text-sky-400">{doc.author}</span> wrote {moment(doc.createdOn?.toDate()).fromNow()}</span>
                                                        <span className="text-xs">#{comments.data.length - index}</span>
                                                    </div>
                                                    <span className="break-words">{filter.clean(doc.text)}</span>
                                                </div>
                                                <span className="absolute top-0 w-4 h-4 rounded-full -left-2 bg-sky-700"></span>
                                            </div>
                                        ))}
                                    </>
                                }
                                {comments.data.length === 0 &&
                                    <div className="flex items-center justify-center min-h-[100px]">
                                        <span className="text-sm text-gray-400">Nothing yet... Post something!</span>
                                    </div>
                                }
                            </>
                        }
                        {comments.isLoading &&
                            <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                        }
                        {comments.isError &&
                            <div className="flex items-center justify-center min-h-[100px]">
                                <span className="text-sm text-gray-400">Sorry, something went wrong getting this data</span>
                            </div>
                        }
                    </div>
                </>
            }
        </div>
    );
}

export default Post;