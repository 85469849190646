import { sanitize } from "js/common";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const RecentForumPostsComments = ({ recentForumPostsComments }) => {

    return (
        <div className="w-full card bg-slate-900">
            <div className="gap-0 card-body">
                <h2 className="mb-2 text-white card-title">My Recent Comments</h2>
                {recentForumPostsComments.isSuccess &&
                    <>
                        {recentForumPostsComments.data.size > 0 &&
                            <>
                                {recentForumPostsComments.data.docs.map(comment => (
                                    <Link to={`/forums/${comment.data().categoryId}/${comment.data().postId}`} key={comment.id}>
                                        <div className="border-b-2 border-gray-700">
                                            <div className="text-white py-1 hover:bg-slate-800 text-sm xl:text-base rounded-xl cursor-pointer h-[60px]">
                                                <div className="flex items-center justify-center h-full px-3">
                                                    <h6 className='text-xl font-bold truncate'>
                                                        {sanitize(comment.data().title)}
                                                    </h6>
                                                    <p className='truncate'>{sanitize(comment.data().text)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </>
                        }
                        {recentForumPostsComments.data.size === 0 &&
                            <div className="flex items-center justify-center min-h-[100px]">
                                <span className="text-sm text-gray-400">Nothing yet... Post something!</span>
                            </div>
                        }
                    </>
                }
                {recentForumPostsComments.isLoading &&
                    <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
                {recentForumPostsComments.isError &&
                    <div className="flex items-center justify-center min-h-[100px]">
                        <span className="text-sm text-gray-400">Sorry, something went wrong getting this data</span>
                    </div>
                }
            </div>
        </div >
    );
}

export default RecentForumPostsComments;