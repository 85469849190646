import VideoHighlightCard from "components/Cards/VideoHighlightCard";
import { useRef, useState } from "react";
import { useQuery } from "react-query";

const FootballVideoHighlights = () => {

    const videoHighlights = useQuery('videoHighlights', async () => {
        let res = await fetch('https://www.scorebat.com/video-api/v3/feed/?token=MzA0NTdfMTY3NzYyNjA0MV8xNmIxZjRmZWVhNDNmODk3ZTEyM2Q3ZWM5ZmVjMjRhZWY0NDYxNGYx');
        return res.json();
    });

    const [selectedHighlight, setSelectedHighlight] = useState();

    const [rightHovered, setRightHovered] = useState(false);
    const [leftHovered, setLeftHovered] = useState(false);

    const container = useRef();

    const scrollLeft = () => {
        container.current.scrollLeft -= 1000;
    }

    const scrollRight = () => {
        container.current.scrollLeft += 1000;
    }

    return (
        <>
            {videoHighlights.isSuccess && videoHighlights.data.response.length > 0 &&
                <>
                    <div className="pb-3 text-xl font-semibold text-white">
                        Recent Highlights
                    </div>
                    <div
                        className="h-[152px] w-12 bg-black bg-opacity-50 z-10 relative cursor-pointer flex items-center justify-center -ml-[2.5rem] invisible md:visible"
                        onClick={() => scrollLeft()}
                        onMouseEnter={() => setLeftHovered(true)}
                        onMouseLeave={() => setLeftHovered(false)}
                    >
                        <i className={`fas fa-angle-left text-white text-5xl my-auto flex flex-col items-center justify-center ${leftHovered ? "text-6xl" : "text-5xl"}`}></i>
                    </div>
                    <div className="flex relative mt-[-9.5rem] overflow-x-scroll overflow-y-hidden scrollbar-hide h-48 scroll-smooth" ref={container}>
                        {videoHighlights.data.response.map((video, index) => (
                            <VideoHighlightCard key={video.title} video={video} setSelectedHighlight={setSelectedHighlight} />
                        ))}
                    </div>
                    <div
                        className="h-[152px] w-12 bg-black bg-opacity-50 z-10 cursor-pointer flex items-center justify-center absolute right-0 mt-[-12.05rem] invisible md:visible"
                        onClick={() => scrollRight()}
                        onMouseEnter={() => setRightHovered(true)}
                        onMouseLeave={() => setRightHovered(false)}
                    >
                        <i className={`fas fa-angle-right text-white my-auto flex flex-col items-center justify-center ${rightHovered ? "text-6xl" : "text-5xl"}`}></i>
                    </div>

                    <input type="checkbox" id="video-highlight-modal" className="modal-toggle" />
                    <label htmlFor="video-highlight-modal" className="cursor-pointer modal">
                        <label className="relative text-white modal-box" htmlFor="">
                            {selectedHighlight !== undefined && videoHighlights.data.response.filter(video => video.title === selectedHighlight).map(video => (
                                <div key={video.title}>
                                    <div>
                                        <h3 className="mb-3 text-lg font-bold">{video.title}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: video.videos?.[0].embed }}>
                                        </div>
                                    </div>
                                    <p className="py-4">{video.competition}</p>
                                </div>
                            ))}
                        </label>
                    </label>
                </>
            }
        </>
    );
}

export default FootballVideoHighlights;