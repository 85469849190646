import { db } from 'firebase-configs/config';
import { doc, updateDoc } from 'firebase/firestore';
import { UserContext } from 'layouts/App';
import { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

const FootballDashboardLeague = ({ league, leagues }) => {

    const user = useContext(UserContext);

    const [open, setOpen] = useState(false);

    //change league
    const [selectLeagueFilter, setSelectLeagueFilter] = useState(null);

    const changeDashboardLeague = async (leagueId) => {
        setOpen(false);
        if (!user) return;
        try {
            await updateDoc(doc(db, "users", user.userFirebase.uid), {
                dashboardLeague: leagueId,
            });
            toast.success("Your dashboard league has been successfully changed!");
        } catch (error) {
            toast.error("There was an error changing your dashboard league. Please try again.");
        }
    }

    return (
        <>
            {league.isSuccess && league.data.response.length > 0 &&
                <div className="relative flex items-center justify-center w-full px-4 mt-3">
                    <img src={league.data.response[0].country.flag} className='w-12 lg:w-20' />
                    <img src={league.data.response[0].league.logo} className='w-12 ml-4 lg:w-20' />
                    <h1 className="ml-4 text-center text-white md:text-4xl lg:text-6xl card-title">{league.data.response[0].league.name}</h1>
                    <label htmlFor="change-league-modal" className="ml-4 btn change-dashboard-league-btn"
                        onClick={(e) => {
                            if (!user) {
                                e.preventDefault();
                                toast.info("You need to be signed in to do that!");
                            } else {
                                setOpen(true);
                                leagues.refetch()
                            }
                        }}>
                        Change
                    </label>
                </div>
            }

            {/* change league modal */}
            <div className={`modal modal-bottom sm:modal-middle ${open ? "modal-open" : ""}`}>
                <div className="text-white modal-box">
                    <div className='flex justify-between align-center'>
                        <h3 className="text-lg font-bold">Select a League</h3>
                        <i className="text-lg cursor-pointer far fa-times-circle" onClick={() => setOpen(false)}></i>
                    </div>
                    <input
                        type="text"
                        placeholder="Search for league or country..."
                        className="w-full my-3 input input-bordered"
                        onChange={(e) => setSelectLeagueFilter(e.target.value)}
                    />
                    {leagues.isSuccess &&
                        <>
                            {leagues.data.response.length > 0 &&
                                <>
                                    {leagues.data.response.filter(league =>
                                        selectLeagueFilter === null ||
                                        (league.country.name.toLowerCase().includes(selectLeagueFilter?.toLowerCase()) ||
                                            league.league.name.toLowerCase().includes(selectLeagueFilter?.toLowerCase()))
                                    ).map(league => (
                                        <div key={league.league.id} onClick={() => changeDashboardLeague(league.league.id)}>
                                            <div className='border-b-2 border-gray-500'>
                                                <div className='flex items-center px-5 py-4 cursor-pointer hover:bg-slate-800 rounded-xl'>
                                                    <div>
                                                        <img src={league.league.logo} className='w-10 h-10 mr-4' />
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <span className='text-lg'>{league.league.name}</span>
                                                        <div className='flex flex-row items-center text-sm'>
                                                            {league.country.flag &&
                                                                <img className="w-5 mr-2" src={league.country.flag}></img>
                                                            }
                                                            <span>{league.country.name}</span>
                                                        </div>
                                                        <div className='flex flex-row mt-1 text-sm'>
                                                            {league.league.type === 'League' &&
                                                                <img className="w-5 mr-2" src={require('assets/img/timeline/league.svg').default}></img>
                                                            }
                                                            {league.league.type === 'Cup' &&
                                                                <img className="w-4 ml-1 mr-2" src={require('assets/img/timeline/trophy-icon.svg').default}></img>
                                                            }
                                                            <div>{league.league.type}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            }
                        </>
                    }
                    {leagues.isLoading &&
                        <Skeleton count={20} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                    }
                </div>
            </div>
        </>
    );
}

export default FootballDashboardLeague;