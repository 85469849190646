import { NavLink } from "react-router-dom";

const BottomNavigation = () => {
    return (
        <div className="z-50 text-white btm-nav btm-nav-sm md:hidden">
            <NavLink
                to="/football/dashboard"
                className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                }
            >
                <button>
                    <i className="mr-2 text-sm fas fa-home text-slate-300"></i>{" "}
                </button>
            </NavLink>
            <NavLink
                to="/forums"
                className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                }
            >
                <button>
                    <i className="mr-2 text-sm fas fa-edit text-slate-300"></i>{" "}
                </button>
            </NavLink>
            <NavLink
                to="/profile"
                className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                }
            >
                <button>
                    <i className="mr-2 text-sm fas fa-user text-slate-300"></i>{" "}
                </button>
            </NavLink>
        </div>
    );
}

export default BottomNavigation;