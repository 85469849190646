import { UserContext } from "layouts/App";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { signOutUser } from "../../firebase-configs/auth";

const UserDropdown = () => {

  const user = useContext(UserContext);

  return (
    <>
      {!user &&
        <Link
          className="px-4 py-2 mb-3 ml-3 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-sky-600 hover:bg-sky-700 hover:shadow-lg focus:outline-none lg:mr-1 lg:mb-0"
          type="button"
          to="/auth/login"
        >
          Login
        </Link>
      }
      {user &&
        <>
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="m-1 cursor-pointer">
              <img
                alt="Profile Image"
                className="w-12 align-middle border-none rounded-full shadow-lg"
                src={user.userFirebase.photoURL || require("assets/img/user_icon.svg").default}
                referrerPolicy="no-referrer"
              />
            </label>
            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52 -m-4">
              <Link className="block w-full px-4 py-2 text-sm font-normal text-white bg-transparent hover:text-gray-400 whitespace-nowrap" to="/profile">Profile</Link>
              <Link className="block w-full px-4 py-2 text-sm font-normal text-white bg-transparent hover:text-gray-400 whitespace-nowrap" to="/settings">Settings</Link>
              <button
                className="block w-full px-4 py-2 text-sm font-normal text-left text-red-500 bg-transparent hover:text-red-800 whitespace-nowrap"
                onClick={(e) => { e.preventDefault(); signOutUser(); }}
              >
                Logout
              </button>
            </ul>
          </div>
        </>
      }
    </>
  );
};

export default UserDropdown;
