import DOMPurify from "dompurify";
import { db, messaging } from "firebase-configs/config";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { getToken, onMessage } from 'firebase/messaging';
import JSConfetti from 'js-confetti';
import { toast } from 'react-toastify';
var filter = require('leo-profanity');

export const fetchFootballData = async (endpoint, qs) => {
    let res = await fetch(`https://v3.football.api-sports.io/${endpoint}${qs !== undefined && qs !== null ? qs : ''}`, {
        headers: {
            'x-rapidapi-host': 'v3.football.api-sports.io',
            'x-rapidapi-key': 'bfead32253dd3252b972620a2deef3b1'
        }
    });
    return res.json();
}

export const confetti = new JSConfetti();

export const sanitize = (text) => {
    return filter.clean(DOMPurify.sanitize(text));
}

onMessage(messaging, (payload) => {
    // console.log('Message received. ', payload);
    confetti.addConfetti({
        emojis: ['😀', '⚽', '👍', '😍'],
    });
    toast(
        <div className="flex flex-row items-center gap-5">
            <div>
                <img className="w-8" src={payload.notification.image} />
            </div>
            <div className="flex flex-col">
                <div className="text-lg text-semibold">{payload.notification.title}</div>
                <span>{payload.notification.body}</span>
            </div>
        </div>,
        {
            position: "top-center",
        })
});

export const getFCMToken = async (user) => {
    console.log("test");
    try {
        let token = await getToken(messaging, { vapidKey: "BGNR2VHL0NgTcLsr-MNq8ZdPIX2fvEdGLGjUjsZqmvjYoYg3HU8-bF3uABtiuuQsBhLT_tfGXVFnQei0bK7TDyA" });
        if (token) {
            if (!user.userFirestore.FCMTokens?.find(token => token.token === token)) {
                await updateDoc(doc(db, "users", user.userFirebase.uid), {
                    FCMTokens: arrayUnion({
                        device: getOperatingSystem(),
                        token: token
                    })
                })
            }
            return true;
        }
    } catch (error) {
        if (error.code == "messaging/permission-blocked") {
            toast.error("Uhh, you denied notifications. You need to enable notifications first. You may need to delete your cache and try again.")
        }
        return false;
    }
}

const getOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "Desktop";
}

