import Skeleton from "react-loading-skeleton";
import Fixture from "./Fixture";

const HeadToHead = ({ headToHead }) => {
    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="card-body">
                <h2 className="text-white card-title">Head To Heads</h2>
                <small><p className="text-white">Previous Head to Heads between these two teams</p></small>
                {headToHead.isSuccess &&
                    <>
                        {headToHead.data.response.length > 0 &&
                            <>
                                {headToHead.data.response.sort((a, b) => new Date(b.fixture.date) - new Date(a.fixture.date)).map(fixture => (
                                    <Fixture fixture={fixture} key={fixture.fixture.id} extraSmall />
                                ))}
                            </>
                        }
                    </>
                }
                {headToHead.isLoading &&
                    <Skeleton count={5} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div>
    );
}

export default HeadToHead;