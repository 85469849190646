import { useQuery } from "react-query";

const MatchVideoHighlights = ({ match }) => {

    const videoHighlights = useQuery('videoHighlights', async () => {
        let res = await fetch('https://www.scorebat.com/video-api/v3/feed/?token=MzA0NTdfMTY3NzYyNjA0MV8xNmIxZjRmZWVhNDNmODk3ZTEyM2Q3ZWM5ZmVjMjRhZWY0NDYxNGYx');
        return res.json();
    });

    const embedUrl = videoHighlights.data?.response?.[videoHighlights.data?.response?.findIndex(video => video.title === `${match.data?.response?.[0].teams.home.name} - ${match.data?.response?.[0].teams.away.name}` || video.title === `${match.data?.response?.[0].teams.away.name} - ${match.data?.response?.[0].teams.home.name}`)]?.videos?.[0]?.embed

    return (
        <>
            {videoHighlights.isSuccess && match.isSuccess && embedUrl &&
                <>
                    {videoHighlights.data.response.length > 0 && match.data.response.length > 0 &&
                        <div className="w-full card bg-slate-900">
                            <div className="gap-0 card-body">
                                <div dangerouslySetInnerHTML={{
                                    __html: embedUrl
                                }}>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }

        </>
    );
}

export default MatchVideoHighlights;