import { db } from "firebase-configs/config";
import { doc, updateDoc } from "firebase/firestore";
import { UserContext } from "layouts/App";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

const TeamHeader = ({ team }) => {

    const user = useContext(UserContext);

    const isUsersFavoriteTeam = user?.userFirestore?.favoriteTeam === team?.data?.response?.[0]?.team?.id;

    const setFavorite = async () => {
        if (!user) {
            toast.error("Sorry, you need to be signed in to do that!");
            return;
        }
        await updateDoc(doc(db, "users", user.userFirebase.uid), {
            favoriteTeam: isUsersFavoriteTeam ? "" : team.data.response[0].team.id
        });
        toast.success(`${team.data.response[0].team.name} has been ${isUsersFavoriteTeam ? "removed" : "set"} as your favorite team!`)
    }

    const generateTeamDescription = () => {
        let teamInfo = team?.data.response?.[0];
        let description = "";
        if (teamInfo.team.national == true) {
            description += `The ${teamInfo.team.name} national team is a football team based in ${teamInfo !== null ? teamInfo.venue.city + "," : ""} ${teamInfo.team.country}. `;
        } else {
            description += `${teamInfo.team.name} is a football team from ${teamInfo !== null ? teamInfo.venue.city + "," : ""} ${teamInfo.team.country}. `;
        }
        if (teamInfo.team.founded !== null) description += `They were founded in ${teamInfo.team.founded}. `;
        if (teamInfo.venue.name !== null) description += `Their home stadium is ${teamInfo.venue.name}. `;
        return description;
    };
    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="card-body">
                <div className="flex self-end">
                    <div className="tooltip" data-tip="My Favorite Team">
                        <i
                            className={`cursor-pointer ${isUsersFavoriteTeam ? 'fas' : 'far'} fa-star fa-2x`}
                            onClick={() => setFavorite()}>
                        </i>
                    </div>
                </div>
                <div className="flex">
                    {team.isSuccess &&
                        <>
                            {team.data.response.length > 0 &&
                                <>
                                    <Helmet>
                                        <title>{team.data.response[0].team.name}</title>
                                    </Helmet>
                                    <div className="w-full md:w-3/12">
                                        <img src={team.data.response[0].team.logo} className="w-48 mx-auto rounded-full" alt="Player" />
                                    </div>
                                    <div className="w-full md:w-9/12">
                                        <span className="text-2xl font-bold">{team.data.response[0].team.name}</span>
                                        <div>{generateTeamDescription()}</div>
                                    </div>
                                </>
                            }
                        </>
                    }
                    {team.isLoading &&
                        <>
                            <Skeleton count={1} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"25px"} style={{ marginTop: "6px", marginBottom: "10px" }} />
                            <Skeleton count={5} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default TeamHeader;