import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// components

import FooterAdmin from "components/Footers/FooterAdmin.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";

// views
import Countdowns from 'components/Headers/Countdowns';
import FootballVideoHighlights from "components/Headers/FootballVideoHighlights";
import Jumbotron from "components/Headers/Jumbotron";
import AuthRequiredRoute from "components/Other/AuthRequiredRoute";
import { confetti } from "js/common";
import CreateAPool from "views/football/CreateAPool";
import FootballDashboard from "views/football/FootballDashboard";
import Match from "views/football/Match";
import Matches from "views/football/Matches";
import Player from "views/football/Player";
import Pools from "views/football/Pools";
import Team from "views/football/Team";

export default function Football() {

    const location = useLocation();

    return (
        <>
            <div className="relative md:ml-64 bg-gradient-to-b from-indigo-950 from-20% to-slate-800 to-60% min-h-screen">
                <AdminNavbar layoutName={
                    <>
                        <span className="flex items-center">
                            <img src={require("../assets/img/timeline/football.svg").default} className="w-4 mr-2 cursor-pointer" onClick={() => confetti.addConfetti({
                                emojis: ['⚽'],
                            })}>
                            </img>
                            Football
                        </span>
                    </>
                } />
                {/* Header */}
                <Jumbotron>
                    {location.pathname === "/football/dashboard" &&
                        <>
                            <Countdowns />
                            <FootballVideoHighlights />
                        </>
                    }
                    <HeaderStats />
                </Jumbotron>
                <div className="w-full mx-auto -m-24 md:px-10 4xl:px-36">
                    <Routes>
                        <Route path="/dashboard" element={<FootballDashboard />} />
                        <Route path="/matches" element={<Matches />} />
                        <Route path="/match/:fixtureId" element={<Match />} />
                        <Route path="/player/:playerId" element={<Player />} />
                        <Route path="/team/:teamId" element={<Team />} />
                        <Route path="/pools" element={<AuthRequiredRoute><Pools /></AuthRequiredRoute>} />
                        <Route path="/pools/createapool/:leagueId?" element={<AuthRequiredRoute><CreateAPool /></AuthRequiredRoute>} />
                        <Route path="*" element={<Navigate to="/football/dashboard" />} />
                    </Routes>
                    <FooterAdmin />
                </div>
            </div>
        </>
    );
}
