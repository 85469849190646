import { db } from "firebase-configs/config";
import { collection, getCountFromServer, query, where } from "firebase/firestore";
import { UserContext } from "layouts/App";
import { useContext, useEffect, useState } from "react";

// components

export default function CardProfile() {

  const user = useContext(UserContext);
  const [postsCount, setPostsCount] = useState();
  const [commentsCount, setCommentsCount] = useState();
  const [chatsCount, setChatsCount] = useState();

  useEffect(() => {
    const posts = query(collection(db, "forum_posts"), where("userId", "==", user?.userFirebase?.uid));
    getCountFromServer(posts).then(snapshot => {
      setPostsCount(snapshot.data().count || 0)
    });
    const comments = query(collection(db, "forum_posts_comments"), where("userId", "==", user?.userFirebase?.uid));
    getCountFromServer(comments).then(snapshot => {
      setCommentsCount(snapshot.data().count || 0)
    });
    const chats = query(collection(db, "chat_message"), where("userId", "==", user?.userFirebase?.uid));
    getCountFromServer(chats).then(snapshot => {
      setChatsCount(snapshot.data().count || 0)
    });
  }, [user])

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mt-16 mb-6 text-white break-words rounded-lg shadow-xl bg-slate-900">
        <div className="px-6 pb-10">
          <div className="flex flex-wrap justify-center">
            <div className="flex justify-center w-full px-4">
              <div className="relative">
                <img
                  alt="..."
                  src={user.userFirebase.photoURL ? user.userFirebase.photoURL : require("assets/img/user_icon.svg").default}
                  className="absolute h-auto -m-16 align-middle border-none rounded-full shadow-xl -ml-11 max-w-150-px"
                />
              </div>
            </div>
            <div className="w-full px-4 mt-20 text-center">
              <div className="flex justify-between gap-0 py-4 pt-8 lg:pt-4">
                <div className="py-3 text-center max-w-min">
                  <span className="block text-xl font-bold tracking-wide uppercase">
                    {postsCount}
                  </span>
                  <span className="text-sm text-slate-400">Posts</span>
                </div>
                <div className="py-3 text-center max-w-min">
                  <span className="block text-xl font-bold tracking-wide uppercase">
                    {commentsCount}
                  </span>
                  <span className="text-sm text-slate-400">Comments</span>
                </div>
                <div className="py-3 text-center max-w-min">
                  <span className="block text-xl font-bold tracking-wide uppercase">
                    {chatsCount}
                  </span>
                  <span className="text-sm text-slate-400">Chat Messages</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 text-center">
            <h3 className="mb-2 text-xl font-semibold leading-normal">
              {user.userFirebase.displayName}
            </h3>
            <h6 className="mb-2 text-sm font-semibold">
              {user.userFirestore?.username}
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}
