import Chart from "chart.js";
import React, { useState } from "react";

const randomColor = () => '#' + Math.floor(Math.random() * 16777215).toString(16);

export default function PieChart({ label, data, id, heading, setClickedBet }) {

    const [chart, setChart] = useState();

    React.useEffect(() => {
        if (chart) {
            chart.data.labels = data.map(label => label.label);
            chart.data.datasets = [{
                label: label,
                data: data.map(data => data.value || 0),
                backgroundColor: Array.from(Array(data.length)).map(item => randomColor()),
            }]
            chart.update();
            return;
        } else {
            var config = {
                type: "pie",
                data: {
                    labels: data.map(label => label.label),
                    datasets: [{
                        label: label,
                        data: data.map(data => data.value || 0),
                        backgroundColor: Array.from(Array(data.length)).map(item => randomColor()),
                        hoverOffset: 4
                    }]
                },
                options: {
                    legend: {
                        labels: {
                            fontColor: "white",
                            fontSize: 12
                        }
                    },
                    title: {
                        display: true,
                        text: heading || "",
                        fontSize: 16,
                        fontColor: 'white'
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    onClick: function (e, item) {
                        if (item.length) {
                            const data = item[0]._chart.config.data.datasets[0].data[item[0]._index];
                            setClickedBet?.(data);
                        }
                    }
                }
            };
            var ctx = document.getElementById(id).getContext("2d");
            setChart(new Chart(ctx, config));
        }
    }, [JSON.stringify(data)]);
    return (
        <div className="h-80">
            <canvas id={id}></canvas>
        </div>
    );
}
