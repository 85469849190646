import TeamHeader from "components/Cards/Football/TeamHeader";
import TeamSquad from "components/Cards/Football/TeamSquad";
import TeamTransfers from "components/Cards/Football/TeamTransfers";
import TeamVenue from "components/Cards/Football/TeamVenue";
import { fetchFootballData } from "js/common";
import { useQuery } from "react-query";
import { useParams } from "react-router";

const Team = () => {

    const { teamId } = useParams();
    const team = useQuery(['team', teamId], () => fetchFootballData('teams', `?id=${teamId}`));
    const squad = useQuery(['teams/squads', teamId], () => fetchFootballData('players/squads', `?team=${teamId}`));
    const transfers = useQuery(['teams/transfers', teamId], () => fetchFootballData('transfers', `?team=${teamId}`));

    return (
        <div className="flex flex-col">
            <div className="w-full px-3 pt-3">
                <TeamHeader team={team} />
            </div>
            <div className="flex flex-col lg:flex-row">
                <div className="w-full px-3 pt-3 lg:w-6/12">
                    <TeamSquad squad={squad} />
                </div>
                <div className="flex-col w-full px-3 pt-3 lg:w-6/12">
                    <div className="pb-2">
                        <TeamVenue team={team} />
                    </div>
                    <div>
                        <TeamTransfers transfers={transfers} teamId={teamId} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Team;