import { resetPassword } from "firebase-configs/auth";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function ForgotPassword() {

    const [email, setEmail] = useState();
    const [loading, setLoading] = useState();

    const submitPasswordReset = async (e) => {
        e.preventDefault();
        try {
            setEmail("");
            setLoading(true);
            await resetPassword(email);
            toast.info("A password email link has been sent. Please check your email inbox.");
        } catch (error) {
            toast.error("There was an error sending the password reset email.");
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <div className="container h-full px-4 py-4 mx-auto">
                <div className="flex items-center content-center justify-center h-full">
                    <div className="w-full px-4 lg:w-4/12">
                        <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-slate-200">
                            <div className="flex-auto px-4 py-10 lg:px-10">
                                <div className="mb-3 text-lg font-semibold">Forgot Password</div>
                                <div className="mb-3 text-sm">Its okay. It happens. Enter your email address and if we have a record of your email, you'll receive a password reset link.</div>
                                <form onSubmit={submitPasswordReset}>
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block mb-2 text-xs font-bold uppercase text-slate-600"
                                            htmlFor="grid-password"
                                        >
                                            Email
                                        </label>
                                        <input
                                            required
                                            type="email"
                                            autoComplete="username"
                                            className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-slate-300 text-slate-600 focus:outline-none focus:ring"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-6 text-center">
                                        <button
                                            className="flex items-center justify-center w-full px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-slate-800 active:bg-slate-600 hover:shadow-lg focus:outline-none"
                                            type="submit"
                                        >
                                            Submit
                                            {loading &&
                                                <span className="my-auto ml-2 loading loading-spinner loading-sm"></span>
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="relative flex flex-wrap mt-6">
                            <div className="w-1/2">
                                <Link to="/auth/login" className="text-slate-200">
                                    <small>Sign In</small>
                                </Link>
                            </div>
                            <div className="w-1/2 text-right">
                                <Link to="/auth/register" className="text-slate-200">
                                    <small>Create new account</small>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
