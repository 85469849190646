import PieChart from "components/Charts/PieChart";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

const MatchOdds = ({ match, odds }) => {

    const [selectedBookmaker, setSelectedBookmaker] = useState();
    const [selectedBet, setSelectedBet] = useState();
    const [betAmount, setBetAmount] = useState(100);
    const [estimatedReturn, setEstimatedReturn] = useState(100);
    const [clickedBet, setClickedBet] = useState(1);

    useEffect(() => {
        setEstimatedReturn((betAmount * clickedBet).toFixed(2))
    }, [clickedBet])

    return (
        <div className="card w-full bg-slate-900 text-white h-[650px]">
            <div className="gap-0 card-body">
                <h2 className="text-white card-title">Odds</h2>
                {match.isSuccess && match.data.response.length > 0 &&
                    <small>
                        <p className="text-white">
                            {match.data.response[0].fixture.status.long === "Match Finished" ?
                                <span>These were the odds up to the beginning of the match. Pre Match Odds are available for up to 7 days after the match concludes.</span>
                                :
                                <span>These are the pre match odds. They will be updated in real time when the match goes live.</span>
                            }
                            <span className="font-semibold"> For informational purposes only</span>
                        </p>
                    </small>
                }
                {odds.isSuccess &&
                    <>
                        {odds.data.response.length > 0 &&
                            <>
                                <select
                                    className="w-full my-2 select select-bordered"
                                    onChange={(e) => setSelectedBookmaker(e.target.value)}
                                    defaultValue="notSelected"
                                >
                                    <option value="notSelected">Select Bookmaker</option>
                                    {odds.data.response[0].bookmakers.map(bookmaker => (
                                        <option value={bookmaker.id} key={bookmaker.id}>{bookmaker.name}</option>
                                    ))}
                                </select>
                                <select
                                    className="w-full my-2 select select-bordered"
                                    onChange={(e) => setSelectedBet(e.target.value)}
                                    defaultValue="notSelected"
                                >
                                    <option value="notSelected">Select Bet</option>
                                    {odds.data.response[0].bookmakers[0].bets.map(bet => (
                                        <option value={bet.id} key={bet.id}>{bet.name}</option>
                                    ))}
                                </select>
                                <div className="flex gap-5">
                                    <div className="w-full max-w-xs form-control">
                                        <label className="py-0 label">
                                            <span className="label-text">Your Bet</span>
                                        </label>
                                        <input
                                            type="text"
                                            value={betAmount}
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value)) {
                                                    setBetAmount(e.target.value);
                                                    setEstimatedReturn((e.target.value * clickedBet).toFixed(2))
                                                }
                                            }}
                                            placeholder="Enter Amount"
                                            className="w-full my-2 text-white bg-transparent border-2 input border-slate-700" />
                                    </div>
                                    <div className="w-full max-w-xs form-control">
                                        <label className="py-0 label">
                                            <span className="label-text">Your Est Return</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder=""
                                            value={estimatedReturn}
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value)) {
                                                    setEstimatedReturn(e.target.value);
                                                    setBetAmount((e.target.value / clickedBet).toFixed(2))
                                                }
                                            }} className="w-full my-2 text-white text-opacity-100 bg-transparent border-2 input border-slate-700" />
                                    </div>
                                </div>
                                {selectedBet !== undefined && selectedBookmaker !== undefined &&
                                    <>
                                        <PieChart
                                            label={`${selectedBookmaker}-${selectedBet}`}
                                            id="preMatchOddsChart"
                                            data={(odds.data.response[0].bookmakers.find(bookermaker => bookermaker.id == selectedBookmaker)?.bets.find(bet => bet.id == selectedBet)?.values)?.map(item => ({ label: item.value, value: item.odd })) || []}
                                            setClickedBet={setClickedBet}
                                        />
                                    </>
                                }
                            </>
                        }
                        {odds.data.response.length === 0 &&
                            <div className="flex items-center justify-center h-full">
                                <span className="text-sm text-gray-400">No Data Available (Yet!)</span>
                            </div>
                        }
                    </>
                }
                {odds.isLoading &&
                    <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div>
    );
}

export default MatchOdds;