import Actions from "components/Other/Actions";
import { confetti } from "js/common";
import { UserContext } from "layouts/App";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const MatchHeader = ({ match, predictions }) => {

    const user = useContext(UserContext);
    const [celebrationRan, setCelebrationRan] = useState(false);

    useEffect(() => {
        if (match.isSuccess && match.data.response.length > 0) {
            if (user) {
                if ((((user.userFirestore?.favoriteTeam === match.data.response[0].teams.home.id) && match.data.response[0].teams.home.winner) ||
                    ((user.userFirestore?.favoriteTeam === match.data.response[0].teams.away.id) && match.data.response[0].teams.away.winner)) &&
                    !celebrationRan) {
                    toast.success(
                        <div className="flex items-center justify-center text-center">
                            🎉🥳 WINNER WINNER CHICKEN DINNER. YOUR FAVORITE TEAM WON! 🥳🎉
                        </div>, {
                        position: "top-center",
                        autoClose: 10000
                    });
                    confetti.addConfetti({
                        emojis: ['🥳', '🎉', '🎊', '🏆'],
                    });
                    setCelebrationRan(true);
                }
            }
        }
    })

    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="gap-0 card-body">
                {match.isSuccess &&
                    <>
                        {match.data.response.length > 0 &&
                            <>
                                <Helmet>
                                    <title>{match.data.response[0].teams.home.name} vs {match.data.response[0].teams.away.name}</title>
                                </Helmet>
                                <div className="flex items-center justify-around text-center">
                                    <div>
                                        <span>Home</span>
                                        <img src={match.data.response[0].teams.home.logo} alt="Home Team" className="w-12 mx-auto mt-2 xl:w-40" />
                                        <Link to={`../team/${match.data.response[0].teams.home.id}`} className="hidden mt-2 text-xl font-bold xl:block hover:underline">{match.data.response[0].teams.home.name}</Link>
                                        <div className="block mt-2 text-xl font-bold xl:hidden">{match.data.response[0].teams.home.name.substring(0, 3).toUpperCase()}</div>
                                        {predictions.isSuccess && predictions.data.response.length > 0 &&
                                            <>
                                                {predictions.data.response[0].predictions.winner.id === match.data.response[0].teams.home.id &&
                                                    <div className="gap-2 py-3 mt-1 text-white badge badge-info">
                                                        <span className="hidden md:inline">{predictions.data.response[0].predictions.winner.comment}</span>
                                                        <i className="fas fa-chart-line"></i>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div>
                                        <span className="text-3xl">{match.data.response[0].goals.home}</span>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="flex-col hidden xl:flex">
                                            {match.data.response[0].fixture.status.long !== "Match Finished" && match.data.response[0].fixture.status.elapsed !== null ?
                                                match.data.response[0].fixture.status.long !== "Halftime" ?
                                                    <>
                                                        {match.data.response[0].fixture.status.elapsed}'
                                                        <img src={require("assets/img/svg/live-game.svg").default} className="w-8 mx-auto" />
                                                    </>
                                                    :
                                                    <>
                                                        {match.data.response[0].fixture.status.long}
                                                        <img src={require("assets/img/svg/live-game.svg").default} className="w-8 mx-auto" />
                                                    </>
                                                :
                                                <>
                                                    <div>{moment(match.data.response[0].fixture.date).format('LL')}</div>
                                                    {match.data.response[0].fixture.status.long}
                                                </>
                                            }
                                            <span>{match.data.response[0].league.season} {match.data.response[0].league.name}</span>
                                            <span>{match.data.response[0].league.round}</span>
                                            <Actions
                                                relatedEntityId={match.data.response[0].fixture.id}
                                                show={match.data.response[0].fixture.status.long !== "Match Finished"}
                                            />
                                        </div>
                                        <div className="block xl:hidden">
                                            <span className="text-xl font-bold">{match.data.response[0].fixture.status.short}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-3xl">{match.data.response[0].goals.away}</span>
                                    </div>
                                    <div>
                                        <span>Away</span>
                                        <img src={match.data.response[0].teams.away.logo} alt="Away Team" className="w-12 mx-auto mt-2 xl:w-40" />
                                        <Link to={`../team/${match.data.response[0].teams.away.id}`} className="hidden mt-2 text-xl font-bold xl:block hover:underline">{match.data.response[0].teams.away.name}</Link>
                                        <div className="block mt-2 text-xl font-bold xl:hidden">{match.data.response[0].teams.away.name.substring(0, 3).toUpperCase()}</div>
                                        {predictions.isSuccess && predictions.data.response.length > 0 &&
                                            <>
                                                {predictions.data.response[0].predictions.winner.id === match.data.response[0].teams.away.id &&
                                                    <div className="gap-2 py-3 mt-1 text-white badge badge-info">
                                                        <span className="hidden md:inline">{predictions.data.response[0].predictions.winner.comment}</span>
                                                        <i className="fas fa-chart-line"></i>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center mt-3 xl:hidden">
                                    <Actions
                                        relatedEntityId={match.data.response[0].fixture.id}
                                        show={match.data.response[0].fixture.status.long !== "Match Finished"}
                                    />
                                    <div className="flex justify-between py-3 text-sm border-b-2 border-gray-700">
                                        <span>Date and Time</span>
                                        <span>{moment(match.data.response[0].fixture.date).format('LLL')}</span>
                                    </div>
                                    <div className="flex justify-between py-3 text-sm border-b-2 border-gray-700">
                                        <span>Country</span>
                                        <span>{match.data.response[0].league.country}</span>
                                    </div>
                                    <div className="flex justify-between py-3 text-sm border-b-2 border-gray-700">
                                        <span>League</span>
                                        <span>{match.data.response[0].league.season} {match.data.response[0].league.name}</span>
                                    </div>
                                    <div className="flex justify-between py-3 text-sm border-b-2 border-gray-700">
                                        <span>Round</span>
                                        <span>{match.data.response[0].league.round}</span>
                                    </div>
                                    <div className="flex justify-between py-3 text-sm border-b-2 border-gray-700">
                                        <span>Referee</span>
                                        <span>{match.data.response[0].fixture.referee}</span>
                                    </div>
                                    <div className="flex items-center justify-between py-3 text-sm border-b-2 border-gray-700">
                                        <span>Stadium</span>
                                        <span className="text-xs">{match.data.response[0].fixture.venue.name}</span>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
                {match.isLoading &&
                    <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div>
    );
}

export default MatchHeader;