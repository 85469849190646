import { Navigate, Route, Routes } from "react-router-dom";

// components

import FooterSmall from "components/Footers/FooterSmall.js";

// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";

import AdditionalUserInfo from "views/auth/AdditionUserInfo";
import ForgotPassword from "views/auth/ForgotPassword";
import Background from '../assets/img/register_bg_2.png';

export default function Auth() {
  return (
    <>
      <main>
        <section className="relative min-h-screen py-40 md:ml-64">
          <div
            className="absolute top-0 w-full h-full bg-no-repeat bg-slate-800 bg-full"
            style={{
              backgroundImage:
                `url(${Background})`,
            }}
          ></div>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/additionalinfo" element={<AdditionalUserInfo />} />
            <Route path="*" element={<Navigate to="/auth/login" />} />
          </Routes>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
