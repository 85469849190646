import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { redirectResult, signInGoogle, signInWithEmailPassword } from '../../firebase-configs/auth';

export default function Login() {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        redirectResult(() => navigate('/'), () => navigate('/auth/additionalinfo'));
    }, []);

    const submitLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            let userCredential = await signInWithEmailPassword(email, password);
            setLoading(false);
            toast.info(`Welcome ${userCredential.user.displayName}, you've signed in successfully!`);
            navigate('/');
        } catch (error) {
            setLoading(false);
            if (error.code === "auth/wrong-password" || error.code === "auth/user-not-found")
                toast.error("Incorrect email or password. Please try again.");
            if (error.code === "auth/user-disabled")
                toast.error("You account has been disabled. Please contact support to resolve this issue.", { autoClose: 20000 });
        }
    }

    return (
        <>
            <div className="container h-full px-4 py-4 mx-auto">
                <div className="flex items-center content-center justify-center h-full">
                    <div className="w-full px-4 lg:w-4/12">
                        <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-slate-200">
                            <div className="px-6 py-6 mb-0 rounded-t">
                                <div className="mb-3 text-center">
                                    <h6 className="text-sm font-bold text-slate-500">
                                        Sign in with
                                    </h6>
                                </div>
                                <div className="text-center btn-wrapper">
                                    <button
                                        className="inline-flex items-center px-4 py-2 mb-1 mr-1 text-xs font-bold uppercase transition-all duration-150 ease-linear bg-white rounded shadow outline-none active:bg-slate-50 text-slate-700 focus:outline-none hover:shadow-md"
                                        type="button"
                                        onClick={(() => signInGoogle())}
                                    >
                                        <img
                                            alt="..."
                                            className="w-5 mr-1"
                                            src={require("assets/img/google.svg").default}
                                        />
                                        Google
                                    </button>
                                </div>
                                <hr className="mt-6 border-b-1 border-slate-300" />
                            </div>
                            <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
                                <div className="mb-3 font-bold text-center text-slate-400">
                                    <small>Or sign in with credentials</small>
                                </div>
                                <form onSubmit={submitLogin}>
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block mb-2 text-xs font-bold uppercase text-slate-600"
                                            htmlFor="grid-password"
                                        >
                                            Email
                                        </label>
                                        <input
                                            required
                                            type="email"
                                            autoComplete="username"
                                            className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-slate-300 text-slate-600 focus:outline-none focus:ring"
                                            placeholder="Email"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>

                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block mb-2 text-xs font-bold uppercase text-slate-600"
                                            htmlFor="grid-password"
                                        >
                                            Password
                                        </label>
                                        <input
                                            autoComplete="current-password"
                                            required
                                            type="password"
                                            className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-slate-300 text-slate-600 focus:outline-none focus:ring"
                                            placeholder="Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label className="inline-flex items-center cursor-pointer">
                                            <input
                                                id="customCheckLogin"
                                                type="checkbox"
                                                className="w-5 h-5 ml-1 transition-all duration-150 ease-linear border-0 rounded form-checkbox text-slate-700"
                                            />
                                            <span className="ml-2 text-sm font-semibold text-slate-600">
                                                Remember me
                                            </span>
                                        </label>
                                    </div>

                                    <div className="mt-6 text-center">
                                        <button
                                            className="flex items-center justify-center w-full px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-slate-800 active:bg-slate-600 hover:shadow-lg focus:outline-none"
                                            type="submit"
                                        >
                                            Sign In
                                            {loading &&
                                                <span className="my-auto ml-2 loading loading-spinner loading-sm"></span>
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="relative flex flex-wrap mt-6">
                            <div className="w-1/2">
                                <Link to="/auth/forgotpassword" className="text-slate-200">
                                    <small>Forgot password?</small>
                                </Link>
                            </div>
                            <div className="w-1/2 text-right">
                                <Link to="/auth/register" className="text-slate-200">
                                    <small>Create new account</small>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
