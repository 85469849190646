import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { db } from "firebase-configs/config";
import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { UserContext } from "layouts/App";
import moment from "moment";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";

const NotificationDropdown = () => {

  const user = useContext(UserContext);

  const userNotifications = useFirestoreQuery(["user_notifications", user?.userFirebase?.uid], user?.userFirebase?.uid && query(collection(db, "user_notifications"), where("userId", '==', user?.userFirebase?.uid), orderBy('createdOn', 'desc'), limit(5)), { subscribe: true }, { enabled: !!user?.userFirebase?.uid });

  return (
    <div className="dropdown dropdown-end">
      <label tabIndex={0} className="m-1 cursor-pointer">
        <i className="text-white fas fa-bell fa-2x"></i>
      </label>
      <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-72 mt-4">
        {userNotifications.isSuccess &&
          <>
            {userNotifications.data.size > 0 &&
              <>
                {userNotifications.data.docs.map((doc) => (
                  <div className="px-2 py-4 text-white border-b border-gray-700" key={doc.id}>
                    <div>{doc.data().text}</div>
                    <span className="text-xs text-gray-400">{moment(doc.data().createdOn?.toDate()).fromNow()}</span>
                  </div>
                ))}
              </>
            }
            {userNotifications.data.size === 0 &&
              <div className="flex items-center justify-center min-h-[100px]">
                <span className="text-sm text-gray-400">No Notifications</span>
              </div>
            }
          </>
        }
        {userNotifications.isLoading &&
          <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
        }
        {userNotifications.isError &&
          <div className="flex items-center justify-center min-h-[100px]">
            <span className="text-sm text-gray-400">Sorry, something went wrong getting this data</span>
          </div>
        }
      </ul>
    </div>
  );
};

export default NotificationDropdown;
