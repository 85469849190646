import { Chart } from "chart.js";
import { useEffect, useState } from "react";

const StackedBarChart = ({ labels, id, data, heading }) => {

    const [chart, setChart] = useState();

    useEffect(() => {
        if (chart) {
            chart.data.labels = labels;
            chart.data.datasets = data.map(item => { return { ...item } });
            chart.update();
            return;
        } else {
            const config = {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: data.map(item => { return { ...item } })
                },
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: 'Chart.js Bar Chart - Stacked'
                        },
                    },
                    title: {
                        display: true,
                        text: heading || "",
                        fontSize: 16,
                        fontColor: 'white'
                    },
                    legend: {
                        labels: {
                            fontColor: "white",
                            fontSize: 12
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            stacked: true,
                            ticks: {
                                fontColor: "white",
                                fontSize: 14,
                                stepSize: 1,
                                beginAtZero: true
                            }
                        }],
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                fontColor: "white",
                                stepSize: 1,
                            }
                        }]
                    },
                }
            };
            var ctx = document.getElementById(id).getContext("2d");
            setChart(new Chart(ctx, config));
        }
    }, [JSON.stringify(data)]);

    return (
        <div className="h-[400px]">
            <canvas id={id}></canvas>
        </div>
    );
}

export default StackedBarChart;