import Fixtures from "components/Cards/Football/Fixtures";
import { fetchFootballData } from "js/common";
import moment from "moment";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";

const Matches = () => {

    const today = moment();

    const [countrySearchTerm, setCountrySearchTerm] = useState('');

    const [selectedCountry, setSelectedCountry] = useState('All');
    const [selectedLeague, setSelectedLeague] = useState(null);
    const [selectedDate, setSelectedDate] = useState();

    const countries = useQuery("countries", () => fetchFootballData("countries"), { staleTime: 100 * (60 * 1000), cacheTime: 150 * (60 * 1000) });

    const leagues = useQuery(["leagues", selectedCountry], () => fetchFootballData("leagues", `?country=${selectedCountry}`), {
        staleTime: 100 * (60 * 1000), cacheTime: 150 * (60 * 1000), enabled: selectedCountry !== 'All'
    });

    const matches = useQuery(["fixtures", selectedDate, selectedLeague],
        () => fetchFootballData("fixtures",
            `?${selectedDate !== null ? (!selectedDate ? 'live=all' : `date=${selectedDate.format('YYYY-MM-DD')}`) : ''}${selectedLeague !== null ? `league=${selectedLeague.id}&season=${selectedLeague.season}` : ''}`)
    );

    return (
        <>
            <Helmet>
                <title>Matches</title>
            </Helmet>
            <div className="flex flex-col px-4 md:flex-row">
                <div className="hidden px-0 my-2 lg:block lg:w-3/12 md:px-2">
                    <div className="w-full text-white card bg-slate-900">
                        <div className="gap-0 card-body">
                            {selectedCountry !== "All" &&
                                <i
                                    className="my-2 cursor-pointer fas fa-chevron-left"
                                    onClick={() => {
                                        setSelectedCountry('All');
                                        setSelectedLeague(null);
                                        setSelectedDate();
                                    }}
                                />
                            }
                            <div className="flex items-center justify-between">
                                <h2 className="inline text-white card-title">Filter</h2>
                            </div>
                            <h4>{selectedCountry}</h4>

                            {selectedCountry === "All" &&
                                <input
                                    value={countrySearchTerm}
                                    type="text"
                                    placeholder="search..."
                                    className="w-full my-2 text-white bg-transparent border-2 input border-slate-700"
                                    onChange={(e) => setCountrySearchTerm(e.target.value.toLowerCase())}
                                />
                            }
                            {countries.isSuccess && selectedCountry === "All" &&
                                <div className="mt-4">
                                    {countries.data.response.filter(country => !countrySearchTerm || country.name.toLowerCase().includes(countrySearchTerm)).map(country => (
                                        <div
                                            className="border-b-2 border-gray-700"
                                            key={country.name}
                                            onClick={(e) => setSelectedCountry(e.currentTarget.getAttribute('data-country'))}
                                            data-country={country.name}
                                        >
                                            <div className="flex py-3 cursor-pointer hover:bg-slate-800 rounded-xl">
                                                <div className="w-2/12 mr-2">
                                                    <img src={country.flag} alt="country-flag" className="w-8 mx-auto"></img>
                                                </div>
                                                <div className="w-10/12 truncate">
                                                    <span>{country.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                            {leagues.isSuccess && selectedCountry !== "All" &&
                                <div className="mt-4">
                                    {leagues.data.response.map(league => (
                                        <div
                                            className="border-b-2 border-gray-700"
                                            key={league.league.id}
                                            onClick={(e) => {
                                                setSelectedLeague({
                                                    id: league.league.id,
                                                    season: league.seasons.find(season => season.current === true).year
                                                });
                                                setSelectedDate(null);
                                            }}
                                        >
                                            <div
                                                className={`flex items-center py-3 cursor-pointer hover:bg-slate-800 rounded-xl ${selectedLeague?.id == league.league.id ? 'bg-slate-800' : ''}`}
                                            >
                                                <div className="w-2/12 mr-2">
                                                    <img src={league.league.logo} alt="country-flag" className="w-8 mx-auto"></img>
                                                </div>
                                                <div className="w-10/12 truncate">
                                                    <span>{league.league.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full lg:w-9/12">
                    <div className="px-0 my-2 md:px-2">
                        <div className="w-full text-white card bg-slate-900">
                            <div className="gap-0 card-body">
                                <div className="flex items-center justify-between lg:justify-around">
                                    <div
                                        className={`text-center border-b-2 border-blue-400 cursor-pointer ${!selectedDate ? 'border-blue-400' : "border-transparent"}`}
                                        onClick={() => setSelectedDate()}
                                    >
                                        LIVE
                                    </div>
                                    <div
                                        className={`text-center cursor-pointer hidden md:block border-b-2 pb-2 hover:border-blue-400 ${selectedDate && selectedDate?.isSame(today.clone().subtract(2, 'd'), 'day') ? 'border-blue-400' : "border-transparent"}`}
                                        onClick={(e) => setSelectedDate(today.clone().subtract(2, 'd'))}
                                    >
                                        {today.clone().subtract(2, 'd').format('dddd')}
                                        <br />
                                        {today.clone().subtract(2, 'd').format('MMM D')}
                                    </div>
                                    <div
                                        className={`text-center cursor-pointer pb-2 border-b-2 hover:border-blue-400 ${selectedDate && selectedDate?.isSame(today.clone().subtract(1, 'd'), 'day') ? 'border-blue-400' : "border-transparent"}`}
                                        onClick={(e) => setSelectedDate(today.clone().subtract(1, 'd'))}
                                    >
                                        {today.clone().subtract(1, 'days').format('dddd')}
                                        <br />
                                        {today.clone().subtract(1, 'days').format('MMM D')}
                                    </div>
                                    <div
                                        className={`text-center cursor-pointer pb-2 border-b-2 hover:border-blue-400 ${selectedDate && selectedDate?.isSame(today.clone(), 'day') ? 'border-blue-400' : "border-transparent"}`}
                                        onClick={(e) => setSelectedDate(today.clone())}
                                    >
                                        {today.clone().format('dddd')}
                                        <br />
                                        {today.clone().format('MMM D')}
                                    </div>
                                    <div
                                        className={`text-center cursor-pointer pb-2 border-b-2 hover:border-blue-400 ${selectedDate && selectedDate?.isSame(today.clone().add(1, 'd'), 'day') ? 'border-blue-400' : "border-transparent"}`}
                                        onClick={(e) => setSelectedDate(today.clone().add(1, 'd'))}
                                    >
                                        {today.clone().add(1, 'days').format('dddd')}
                                        <br />
                                        {today.clone().add(1, 'days').format('MMM D')}
                                    </div>
                                    <div
                                        className={`text-center cursor-pointer pb-2 hidden md:block border-b-2 hover:border-blue-400 ${selectedDate && selectedDate.isSame(today.clone().add(2, 'd'), 'day') ? 'border-blue-400' : "border-transparent"}`}
                                        onClick={(e) => setSelectedDate(today.clone().add(2, 'd'))}
                                    >
                                        {today.clone().add(2, 'days').format('dddd')}
                                        <br />
                                        {today.clone().add(2, 'days').format('MMM D')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-0 my-2 md:px-2">
                        <Fixtures matches={matches} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Matches;