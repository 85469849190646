import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// components
import FooterAdmin from "components/Footers/FooterAdmin.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
// views
import Jumbotron from "components/Headers/Jumbotron";
import AuthRequiredRoute from "components/Other/AuthRequiredRoute";
import Homepage from "views/Homepage";
import BugReport from "views/main/BugReport";
import Forum from "views/main/Forum";
import Forums from "views/main/Forums";
import Post from "views/main/Post";
import Profile from "views/main/Profile";
import Settings from "views/main/Settings.js";

export default function Main() {

    const location = useLocation();

    return (
        <>
            <div className="relative md:ml-64 bg-gradient-to-b from-slate-600 from-5% to-slate-800 to-50% min-h-screen">
                {location.pathname !== "/" &&
                    <>
                        <AdminNavbar layoutName={""} />
                        <Jumbotron />
                    </>
                }
                <div className="mx-auto -m-24 md:px-10">
                    <div className="min-h-[calc(100vh-218px)]">
                        <Routes>
                            <Route path="/" element={<Homepage />} />
                            <Route path="/settings" element={<AuthRequiredRoute><Settings /></AuthRequiredRoute>} />
                            <Route path="/profile" element={<AuthRequiredRoute><Profile /></AuthRequiredRoute>} />
                            <Route path="/forums" element={<Forums />} />
                            <Route path="/forums/:categoryId" element={<Forum />} />
                            <Route path="/forums/:categoryId/:postId" element={<Post />} />
                            <Route path="/reportabug" element={<BugReport />} />
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </div>
                    <FooterAdmin />
                </div>
            </div>
        </>
    );
}
