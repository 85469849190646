/*eslint-disable*/
import { UserContext } from "layouts/App";
import React from "react";
import { Link, NavLink } from "react-router-dom";

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");

  const user = React.useContext(UserContext);
  return (
    <>
      <nav className="relative z-10 flex flex-wrap items-center justify-between px-6 py-4 shadow-xl md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden bg-slate-900 md:w-64">
        <div className="flex flex-wrap items-center justify-between w-full px-0 mx-auto md:flex-col md:items-stretch md:min-h-full md:flex-nowrap">
          {/* Toggler */}
          <button
            className="px-3 py-1 text-xl leading-none text-white bg-transparent border border-transparent border-solid rounded cursor-pointer md:hidden"
            type="button"
            onClick={() => setCollapseShow("bg-slate-900 m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="inline-block p-4 px-0 mr-0 text-left text-white md:block md:pb-2 whitespace-nowrap"
            to="/"
          >
            <span className="text-xl font-bold">kicks</span>.<span className="text-sm">football</span>
          </Link>
          {/* User */}
          <ul className="flex flex-wrap items-center list-none md:hidden">
            <li className="relative inline-block">
              <NotificationDropdown />
            </li>
            <li className="relative inline-block">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="block pb-4 mb-4 border-b border-solid md:min-w-full md:hidden border-slate-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="inline-block p-4 px-0 mr-0 text-left text-white md:block md:pb-2 whitespace-nowrap"
                    to="/"
                  >
                    <span className="text-xl font-bold">kicks</span>.<span className="text-sm">football</span>
                  </Link>
                </div>
                <div className="flex justify-end w-6/12">
                  <button
                    type="button"
                    className="px-3 py-1 text-xl leading-none text-white bg-transparent border border-transparent border-solid rounded cursor-pointer md:hidden"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="pt-0 mb-3">
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full h-12 px-3 py-2 text-base font-normal leading-snug text-white bg-white border-0 border-solid rounded shadow-none outline-none border-slate-500 placeholder-slate-300 focus:outline-none"
                />
              </div>
            </form>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="block pt-1 pb-4 text-xs font-bold text-white no-underline uppercase md:min-w-full">
              ⚽ Football
            </h6>
            {/* Navigation */}

            <ul className="flex flex-col list-none md:flex-col md:min-w-full">
              <li className="items-center">
                <NavLink
                  onClick={() => setCollapseShow("hidden")}
                  to="/football/dashboard"
                  className={({ isActive, isPending }) =>
                    `text-xs uppercase py-3 font-bold block ${isActive ? "text-violet-500 hover:text-violet-600" : "text-white hover:text-sky-400"}`
                  }
                  children={({ isActive, isPending }) => (
                    <>
                      <i
                        className={`fas fa-tv mr-2 text-sm ${(isActive ? "opacity-75" : "text-slate-300")}`}
                      ></i>
                      {"Dashboard"}
                    </>
                  )}
                >
                </NavLink>
              </li>
              <li className="items-center">
                <NavLink
                  onClick={() => setCollapseShow("hidden")}
                  to="/football/matches"
                  className={({ isActive, isPending }) =>
                    `text-xs uppercase py-3 font-bold block ${isActive ? "text-violet-500 hover:text-violet-600" : "text-white hover:text-sky-400"}`
                  }
                  children={({ isActive, isPending }) => (
                    <>
                      <i
                        className={`fas fa-tv mr-2 text-sm ${(isActive ? "opacity-75" : "text-slate-300")}`}
                      ></i>
                      {"Matches"}
                    </>
                  )}
                >
                </NavLink>
              </li>
              <li className="items-center">
                <NavLink
                  onClick={() => setCollapseShow("hidden")}
                  to="/football/pools"
                  className={({ isActive, isPending }) =>
                    `text-xs uppercase py-3 font-bold block ${isActive ? "text-violet-500 hover:text-violet-600" : "text-white hover:text-sky-400"}`
                  }
                  children={({ isActive, isPending }) => (
                    <>
                      <i
                        className={`fas fa-tv mr-2 text-sm ${(isActive ? "opacity-75" : "text-slate-300")}`}
                      ></i>
                      {"Pools"}
                    </>
                  )}
                >
                </NavLink>
              </li>
            </ul>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="block pt-1 pb-4 text-xs font-bold text-white no-underline uppercase md:min-w-full">
              🏀 NBA
            </h6>
            {/* Navigation */}

            <ul className="flex flex-col list-none md:flex-col md:min-w-full">
              <li className="items-center">
                <span
                  className={"text-xs uppercase py-3 font-bold block text-white hover:text-sky-400"}
                >
                  <i className={"fas fa-tv mr-2 text-sm"}></i>{" "}
                  Coming Soon!
                </span>
              </li>
            </ul>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="block pt-1 pb-4 text-xs font-bold text-white no-underline uppercase md:min-w-full">
              🏈 NFL
            </h6>
            {/* Navigation */}

            <ul className="flex flex-col list-none md:flex-col md:min-w-full">
              <li className="items-center">
                <span
                  className={"text-xs uppercase py-3 font-bold block text-white hover:text-sky-400"}
                >
                  <i className={"fas fa-tv mr-2 text-sm"}></i>{" "}
                  Coming Soon!
                </span>
              </li>
            </ul>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="block pt-1 pb-4 text-xs font-bold text-white no-underline uppercase md:min-w-full">
              🏒 Hockey
            </h6>
            {/* Navigation */}

            <ul className="flex flex-col list-none md:flex-col md:min-w-full">
              <li className="items-center">
                <span
                  className={"text-xs uppercase py-3 font-bold block text-white hover:text-sky-400"}
                >
                  <i className={"fas fa-tv mr-2 text-sm"}></i>{" "}
                  Coming Soon!
                </span>
              </li>
            </ul>
            <hr className="my-4 md:min-w-full" />
            {user &&
              <>
                {/* Heading */}
                <h6 className="block pt-1 pb-4 text-xs font-bold text-white no-underline uppercase md:min-w-full">
                  👤 My Profile
                </h6>
                <ul>
                  <li className="items-center">
                    <NavLink
                      onClick={() => setCollapseShow("hidden")}
                      to="/settings"
                      className={({ isActive, isPending }) =>
                        `text-xs uppercase py-3 font-bold block ${isActive ? "text-violet-500 hover:text-violet-600" : "text-white hover:text-sky-400"}`
                      }
                      children={({ isActive, isPending }) => (
                        <>
                          <i
                            className={`fas fa-tv mr-2 text-sm ${(isActive ? "opacity-75" : "text-slate-300")}`}
                          ></i>
                          {"Settings"}
                        </>
                      )}
                    >
                    </NavLink>
                  </li>
                  <li className="items-center">
                    <NavLink
                      onClick={() => setCollapseShow("hidden")}
                      to="/profile"
                      className={({ isActive, isPending }) =>
                        `text-xs uppercase py-3 font-bold block ${isActive ? "text-violet-500 hover:text-violet-600" : "text-white hover:text-sky-400"}`
                      }
                      children={({ isActive, isPending }) => (
                        <>
                          <i
                            className={`fas fa-tv mr-2 text-sm ${(isActive ? "opacity-75" : "text-slate-300")}`}
                          ></i>
                          {"Profile"}
                        </>
                      )}
                    >
                    </NavLink>
                  </li>
                </ul>
                {/* Divider */}
                <hr className="my-4 md:min-w-full" />
              </>
            }
            {/* Heading */}
            <h6 className="block pt-1 pb-4 text-xs font-bold text-white no-underline uppercase md:min-w-full">
              🖊️ Collaberate
            </h6>
            <ul>
              <li className="items-center">
                <NavLink
                  onClick={() => setCollapseShow("hidden")}
                  to="/forums"
                  className={({ isActive, isPending }) =>
                    `text-xs uppercase py-3 font-bold block ${isActive ? "text-violet-500 hover:text-violet-600" : "text-white hover:text-sky-400"}`
                  }
                  children={({ isActive, isPending }) => (
                    <>
                      <i
                        className={`fas fa-tv mr-2 text-sm ${(isActive ? "opacity-75" : "text-slate-300")}`}
                      ></i>
                      {"Forums"}
                    </>
                  )}
                >
                </NavLink>
              </li>
            </ul>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="block pt-1 pb-4 text-xs font-bold text-white no-underline uppercase md:min-w-full">
              ⚙️ Support
            </h6>
            <ul>
              <li className="items-center">
                <NavLink
                  onClick={() => setCollapseShow("hidden")}
                  to="/reportabug"
                  className={({ isActive, isPending }) =>
                    `text-xs uppercase py-3 font-bold block ${isActive ? "text-violet-500 hover:text-violet-600" : "text-white hover:text-sky-400"}`
                  }
                  children={({ isActive, isPending }) => (
                    <>
                      <i
                        className={`fas fa-tv mr-2 text-sm ${(isActive ? "opacity-75" : "text-slate-300")}`}
                      ></i>
                      {"Report a Bug"}
                    </>
                  )}
                >
                </NavLink>
              </li>
            </ul>
            {!user &&
              <>
                {/* Divider */}
                <hr className="my-4 md:min-w-full" />
                {/* Heading */}
                <h6 className="block pt-1 pb-4 text-xs font-bold no-underline uppercase md:min-w-full text-slate-500">
                  Login or Register
                </h6>
                <ul className="flex flex-col list-none md:flex-col md:min-w-full md:mb-4">
                  <li className="items-center">
                    <NavLink
                      onClick={() => setCollapseShow("hidden")}
                      to="/auth/login"
                      className={({ isActive, isPending }) =>
                        `text-xs uppercase py-3 font-bold block ${isActive ? "text-violet-500 hover:text-violet-600" : "text-white hover:text-sky-400"}`
                      }
                      children={({ isActive, isPending }) => (
                        <>
                          <i
                            className={`fas fa-tv mr-2 text-sm ${(isActive ? "opacity-75" : "text-slate-300")}`}
                          ></i>
                          {"Login"}
                        </>
                      )}
                    >
                    </NavLink>
                  </li>

                  <li className="items-center">
                    <NavLink
                      onClick={() => setCollapseShow("hidden")}
                      to="/auth/register"
                      className={({ isActive, isPending }) =>
                        `text-xs uppercase py-3 font-bold block ${isActive ? "text-violet-500 hover:text-violet-600" : "text-white hover:text-sky-400"}`
                      }
                      children={({ isActive, isPending }) => (
                        <>
                          <i
                            className={`fas fa-tv mr-2 text-sm ${(isActive ? "opacity-75" : "text-slate-300")}`}
                          ></i>
                          {"Register"}
                        </>
                      )}
                    >
                    </NavLink>
                  </li>
                </ul>
                {/* Divider */}
                <hr className="my-4 md:min-w-full" />
              </>
            }
          </div>
        </div>
      </nav >
    </>
  );
}
