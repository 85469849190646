
import NotificationDropdown from "components/Dropdowns/NotificationDropdown";
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import { UserContext } from "layouts/App";
import { useContext } from "react";

export default function Navbar({ layoutName }) {

  const user = useContext(UserContext);
  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 z-10 flex items-center w-full p-4 bg-transparent md:flex-row md:flex-nowrap md:justify-start">
        <div className="flex flex-wrap items-center justify-between w-full px-4 mx-autp md:flex-nowrap md:px-10">
          {/* Brand */}
          <div
            className="hidden text-sm font-semibold text-white uppercase cursor-default lg:inline-block"
            onClick={(e) => e.preventDefault()}
          >
            {layoutName}
          </div>
          {/* Form */}
          <form className="flex-row flex-wrap items-center hidden w-9/12 pl-10 mr-3 md:flex">
            <div className="relative flex flex-wrap items-stretch w-full">
              <span className="absolute z-10 items-center justify-center w-8 h-full py-3 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-slate-300">
                <i className="fas fa-search"></i>
              </span>
              <input
                type="text"
                placeholder="Search for player, team, manager..."
                className="relative w-full px-3 py-3 pl-10 text-sm border-0 shadow outline-none placeholder-slate-300 text-slate-600 bg-slate-900 rounded-xl focus:outline-none focus:ring"
              />
            </div>
          </form>
          {user &&
            <>
              {/* Notifications */}
              <ul className="flex-col items-center hidden list-none md:flex-row md:flex">
                <NotificationDropdown />
              </ul>
            </>
          }
          {/* User */}
          <ul className="flex-col items-center hidden list-none md:flex-row md:flex">
            <UserDropdown />
          </ul>
        </div>
      </nav >
      {/* End Navbar */}
    </>
  );
}
