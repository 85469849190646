import { db } from "firebase-configs/config";
import { addDoc, collection, doc, query, updateDoc, where } from "firebase/firestore";
import { fetchFootballData } from "js/common";
import { UserContext } from "layouts/App";
import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CreateAPool() {
    const user = useContext(UserContext);

    const { leagueId } = useParams();


    const [SelectedLeague, setSelectedLeague] = useState(null);
    const [SearchLeague, setSearchLeague] = useState();

    const fixtureList = useRef();

    const league = useQuery(['league', SelectedLeague], () => fetchFootballData('leagues', `?id=${SelectedLeague}`), { enabled: !!SelectedLeague });
    const currentSeason = league?.data?.response?.[0]?.seasons?.find(season => season.current === true)?.year;
    const selectedLeagueFixtures = useQuery(['fixtures', SelectedLeague, currentSeason], () => fetchFootballData('fixtures', `?league=${SelectedLeague}&season=${currentSeason}`), { enabled: !!currentSeason })

    const [userPoolData, userPoolDataLoading, userPoolDataError, userPoolDataSnapshot] = useCollectionData(query(collection(db, "pools"), where("userId", '==', user.userFirebase.uid), where("leagueId", '==', SelectedLeague)))

    const setPoolPick = async (fixtureId, outcome) => {
        if (!user) return;
        try {
            if (userPoolDataSnapshot?.docs?.[0]?.id) {
                let picks = userPoolData?.[0].picks;
                let existingIndex = picks.findIndex(item => item.fixtureid === fixtureId);
                if (existingIndex !== -1) {
                    picks[existingIndex] = { fixtureid: fixtureId, outcome: outcome }
                } else {
                    picks.push({ fixtureid: fixtureId, outcome: outcome })
                }
                await updateDoc(doc(db, "pools", userPoolDataSnapshot?.docs?.[0]?.id), {
                    picks: picks,
                });
            } else {
                await addDoc(collection(db, "pools"), {
                    userId: user.userFirebase.uid,
                    picks: [{ fixtureid: fixtureId, outcome: outcome }],
                    leagueId: SelectedLeague,
                    season: league.data.response[0].seasons.find(season => season.current === true).year,
                });
            }
            toast.success("Pick saved!")
        } catch (err) {
            toast.error("Something went wrong saving your picks. Sorry about that.")
        }

    }
    return (
        <div className="relative text-white">
            <Helmet>
                <title>Create a Pool</title>
            </Helmet>
            <div className="flex">
                <span className="mx-3 text-3xl font-semibold">Create a Pool</span>
            </div>
            <div className="flex flex-col mt-2">
                <span className="mx-3 text-lg font-semibold">Popular Leagues</span>
                <div className="flex flex-wrap">
                    <div className="w-full p-3 lg:w-6/12 xl:w-4/12">
                        <div
                            className="relative flex flex-col break-words bg-slate-900 min-h-[100px] text-white rounded-xl xl:mb-0 shadow-lg hover:scale-105 cursor-pointer"
                            onClick={() => setSelectedLeague(39)}
                        >
                            <div className="flex-auto p-4">
                                <div className="flex flex-wrap items-center">
                                    <div className="relative flex-1 flex-grow w-full max-w-full pr-4">
                                        <h5 className="text-xs font-bold uppercase text-slate-400">
                                            England
                                        </h5>
                                        <span className="text-xl font-semibold">
                                            Premier League
                                        </span>
                                    </div>
                                    <div className="relative flex-initial w-auto pl-4">
                                        <div
                                            className="inline-flex items-center justify-center w-12 h-12 text-center text-white rounded-full shadow-lg"
                                        >
                                            <img src="https://media.api-sports.io/football/leagues/39.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full p-3 lg:w-6/12 xl:w-4/12">
                        <div
                            className="relative flex flex-col break-words bg-slate-900 min-h-[100px] text-white rounded-xl xl:mb-0 shadow-lg hover:scale-105 cursor-pointer"
                            onClick={() => setSelectedLeague(140)}
                        >
                            <div className="flex-auto p-4">
                                <div className="flex flex-wrap items-center">
                                    <div className="relative flex-1 flex-grow w-full max-w-full pr-4">
                                        <h5 className="text-xs font-bold uppercase text-slate-400">
                                            Spain
                                        </h5>
                                        <span className="text-xl font-semibold">
                                            La Liga
                                        </span>
                                    </div>
                                    <div className="relative flex-initial w-auto pl-4">
                                        <div
                                            className="inline-flex items-center justify-center w-12 h-12 text-center text-white rounded-full shadow-lg"
                                        >
                                            <img src="https://media.api-sports.io/football/leagues/140.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full p-3 lg:w-6/12 xl:w-4/12">
                        <div
                            className="relative flex flex-col break-words bg-slate-900 min-h-[100px] text-white rounded-xl xl:mb-0 shadow-lg hover:scale-105 cursor-pointer"
                            onClick={() => setSelectedLeague(218)}
                        >
                            <div className="flex-auto p-4">
                                <div className="flex flex-wrap items-center">
                                    <div className="relative flex-1 flex-grow w-full max-w-full pr-4">
                                        <h5 className="text-xs font-bold uppercase text-slate-400">
                                            Germany
                                        </h5>
                                        <span className="text-xl font-semibold">
                                            Bundesliga
                                        </span>
                                    </div>
                                    <div className="relative flex-initial w-auto pl-4">
                                        <div
                                            className="inline-flex items-center justify-center w-12 h-12 text-center text-white rounded-full shadow-lg"
                                        >
                                            <img src="https://media.api-sports.io/football/leagues/218.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full p-3 lg:w-6/12 xl:w-4/12">
                        <div
                            className="relative flex flex-col break-words bg-slate-900 min-h-[100px] text-white rounded-xl xl:mb-0 shadow-lg hover:scale-105 cursor-pointer"
                            onClick={() => setSelectedLeague(61)}
                        >
                            <div className="flex-auto p-4">
                                <div className="flex flex-wrap items-center">
                                    <div className="relative flex-1 flex-grow w-full max-w-full pr-4">
                                        <h5 className="text-xs font-bold uppercase text-slate-400">
                                            France
                                        </h5>
                                        <span className="text-xl font-semibold">
                                            Ligue 1
                                        </span>
                                    </div>
                                    <div className="relative flex-initial w-auto pl-4">
                                        <div
                                            className="inline-flex items-center justify-center w-12 h-12 text-center text-white rounded-full shadow-lg"
                                        >
                                            <img src="https://media.api-sports.io/football/leagues/61.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full p-3 lg:w-6/12 xl:w-4/12">
                        <div
                            className="relative flex flex-col break-words bg-slate-900 min-h-[100px] text-white rounded-xl xl:mb-0 shadow-lg hover:scale-105 cursor-pointer"
                            onClick={() => setSelectedLeague(253)}
                        >
                            <div className="flex-auto w-full p-4">
                                <div className="flex flex-wrap items-center">
                                    <div className="relative flex-1 flex-grow w-full max-w-full pr-4">
                                        <h5 className="text-xs font-bold uppercase text-slate-400">
                                            United States & Canada
                                        </h5>
                                        <span className="text-xl font-semibold">
                                            Major League Soccer
                                        </span>
                                    </div>
                                    <div className="relative flex-initial w-auto pl-4">
                                        <div
                                            className="inline-flex items-center justify-center w-12 h-12 text-center text-white rounded-full shadow-lg"
                                        >
                                            <img src="https://media.api-sports.io/football/leagues/253.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full p-3 lg:w-6/12 xl:w-4/12">
                        <div
                            className="relative flex flex-col break-words bg-slate-900 min-h-[100px] text-white rounded-xl xl:mb-0 shadow-lg hover:scale-105 cursor-pointer"
                            onClick={() => setSelectedLeague(2)}
                        >
                            <div className="flex-auto w-full p-4">
                                <div className="flex flex-wrap items-center">
                                    <div className="relative flex-1 flex-grow w-full max-w-full pr-4">
                                        <h5 className="text-xs font-bold uppercase text-slate-400">
                                            Europe
                                        </h5>
                                        <span className="text-xl font-semibold">
                                            UEFA Champions League
                                        </span>
                                    </div>
                                    <div className="relative flex-initial w-auto pl-4">
                                        <div
                                            className="inline-flex items-center justify-center w-12 h-12 text-center text-white rounded-full shadow-lg"
                                        >
                                            <img src="https://media.api-sports.io/football/leagues/2.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full mt-10 card bg-slate-900">
                <div className="gap-0 card-body">
                    <h2 className="text-white card-title">Pool Matches</h2>
                    <small><p className="mb-4 text-white">Select a winner or draw for each match</p></small>
                    <div className="flex flex-col px-5 md:px-0">
                        {selectedLeagueFixtures.isSuccess && selectedLeagueFixtures.data.response.length > 0 && !userPoolDataLoading &&
                            <div className="w-full md:px-8" ref={fixtureList}>
                                {league.isSuccess && league.data.response.length > 0 &&
                                    <div className="flex flex-row items-center justify-between mb-10">
                                        <div>
                                            <img src={league.data.response[0].league.logo} className="inline w-8 mr-2 md:w-12"></img>
                                            <span className="text-sm font-semibold md:text-3xl">{league.data.response[0].league.name}</span>
                                        </div>
                                        {userPoolData?.[0]?.picks.length > 0 &&
                                            <span className="text-sm font-semibold md:text-3xl">{userPoolData?.[0]?.picks.length} / {selectedLeagueFixtures.data.response.length}</span>
                                        }
                                    </div>
                                }
                                {selectedLeagueFixtures.data.response.map(fixture => {
                                    let userOutcome = userPoolData?.[0]?.picks?.find(item => item.fixtureid === fixture.fixture.id)?.outcome;
                                    return (
                                        <div
                                            className="relative py-2"
                                            key={fixture.fixture.id}
                                            data-date={fixture.fixture.date}
                                        >
                                            <div className="flex items-center justify-center mb-2">
                                                {moment(fixture.fixture.date).format('LLL')}
                                            </div>
                                            <div className="flex flex-row items-end justify-end w-full pb-5 text-center border-b border-slate-400 ">
                                                <div className="flex flex-col w-1/3">
                                                    <div className="mb-2">
                                                        <img src={fixture.teams.home.logo} className="inline w-6 mr-2"></img>
                                                        <span className="text-sm md:text-lg">{fixture.teams.home.name}</span>
                                                    </div>
                                                    <span>
                                                        <button
                                                            className={`h-12 w-full 
                                                    ${fixture.teams.home.winner === true && fixture.fixture.status.long === "Match Finished" && userOutcome !== fixture.teams.home.id ? "bg-gray-500" : ""}
                                                    ${userOutcome === fixture.teams.home.id && fixture.fixture.status.long === "Match Finished" ? fixture.teams.home.winner === true ? "bg-green-500" : "bg-red-500" : ""} 
                                                    rounded-none border hover:bg-white hover:text-black`.replace(/\s+/g, ' ').trim()}
                                                            onClick={
                                                                fixture.fixture.status.long !== 'Match Finished' ?
                                                                    () => setPoolPick(fixture.fixture.id, fixture.teams.home.id)
                                                                    :
                                                                    () => toast.info("This match is Finished! You can't select it anymore silly billy!")
                                                            }
                                                        >
                                                            <div className="flex items-center justify-center">
                                                                Win
                                                                {userOutcome === fixture.teams.home.id &&
                                                                    <span className="ml-2 text-black bg-white border-none badge">
                                                                        {fixture.teams.home.winner === true && fixture.fixture.status.long === "Match Finished" ?
                                                                            3
                                                                            :
                                                                            0
                                                                        }
                                                                    </span>
                                                                }
                                                            </div>
                                                        </button>
                                                    </span>
                                                </div>
                                                <div className="w-1/3">
                                                    <div className="mb-2 text-sm md:text-lg">&nbsp;</div>
                                                    <span>
                                                        <button
                                                            className={`h-12 w-full 
                                                    ${fixture.teams.away.winner === null && fixture.teams.home.winner === null === true && fixture.fixture.status.long === "Match Finished" && userOutcome !== 'draw' ? "bg-gray-500" : ""}
                                                    ${userOutcome === 'draw' && fixture.fixture.status.long === "Match Finished" ? fixture.teams.away.winner === null && fixture.teams.home.winner === null && fixture.fixture.status.long === "Match Finished" ? "bg-green-500" : "bg-red-500" : ""} 
                                                    rounded-none border hover:bg-white hover:text-black`.replace(/\s+/g, ' ').trim()}
                                                            onClick={
                                                                fixture.fixture.status.long !== 'Match Finished' ?
                                                                    () => setPoolPick(fixture.fixture.id, 'draw')
                                                                    :
                                                                    () => toast.info("This match is Finished! You can't select it anymore silly billy!")
                                                            }
                                                        >
                                                            <div className="flex items-center justify-center">
                                                                Draw
                                                                {userOutcome === 'draw' &&
                                                                    <span className="ml-2 text-black bg-white border-none badge">
                                                                        {fixture.teams.away.winner === null && fixture.teams.home.winner === null && fixture.fixture.status.long === "Match Finished" ?
                                                                            3
                                                                            :
                                                                            0
                                                                        }
                                                                    </span>
                                                                }
                                                            </div>
                                                        </button>
                                                    </span>
                                                </div>
                                                <div className="flex flex-col w-1/3">
                                                    <div className="mb-2">
                                                        <img src={fixture.teams.away.logo} className="inline w-6 mr-2"></img>
                                                        <span className="text-sm md:text-lg">{fixture.teams.away.name}</span>
                                                    </div>
                                                    <span>
                                                        <button
                                                            className={`h-12 w-full
                                                    ${fixture.teams.away.winner === true && fixture.fixture.status.long === "Match Finished" && userOutcome !== fixture.teams.away.id ? "bg-gray-500" : ""}
                                                    ${userOutcome === fixture.teams.away.id && fixture.fixture.status.long === "Match Finished" ? fixture.teams.away.winner === true ? "bg-green-500" : "bg-red-500" : ""} 
                                                    rounded-none border hover:bg-white hover:text-black`.replace(/\s+/g, ' ').trim()}
                                                            onClick={
                                                                fixture.fixture.status.long !== 'Match Finished' ?
                                                                    () => setPoolPick(fixture.fixture.id, fixture.teams.away.id)
                                                                    :
                                                                    () => toast.info("This match is Finished! You can't select it anymore silly billy!")
                                                            }
                                                        >
                                                            <div className="flex items-center justify-center">
                                                                Win
                                                                {userOutcome === fixture.teams.away.id &&
                                                                    <span className="ml-2 text-black bg-white border-none badge">
                                                                        {fixture.teams.away.winner === true && fixture.fixture.status.long === "Match Finished" ?
                                                                            3
                                                                            :
                                                                            0
                                                                        }
                                                                    </span>
                                                                }
                                                            </div>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                            {/* <div className="absolute w-4 h-4 rounded-full -top-4 -left-2 bg-slate-400">
                                </div> */}
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}