import moment from "moment";
import { Link } from "react-router-dom";

export default function FixtureMiniCard({ fixture }) {
    return (
        <>
            <Link to={`./match/${fixture.fixture.id}`}>
                <div className="relative flex flex-col min-w-0 mb-6 text-white break-words shadow-lg cursor-pointer bg-slate-900 rounded-xl 2xl:mb-0 hover:scale-105 h-28 w-72">
                    <div className="flex flex-row mt-3 ml-3 text-xs">
                        <img className="w-4 h-4 mr-2" src={fixture.league.logo}></img>
                        <span>{fixture.league.name}</span>
                    </div>
                    <div className="flex flex-row h-full px-3 text-sm">
                        <div className="w-4/12 my-auto">
                            <div className="text-sm text-center">
                                <span className={
                                    {
                                        'Match Cancelled': "text-red-400",
                                        'Match Postponed': "text-orange-400"
                                    }[fixture.fixture.status.long]
                                }>
                                    {fixture.fixture.status.long !== "Match Finished" && fixture.fixture.status.elapsed !== null &&
                                        fixture.fixture.status.long !== "Halftime" ?
                                        <>
                                            {fixture.fixture.status.elapsed}'
                                            <img src={require("assets/img/svg/live-game.svg").default} className="w-8 mx-auto" />
                                        </>
                                        :
                                        <>
                                            <span>{moment(fixture.fixture.date).format('LL')}</span>
                                            <br />
                                            {fixture.fixture.status.long}
                                        </>
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col w-7/12 px-2 my-auto">
                            <div className="flex items-center my-1">
                                <img className="w-4 h-4 mr-1" src={fixture.teams.home.logo} />
                                {fixture.teams.home.name}
                            </div>
                            <div className="flex items-center my-1">
                                <img className="w-4 h-4 mr-1" src={fixture.teams.away.logo} />
                                {fixture.teams.away.name}
                            </div>
                        </div>
                        <div className="flex flex-col justify-around w-1/12 px-2">
                            <div className="flex items-center">
                                {fixture.goals.home}
                            </div>
                            <div className="flex items-center">
                                {fixture.goals.away}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </>
    );
}
