import PieChart from "components/Charts/PieChart";
import StackedBarChart from "components/Charts/StackedBarChart";
import { fetchFootballData } from "js/common";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import colors from "tailwindcss/colors";

const PlayerStats = ({ player, seasons }) => {
    const [selectedLeague, setSelectedLeague] = useState();
    const [selectedTeam, setSelectedTeam] = useState();
    const [selectedSeason, setSelectedSeason] = useState();

    const playerStats = useQuery(['player', player.data?.response[0]?.player.id, selectedSeason], () => fetchFootballData('players', `?id=${player.data.response[0].player.id}&season=${selectedSeason}`), {
        enabled: player.isSuccess && seasons.isSuccess && player.data.response.length > 0 && seasons.data.response.length > 0 && !!selectedSeason
    });

    useEffect(() => {
        if (player.isSuccess && seasons.isSuccess &&
            player.data.response.length > 0 && player.data.response[0].statistics.length > 0 && seasons.data.response.length > 0) {
            setSelectedTeam(player.data.response[0].statistics[0].team.id);
            setSelectedLeague(player.data.response[0].statistics[0].league.id);
            setSelectedSeason(seasons.data.response.slice().reverse()[0]);
        }
    }, []);

    useEffect(() => {
        setSelectedTeam(undefined);
        setSelectedLeague(undefined);
    }, [selectedSeason]);

    useEffect(() => {
        if (playerStats.isSuccess) {
            setSelectedTeam(playerStats.data.response[0].statistics[0].team.id);
            setSelectedLeague(playerStats.data.response[0].statistics[0].league.id);
        }
    }, [playerStats.status])
    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="card-body">
                <h2 className="text-white card-title">Statistics</h2>
                {playerStats.isSuccess && seasons.isSuccess &&
                    <>
                        {playerStats.data.response.length > 0 && playerStats.data.response[0].statistics.length > 0 && seasons.data.response.length > 0 &&
                            <>
                                <div className="flex flex-col md:flex-row">
                                    <select
                                        className="w-full max-w-xs select select-bordered"
                                        value={selectedSeason}
                                        onChange={(e) => setSelectedSeason(e.target.value)}
                                    >
                                        {seasons.data.response.slice().reverse().map((season) => (
                                            <option value={season} key={season}>{season}</option>
                                        ))}
                                    </select>
                                    <select
                                        className="w-full max-w-xs mt-4 md:mt-0 md:ml-4 select select-bordered"
                                        value={selectedLeague}
                                        onChange={(e) => {
                                            setSelectedLeague(e.target.value);
                                            setSelectedTeam(e.target[e.target.selectedIndex].getAttribute("data-teamid"));
                                        }}
                                    >
                                        {player.data.response[0].statistics.map((stat) => (
                                            <option
                                                key={stat.league.id}
                                                value={stat.league.id}
                                                data-teamid={stat.team.id}
                                            >
                                                {stat.league.name} - {stat.team.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    {selectedLeague && selectedTeam &&
                                        <div className="flex flex-wrap">
                                            <div className="w-full px-4 md:w-6/12">
                                                <PieChart
                                                    label={player.data.response[0].player.id}
                                                    data={Object.entries(playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).cards)?.map((item) => ({ label: item[0].toLowerCase().replace(/\b\w/g, s => s.toUpperCase()), value: item[1] })) || []}
                                                    id={'playerCards'}
                                                    heading="Cards"
                                                />
                                            </div>
                                            <div className="w-full px-4 md:w-6/12">
                                                <PieChart
                                                    label={player.data.response[0].player.id}
                                                    data={Object.entries(playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).fouls)?.map((item) => ({ label: item[0].toLowerCase().replace(/\b\w/g, s => s.toUpperCase()), value: item[1] })) || []}
                                                    id={'playerFouls'}
                                                    heading="Fouls"
                                                />
                                            </div>
                                            <div className="w-full px-4 md:w-6/12">
                                                <PieChart
                                                    label={player.data.response[0].player.id}
                                                    data={Object.entries(playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).penalty)?.map((item) => ({ label: item[0].toLowerCase().replace(/\b\w/g, s => s.toUpperCase()), value: item[1] })) || []}
                                                    id={'playerPenalties'}
                                                    heading="Penalties"
                                                />
                                            </div>
                                            <div className="w-full px-4 md:w-6/12">
                                                <PieChart
                                                    label={player.data.response[0].player.id}
                                                    data={Object.entries(playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).goals)?.map((item) => ({ label: item[0].toLowerCase().replace(/\b\w/g, s => s.toUpperCase()), value: item[1] })) || []}
                                                    id={'playerGoals'}
                                                    heading="Goals"
                                                />
                                            </div>
                                            <div className="w-full mt-10">
                                                <StackedBarChart
                                                    labels={['Dribbles', 'Passes', 'Tackles', 'Duels', 'Shots']}
                                                    id={'playerStats'}
                                                    heading="Player Statistics"
                                                    data={
                                                        [
                                                            {
                                                                label: 'Success',
                                                                data: [
                                                                    playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).dribbles.success || 0,
                                                                    0,
                                                                    0,
                                                                    0,
                                                                    0
                                                                ],
                                                                backgroundColor: colors.red[400],
                                                            },
                                                            {
                                                                label: 'Other',
                                                                data: [
                                                                    playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).dribbles.total - (playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).dribbles.success + playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).dribbles.past) || 0,
                                                                    0,
                                                                    playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).tackles.total - (playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).tackles.blocks || 0 + playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).tackles.interceptions || 0) || 0,
                                                                    playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).duels.total - playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).duels.won || 0,
                                                                    0
                                                                ],
                                                                backgroundColor: colors.blue[500],
                                                            },
                                                            {
                                                                label: 'Normal',
                                                                data: [
                                                                    0,
                                                                    playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).passes.total - playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).passes.key || 0,
                                                                    0,
                                                                    0,
                                                                    0
                                                                ],
                                                                backgroundColor: colors.purple[500],
                                                            },
                                                            {
                                                                label: 'Past',
                                                                data: [
                                                                    playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).dribbles.past || 0,
                                                                    0,
                                                                    0,
                                                                    0,
                                                                    0
                                                                ],
                                                                backgroundColor: colors.cyan[500],
                                                            },
                                                            {
                                                                label: 'Key',
                                                                data: [
                                                                    0,
                                                                    playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).passes.key || 0,
                                                                    0,
                                                                    0,
                                                                    0
                                                                ],
                                                                backgroundColor: colors.yellow[500],
                                                            },
                                                            {
                                                                label: 'On Target',
                                                                data: [
                                                                    0,
                                                                    0,
                                                                    0,
                                                                    0,
                                                                    playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).shots.on || 0
                                                                ],
                                                                backgroundColor: colors.pink[500],
                                                            },
                                                            {
                                                                label: 'Off Target',
                                                                data: [
                                                                    0,
                                                                    0,
                                                                    0,
                                                                    0,
                                                                    playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).shots.total - playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).shots.on || 0
                                                                ],
                                                                backgroundColor: colors.green[500],
                                                            },
                                                            {
                                                                label: 'Interceptions',
                                                                data: [
                                                                    0,
                                                                    0,
                                                                    playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).tackles.interceptions || 0,
                                                                    0,
                                                                    0
                                                                ],
                                                                backgroundColor: colors.violet[500],
                                                            },
                                                            {
                                                                label: 'Blocks',
                                                                data: [
                                                                    0,
                                                                    0,
                                                                    playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).tackles.block || 0,
                                                                    0,
                                                                    0
                                                                ],
                                                                backgroundColor: colors.orange[700],
                                                            },
                                                            {
                                                                label: 'Won',
                                                                data: [
                                                                    0,
                                                                    0,
                                                                    0,
                                                                    playerStats.data.response[0].statistics.find(stat => stat.league.id === selectedLeague && stat.team.id === selectedTeam).duels.won || 0,
                                                                    0
                                                                ],
                                                                backgroundColor: colors.teal[800],
                                                            },
                                                        ]
                                                    }
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {playerStats.data.response.length === 0 &&
                            <div className="flex items-center justify-center min-h-[100px]">
                                <span className="text-sm text-gray-400">No Data Available</span>
                            </div>
                        }
                    </>
                }
                {player.isLoading &&
                    <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div >
    );
}

export default PlayerStats;