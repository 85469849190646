import { useFirestoreQuery } from '@react-query-firebase/firestore';
import FootballDashboardLeague from 'components/Cards/Football/FootballDashboardLeague';
import Injuries from 'components/Cards/Football/Injuries';
import Standings from 'components/Cards/Football/Standings';
import TopStats from 'components/Cards/Football/TopStats';
import RecentChatMessages from 'components/Cards/RecentChatMessages';
import RecentForumPosts from 'components/Cards/RecentForumPosts';
import RecentForumPostsComments from 'components/Cards/RecentForumPostsComments';
import FootBallDashboardTour from 'components/Tours/FootballDashboardTour';
import { db } from 'firebase-configs/config';
import { collection, limit, orderBy, query, where } from 'firebase/firestore';
import { fetchFootballData } from 'js/common';
import { UserContext } from "layouts/App";
import moment from 'moment';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import Fixtures from '../../components/Cards/Football/Fixtures';

export default function FootballDashboard() {

    const user = useContext(UserContext);

    //get current season for league
    const userLeague = user?.userFirestore?.dashboardLeague || 39;
    const league = useQuery(["league", userLeague], () => fetchFootballData('leagues', `?current=true&id=${userLeague}`));
    const currentSeason = league?.data?.response?.[0]?.seasons?.[0]?.year;

    //get dashboard data
    const matches = useQuery(["fixtures", userLeague, currentSeason], () => fetchFootballData('fixtures',
        `?league=${userLeague}&season=${currentSeason}&from=${moment().subtract(7, 'days').format('YYYY-MM-DD')}&to=${moment().add(7, 'days').format('YYYY-MM-DD')}`
    ), { enabled: !!currentSeason });
    const standings = useQuery(["standings", userLeague, currentSeason], () => fetchFootballData('standings', `?league=${userLeague}&season=${currentSeason}`), { enabled: !!currentSeason });
    const topScorers = useQuery(['topScorers', userLeague, currentSeason], () => fetchFootballData('players/topscorers', `?league=${userLeague}&season=${currentSeason}`), { enabled: !!currentSeason });
    const topAssists = useQuery(['topAssists', userLeague, currentSeason], () => fetchFootballData('players/topassists', `?league=${userLeague}&season=${currentSeason}`), { enabled: !!currentSeason });
    const topYellowCards = useQuery(['topYellowCards', userLeague, currentSeason], () => fetchFootballData('players/topyellowcards', `?league=${userLeague}&season=${currentSeason}`), { enabled: !!currentSeason });
    const topRedCards = useQuery(['topRedCards', userLeague, currentSeason], () => fetchFootballData('players/topredcards', `?league=${userLeague}&season=${currentSeason}`), { enabled: !!currentSeason });
    const injuries = useQuery(['injuries', userLeague, currentSeason], () => fetchFootballData('injuries', `?league=${userLeague}&season=${currentSeason}`), { enabled: !!currentSeason });

    const leagues = useQuery("leagues", () => fetchFootballData('leagues'), {
        staleTime: Infinity,
        cacheTime: 150 * (60 * 1000),
        enabled: false
    });

    //get dashboard user data
    const recentForumPosts = useFirestoreQuery(["recent_forum_posts", user?.userFirebase?.uid], user?.userFirebase?.uid && query(collection(db, "forum_posts"), where("userId", '==', user?.userFirebase?.uid), orderBy('createdOn', 'desc'), limit(5)), { subscribe: true }, { enabled: !!user?.userFirebase?.uid });
    const recentForumPostsComments = useFirestoreQuery(["recent_forum_posts_comments", user?.userFirebase?.uid], user?.userFirebase?.uid && query(collection(db, "forum_posts_comments"), where("userId", '==', user?.userFirebase?.uid), orderBy('createdOn', 'desc'), limit(5)), { subscribe: true }, { enabled: !!user?.userFirebase?.uid });
    const recentChatMessages = useFirestoreQuery(["recent_chat_messages", user?.userFirebase?.uid], user?.userFirebase?.uid && query(collection(db, "chat_message"), where("userId", '==', user?.userFirebase?.uid), orderBy('createdOn', 'desc'), limit(5)), { subscribe: true }, { enabled: !!user?.userFirebase?.uid });

    const getGreetingTime = () => {
        let currentTime = moment();
        if (parseFloat(currentTime.format('HH')) >= 12 && parseFloat(currentTime.format('HH')) <= 17) {
            return 'Good afternoon';
        } else if (parseFloat(currentTime.format('HH')) >= 17) {
            return 'Good evening';
        }
        return 'Good morning';
    }

    return (
        <>
            {!localStorage.getItem("footballDashboardTourComplete") &&
                <FootBallDashboardTour />
            }
            <Helmet>
                <title>Football Dashboard</title>
            </Helmet>
            {user &&
                <div className='relative flex items-center justify-center w-full px-4 -mt-10'>
                    <span className='text-lg font-semibold text-center text-white md:text-2xl'>{getGreetingTime()}, {user.userFirebase.displayName}!</span>
                </div>
            }

            <FootballDashboardLeague league={league} leagues={leagues} />

            <div className="flex flex-wrap px-4 mt-4">

                <div className="w-full px-0 my-2 2xl:w-6/12 md:px-2 upcoming-fixtures">
                    <Fixtures matches={matches} />
                </div>

                <div className="w-full px-0 my-2 2xl:w-6/12 md:px-2 standings">
                    <Standings standings={standings} />
                </div>

                <div className="w-full px-0 my-2 md:px-2 top-stats">
                    <TopStats topScorers={topScorers} topAssists={topAssists} topYellowCards={topYellowCards} topRedCards={topRedCards} />
                </div>

                <div className="w-full px-0 my-2 md:px-2">
                    <Injuries injuries={injuries} />
                </div>

                {user &&
                    <div className="w-full px-0 my-2 lg:w-4/12 md:px-2 recent-posts">
                        <RecentForumPosts recentForumPosts={recentForumPosts} />
                    </div>
                }

                {user &&
                    <div className="w-full px-0 my-2 lg:w-4/12 md:px-2">
                        <RecentForumPostsComments recentForumPostsComments={recentForumPostsComments} />
                    </div>
                }

                {user &&
                    <div className="w-full px-0 my-2 lg:w-4/12 md:px-2">
                        <RecentChatMessages recentChatMessages={recentChatMessages} />
                    </div>
                }
            </div>
        </>
    );
}
