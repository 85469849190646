import { confetti } from 'js/common';
import Joyride, { EVENTS, STATUS } from 'react-joyride';
import colors from 'tailwindcss/colors';

const FootBallDashboardTour = () => {
    return (
        <Joyride
            showProgress={true}
            continuous={true}
            showSkipButton={true}
            scrollOffset={200}
            callback={(data) => {
                if (data.type === EVENTS.TOUR_END && data.status === STATUS.FINISHED) {
                    confetti.addConfetti({
                        emojis: ['😀', '⚽', '👍', '😍'],
                    });
                    localStorage.setItem("footballDashboardTourComplete", true);
                }
                if (data.type === EVENTS.TOUR_END && data.status === STATUS.SKIPPED) {
                    confetti.addConfetti({
                        emojis: ['😢'],
                        confettiNumber: 2,
                    });
                    localStorage.setItem("footballDashboardTourComplete", true);
                }
            }}
            steps={[
                {
                    target: "html",
                    title: "Welcome to your football Dashboard!",
                    content: `Here you will find a breakdown of everything you need to get a quick glance of whats going on. You can click on any item and navigate to it to get more information.`,
                    disableBeacon: true,
                    floaterProps: {
                        hideArrow: true
                    },
                    placement: "center"
                },
                {
                    target: ".upcoming-fixtures",
                    content: `Find recent and upcoming fixtures for your chosen Dashboard League`,
                    position: 'top',
                },
                {
                    target: ".standings",
                    content: `View Standings for your chosen Dashboard League`,
                    position: 'top'
                },
                {
                    target: ".top-stats",
                    content: `View Top Scorers, Assists and Cards for your chosen Dashboard League`,
                    position: 'top'
                },
                {
                    target: ".recent-posts",
                    content: `View your recent posts from live chats and forums`,
                    position: 'top'
                },
                {
                    target: ".change-dashboard-league-btn",
                    content: `Change your Dashboard League at any time here`,
                    position: 'bottom'
                },
            ]}
            styles={{
                options: {
                    arrowColor: '#e3ffeb',
                    backgroundColor: colors.slate[900],
                    overlayColor: '',
                    primaryColor: colors.violet[500],
                    textColor: 'white',
                    zIndex: 1000,
                },
                beaconOuter: {
                    display: "none"
                },
            }}
        />
    );
}

export default FootBallDashboardTour;