import { createContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import 'react-loading-skeleton/dist/skeleton.css';

import { useAuthUser } from "@react-query-firebase/auth";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import WebsiteNotifications from "components/Headers/WebsiteNotifications";
import BottomNavigation from "components/Navbars/BottomNavigation";
import ScrollToTop from "components/Other/ScrollToTop";
import Sidebar from "components/Sidebar/Sidebar";
import { auth, db } from "firebase-configs/config";
import { doc } from "firebase/firestore";
import { ToastContainer } from "react-toastify";
import Auth from "./Auth";
import Football from "./Football";
import Main from "./Main";



export const UserContext = createContext(undefined);

export default function App() {

    const userFirebase = useAuthUser(['user'], auth);
    const userFirestore = useFirestoreDocumentData(['user_data', userFirebase?.data?.uid], userFirebase?.data?.uid && doc(db, "users", userFirebase?.data?.uid), { subscribe: true }, { enabled: !!userFirebase?.data?.uid });

    return (
        <>
            {((userFirebase.isSuccess && userFirestore.isSuccess) || (userFirebase.isSuccess && !userFirebase.data)) &&
                <UserContext.Provider value={userFirebase.data ? { userFirestore: userFirestore.data, userFirebase: userFirebase.data } : null}>
                    <ToastContainer theme="dark" />
                    <BrowserRouter>
                        <ScrollToTop />
                        <Sidebar />
                        <WebsiteNotifications />
                        <Routes>
                            {/* add routes with layouts */}
                            <Route path="*" element={<Main />} />
                            <Route path="/football/*" element={<Football />} />
                            <Route path="/auth/*" element={<Auth />} />
                        </Routes>
                        <BottomNavigation />
                    </BrowserRouter>
                </UserContext.Provider>
            }
        </>
    );
}
