import moment from "moment";
import { Link } from "react-router-dom";

const Fixture = ({ fixture, extraSmall }) => {

    const isLive = () => {
        if (fixture.fixture.status.long !== "Match Finished" && fixture.fixture.status.elapsed === null) {
            return true;
        }
        return false;
    }
    return (
        <Link to={`/football/match/${fixture.fixture.id}`}>
            <div className="relative border-b-2 border-gray-700 group">
                <div className="absolute flex-col items-center hidden w-10/12 h-auto px-2 py-2 text-white -translate-x-1/2 border border-white rounded-lg left-1/2 group-hover:flex bottom-20 bg-slate-800">
                    <div className="flex items-center text-lg font-semibold text-center">
                        <img className="inline w-5 h-5 mr-1" src={fixture.teams.home.logo}></img>
                        {fixture.teams.home.name} vs {fixture.teams.away.name}
                        <img className="inline w-5 h-5 ml-1" src={fixture.teams.away.logo}></img>
                    </div>
                    <span className="text-center">{fixture.fixture.venue.name}, {fixture.fixture.venue.city}</span>
                    <div className="flex items-center"><img className="inline w-5 h-5 mr-1" src={fixture.league.logo}></img>{fixture.league.name}</div>
                    <div className="flex items-center"><img className="inline w-5 h-5 mr-1" src={fixture.league.flag}></img>{fixture.league.country}</div>
                    <div>{fixture.league.round} | {fixture.league.season}</div>
                    <div
                        className="absolute bottom-0 w-4 h-4 transform rotate-45 -translate-x-1/2 translate-y-1/2 border-b border-r border-white left-1/2 bg-slate-800"
                    ></div>
                </div>
                <div className="py-1 text-sm text-white cursor-pointer hover:bg-slate-800 xl:text-base rounded-xl">
                    <div className="flex flex-row items-center">
                        <div className={`w-5/12 text-xs text-center md:w-4/12 xl:text-base ${extraSmall && "xl:text-sm"}`}>
                            <span className={
                                {
                                    'Match Cancelled': "text-red-400",
                                    'Match Postponed': "text-orange-400"
                                }[fixture.fixture.status.long]
                            }>
                                {fixture.fixture.status.long !== "Match Finished" && fixture.fixture.status.elapsed !== null ?
                                    fixture.fixture.status.long !== "Halftime" ?
                                        <>
                                            {fixture.fixture.status.elapsed}'
                                            <img src={require("assets/img/svg/live-game.svg").default} className="w-8 mx-auto" />
                                        </>
                                        :
                                        <>
                                            {fixture.fixture.status.long}
                                            <img src={require("assets/img/svg/live-game.svg").default} className="w-8 mx-auto" />
                                        </>
                                    :
                                    <>
                                        <div>{moment(fixture.fixture.date).format('LL')}</div>
                                        {fixture.fixture.status.long}
                                    </>
                                }
                            </span>
                        </div>
                        <div className="w-8/12">
                            <div className="flex items-center">
                                <div className="w-1/12 mr-2">
                                    <img src={fixture.teams.home.logo} className="w-6" loading="lazy"></img>
                                </div>
                                <div className="w-10/12">
                                    {fixture.teams.home.name}
                                </div>
                                <div className="w-1/12">
                                    {fixture.goals.home}
                                </div>
                            </div>
                            <div className="flex items-center mt-2">
                                <div className="w-1/12 mr-2">
                                    <img src={fixture.teams.away.logo} className="w-6" loading="lazy"></img>
                                </div>
                                <div className="w-10/12">
                                    {fixture.teams.away.name}
                                </div>
                                <div className="w-1/12">
                                    {fixture.goals.away}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default Fixture;