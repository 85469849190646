import Skeleton from "react-loading-skeleton";

const PlayerTransfers = ({ transfers }) => {
    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="card-body">
                <h2 className="text-white card-title">Transfers</h2>
                {transfers.isSuccess &&
                    <>
                        {transfers.data.response.length > 0 &&
                            <>
                                {transfers.data.response[0].transfers.map(transfer => (
                                    <div className="border-b-2 border-slate-800" key={transfer.date}>
                                        <div className="flex flex-row py-2">
                                            <div className="flex flex-row items-center w-4/12">
                                                <img src={transfer.teams.out.logo} alt="Previous Team" className="w-6 mr-2" />
                                                <span className="truncate">{transfer.teams.out.name}</span>
                                            </div>
                                            <div className="flex flex-col items-center w-4/12 text-center">
                                                <i className="fas fa-long-arrow-alt-right"></i>
                                                <span>{transfer.date}</span>
                                                <span>{transfer.type}</span>
                                            </div>
                                            <div className="flex flex-row items-center justify-end w-4/12">
                                                <img src={transfer.teams.in.logo} alt="Previous Team" className="w-6 mr-2" />
                                                <span className="truncate">{transfer.teams.in.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        }
                        {transfers.data.response.length === 0 &&
                            <div className="flex items-center justify-center min-h-[100px]">
                                <span className="text-sm text-gray-400">No Data Available</span>
                            </div>
                        }
                    </>
                }
                {transfers.isLoading &&
                    <Skeleton count={5} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div>
    );
}

export default PlayerTransfers;