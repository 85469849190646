import moment from "moment";
import { useEffect, useState } from "react";

const Countdown = ({ title, date }) => {


    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const [day, setDay] = useState(0);
    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);
    const [second, setSecond] = useState(0);


    useEffect(() => {
        setInterval(() => {
            const timeBetween = moment.duration(moment(date).diff(moment()));
            setYear(timeBetween.years());
            setMonth(timeBetween.months());
            setDay(timeBetween.days());
            setHour(timeBetween.hours());
            setMinute(timeBetween.minutes());
            setSecond(timeBetween.seconds());
        }, 1000)
    }, [])


    return (
        <div className="shadow-xl card bg-slate-900">
            <div className="px-5 py-5 card-body">
                <div className="flex flex-col">
                    <div className="mb-2 text-lg font-semibold text-center text-white">{title}</div>
                    <div className="grid justify-center grid-flow-col gap-3 text-center text-white auto-cols-max">
                        <div className="flex flex-col items-center">
                            <span className="font-mono text-xl countdown">
                                <span style={{ "--value": year }}></span>
                            </span>
                            years
                        </div>
                        <div className="flex flex-col items-center">
                            <span className="font-mono text-xl countdown">
                                <span style={{ "--value": month }}></span>
                            </span>
                            months
                        </div>
                        <div className="flex flex-col items-center">
                            <span className="font-mono text-xl countdown">
                                <span style={{ "--value": day }}></span>
                            </span>
                            days
                        </div>
                        <div className="flex flex-col items-center">
                            <span className="font-mono text-xl countdown">
                                <span style={{ "--value": hour }}></span>
                            </span>
                            hours
                        </div>
                        <div className="flex flex-col items-center">
                            <span className="font-mono text-xl countdown">
                                <span style={{ "--value": minute }}></span>
                            </span>
                            min
                        </div>
                        <div className="flex flex-col items-center">
                            <span className="font-mono text-xl countdown">
                                <span style={{ "--value": second }}></span>
                            </span>
                            sec
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Countdown;