import moment from "moment";
import Skeleton from 'react-loading-skeleton';
import Fixture from "./Fixture";

const Fixtures = ({ matches }) => {

    if (matches.isSuccess && matches.data.response.length > 0) {
        const groupByArray = (xs, key) => {
            return xs.reduce(function (rv, x) {
                let v = key instanceof Function ? key(x) : x[key];
                let el = rv.find((r) => r && r.key === v);
                if (el) {
                    el.values.push(x);
                }
                else {
                    rv.push({ key: v, values: [x] });
                }
                return rv;
            }, []);
        }
    }

    return (
        <>
            <div className="w-full card bg-slate-900">
                <div className="gap-0 card-body">
                    <h2 className="text-white card-title">Matches</h2>
                    {matches.isSuccess &&
                        <>
                            {matches.data.response.length > 0 &&
                                <>
                                    {matches.data.response.sort((a, b) => moment(b.fixture.date).diff(moment(a.fixture.date))).map(fixture => (
                                        <Fixture fixture={fixture} key={fixture.fixture.id} />
                                    ))}
                                </>
                            }
                            {matches.data.response.length === 0 &&
                                <div className="flex items-center justify-center min-h-[100px]">
                                    <span className="text-sm text-gray-400">No matches +/- 7 days...</span>
                                </div>
                            }
                        </>
                    }
                    {matches.isLoading &&
                        <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                    }
                </div>
            </div>
        </>
    );
}

export default Fixtures;