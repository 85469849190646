import { db } from "firebase-configs/config";
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { getFCMToken } from "js/common";
import { UserContext } from "layouts/App";
import { useContext } from "react";
import { toast } from "react-toastify";

const Actions = ({ relatedEntityId, show }) => {

    const user = useContext(UserContext);

    const hasThisPinned = user?.userFirestore?.pinnedMatches?.includes(relatedEntityId);
    const hasThisNotifications = user?.userFirestore?.notificationMatches?.includes(relatedEntityId);

    const setFavorite = async () => {
        if (!user) {
            toast.error("Sorry, you need to be signed in to do that!");
            return;
        }
        await updateDoc(doc(db, "users", user.userFirebase.uid), {
            pinnedMatches: hasThisPinned ? arrayRemove(relatedEntityId) : arrayUnion(relatedEntityId)
        });
        toast.success(`The match has been ${hasThisPinned ? "removed" : "added"} to your favorite list!`)
    }

    const setNotifications = async () => {
        if (!user) {
            toast.error("Sorry, you need to be signed in to do that!");
            return;
        }
        if (await getFCMToken(user)) {
            await updateDoc(doc(db, "users", user.userFirebase.uid), {
                notificationMatches: hasThisNotifications ? arrayRemove(relatedEntityId) : arrayUnion(relatedEntityId)
            });
            toast.success(`You will ${hasThisNotifications ? "no longer receive" : "now receive"}  notifications for this match!`)
        } else {
            toast.error("There was an error setting up notifications. Please try again.")
        }
    }

    return (
        <>
            {show &&
                <div className="flex flex-row justify-between mt-2">
                    <div className="tooltip" data-tip="Pin this Match">
                        <i
                            className={`cursor-pointer ${hasThisPinned ? 'fas' : 'far'} fa-star fa-2x`}
                            onClick={() => setFavorite()}>
                        </i>
                    </div>
                    <div className="tooltip" data-tip="Get Notifications">
                        <i
                            className={`cursor-pointer ${hasThisNotifications ? 'fas' : 'far'} fa-bell fa-2x`}
                            onClick={() => setNotifications()}>
                        </i>
                    </div>
                </div>
            }
        </>
    );
}

export default Actions;