import Skeleton from "react-loading-skeleton";

const PlayerTrophies = ({ trophies }) => {
    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="card-body">
                <h2 className="text-white card-title">Trophies</h2>
                {trophies.isSuccess &&
                    <>
                        {trophies.data.response.length > 0 &&
                            <>
                                {trophies.data.response.map(trophy => (
                                    <div className="border-b-2 border-slate-800" key={`${trophy.league}-${trophy.season}`}>
                                        <div className="flex items-center justify-between px-2 py-2 text-sm hover:bg-slate-800 rounded-xl">
                                            <div className="flex flex-row items-center">
                                                <img src={
                                                    trophy.place === "Winner" ?
                                                        require("assets/img/timeline/trophy-icon.svg").default :
                                                        require("assets/img/timeline/second-icon.svg").default
                                                } alt="Trophy" className="w-5 mr-2" />
                                                {trophy.league}
                                            </div>
                                            <span>{trophy.season}</span>
                                        </div>
                                    </div>
                                ))}
                            </>
                        }
                    </>
                }
                {trophies.isLoading &&
                    <Skeleton count={5} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div>
    );
}

export default PlayerTrophies;