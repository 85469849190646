import { UserContext } from "layouts/App";
import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const AuthRequiredRoute = ({ children }) => {
    const user = useContext(UserContext);
    const location = useLocation();

    return user ? (
        <>{children}</>
    ) : (
        <>
            {toast.info("You need to be signed in to access that feature!")}
            <Navigate
                replace={true}
                to="/auth/login"
                state={{ from: `${location.pathname}${location.search}` }}
            />
        </>
    )
}

export default AuthRequiredRoute;