import PieChart from "components/Charts/PieChart";
import StackedBarChart from "components/Charts/StackedBarChart";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";

import colors from "tailwindcss/colors";

const MatchPlayerStats = ({ match }) => {
    const [selectedTeam, setSelectedTeam] = useState(0);
    const [selectedPlayer, setSelectedPlayer] = useState(0);

    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="gap-0 card-body">
                <h2 className="text-white card-title">Player Statistics</h2>
                <small><p className="text-white">Player Statistics</p></small>
                {match.isSuccess &&
                    <>
                        {match.data.response.length > 0 && match.data.response[0].players.length > 0 &&
                            <>
                                <div className="flex flex-col justify-center my-5 md:flex-row md:justify-between">
                                    <select
                                        className="w-full max-w-xs select select-bordered"
                                        onChange={(e) => {
                                            let values = e.target.value.split(',');
                                            setSelectedTeam(values[0])
                                            setSelectedPlayer(values[1]);
                                        }}
                                    >
                                        {match.data.response[0].players[0].players.map((player, index) => (
                                            <option value={`0,${index}`} key={player.player.id}>{player.player.name}</option>
                                        ))}
                                        <option disabled>-----</option>
                                        {match.data.response[0].players[1].players.map((player, index) => (
                                            <option value={`1,${index}`} key={player.player.id}>{player.player.name}</option>
                                        ))}
                                    </select>
                                    <div className="flex items-center justify-center gap-10 flow-row">
                                        <div className="tooltip"
                                            data-tip={match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].games.captain ? "Captain" : "Not Captain"}
                                        >
                                            <img
                                                className={`w-8 duration-200 ${match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].games.captain ? '' : 'grayscale'}`}
                                                src={require("assets/img/timeline/captain.svg").default}>
                                            </img>
                                        </div><div className="tooltip" data-tip="Number">
                                            <span className="w-8 block text-2xl font-semibold text-center text-blue-500 duration-200 cursor-default min-w-[32px]">
                                                {match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].games.number}
                                            </span>
                                        </div>
                                        <div
                                            className="tooltip"
                                            data-tip={
                                                {
                                                    'G': "Goalkeeper",
                                                    "M": "Midfielder",
                                                    'D': "Defender",
                                                    "F": "Forward"
                                                }[match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].games.position]
                                            }>
                                            <img
                                                className="w-8 duration-200"
                                                src={
                                                    {
                                                        "G": require("assets/img/timeline/yellow-shirt.svg").default,
                                                        "D": require("assets/img/timeline/blue-shirt.svg").default,
                                                        "M": require("assets/img/timeline/green-shirt.svg").default,
                                                        "F": require("assets/img/timeline/red-shirt.svg").default
                                                    }[match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].games.position]
                                                }>
                                            </img>
                                        </div>
                                        <div className="tooltip" data-tip={match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].games.substitute ? "Substitute" : "Started"}>
                                            <img
                                                className={`w-8 duration-200 ${match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].games.substitute ? '' : 'grayscale'}`}

                                                src={require("assets/img/timeline/substitution.svg").default}>
                                            </img>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap">
                                    <div className="w-full px-4 md:w-6/12">
                                        <PieChart
                                            label={match.data.response[0].players[selectedTeam].players[selectedPlayer].player.id}
                                            data={Object.entries(match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].cards)?.map((item) => ({ label: item[0].toLowerCase().replace(/\b\w/g, s => s.toUpperCase()), value: item[1] })) || []}
                                            id={'playerCards'}
                                            heading="Cards"
                                        />
                                    </div>
                                    <div className="w-full px-4 md:w-6/12">
                                        <PieChart
                                            label={match.data.response[0].players[selectedTeam].players[selectedPlayer].player.id}
                                            data={Object.entries(match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].fouls)?.map((item) => ({ label: item[0].toLowerCase().replace(/\b\w/g, s => s.toUpperCase()), value: item[1] })) || []}
                                            id={'playerFouls'}
                                            heading="Fouls"
                                        />
                                    </div>
                                    <div className="w-full px-4 md:w-6/12">
                                        <PieChart
                                            label={match.data.response[0].players[selectedTeam].players[selectedPlayer].player.id}
                                            data={Object.entries(match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].penalty)?.map((item) => ({ label: item[0].toLowerCase().replace(/\b\w/g, s => s.toUpperCase()), value: item[1] })) || []}
                                            id={'playerPenalties'}
                                            heading="Penalties"
                                        />
                                    </div>
                                    <div className="w-full px-4 md:w-6/12">
                                        <PieChart
                                            label={match.data.response[0].players[selectedTeam].players[selectedPlayer].player.id}
                                            data={Object.entries(match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].goals)?.map((item) => ({ label: item[0].toLowerCase().replace(/\b\w/g, s => s.toUpperCase()), value: item[1] })) || []}
                                            id={'playerGoals'}
                                            heading="Goals"
                                        />
                                    </div>
                                    <div className="w-full mt-10">
                                        <StackedBarChart
                                            labels={['Dribbles', 'Passes', 'Tackles', 'Duels', 'Shots']}
                                            id={'playerStats'}
                                            heading="Player Statistics"
                                            data={
                                                [
                                                    {
                                                        label: 'Success',
                                                        data: [
                                                            match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].dribbles.success || 0,
                                                            0,
                                                            0,
                                                            0,
                                                            0
                                                        ],
                                                        backgroundColor: colors.red[400],
                                                    },
                                                    {
                                                        label: 'Other',
                                                        data: [
                                                            match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].dribbles.total - (match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].dribbles.success + match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].dribbles.past) || 0,
                                                            0,
                                                            match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].tackles.total - (match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].tackles.blocks || 0 + match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].tackles.interceptions || 0) || 0,
                                                            match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].duels.total - match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].duels.won || 0,
                                                            0
                                                        ],
                                                        backgroundColor: colors.blue[500],
                                                    },
                                                    {
                                                        label: 'Normal',
                                                        data: [
                                                            0,
                                                            match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].passes.total - match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].passes.key || 0,
                                                            0,
                                                            0,
                                                            0
                                                        ],
                                                        backgroundColor: colors.purple[500],
                                                    },
                                                    {
                                                        label: 'Past',
                                                        data: [
                                                            match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].dribbles.past || 0,
                                                            0,
                                                            0,
                                                            0,
                                                            0
                                                        ],
                                                        backgroundColor: colors.cyan[500],
                                                    },
                                                    {
                                                        label: 'Key',
                                                        data: [
                                                            0,
                                                            match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].passes.key || 0,
                                                            0,
                                                            0,
                                                            0
                                                        ],
                                                        backgroundColor: colors.yellow[500],
                                                    },
                                                    {
                                                        label: 'On Target',
                                                        data: [
                                                            0,
                                                            0,
                                                            0,
                                                            0,
                                                            match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].shots.on || 0
                                                        ],
                                                        backgroundColor: colors.pink[500],
                                                    },
                                                    {
                                                        label: 'Off Target',
                                                        data: [
                                                            0,
                                                            0,
                                                            0,
                                                            0,
                                                            match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].shots.total - match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].shots.on || 0
                                                        ],
                                                        backgroundColor: colors.green[500],
                                                    },
                                                    {
                                                        label: 'Interceptions',
                                                        data: [
                                                            0,
                                                            0,
                                                            match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].tackles.interceptions || 0,
                                                            0,
                                                            0
                                                        ],
                                                        backgroundColor: colors.violet[500],
                                                    },
                                                    {
                                                        label: 'Blocks',
                                                        data: [
                                                            0,
                                                            0,
                                                            match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].tackles.block || 0,
                                                            0,
                                                            0
                                                        ],
                                                        backgroundColor: colors.orange[700],
                                                    },
                                                    {
                                                        label: 'Won',
                                                        data: [
                                                            0,
                                                            0,
                                                            0,
                                                            match.data.response[0].players[selectedTeam].players[selectedPlayer].statistics[0].duels.won || 0,
                                                            0
                                                        ],
                                                        backgroundColor: colors.teal[800],
                                                    },
                                                ]
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        {match.data.response.length > 0 && match.data.response[0].players.length === 0 &&
                            <div className="flex items-center justify-center min-h-[100px]">
                                <span className="text-sm text-gray-400">No Data Available (Yet!)</span>
                            </div>
                        }
                    </>
                }
                {match.loading &&
                    <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div >
    );
}

export default MatchPlayerStats;