import { useQuery } from "react-query";
import { Link } from "react-router-dom";

const Homepage = () => {

    const news = useQuery('news', async () => {
        let res = await fetch('https://rss-to-json-serverless-api.vercel.app/api?feedURL=https://www.espn.co.uk/espn/rss/football/news');
        return res.json();
    });

    return (
        <>
            <div id='stars'></div>
            <div id='stars2'></div>
            <div id='stars3'></div>
            {/* <div className="mt-20 md:-mx-10">
                <div className="absolute w-full overflow-hidden pointer-events-none">
                    <iframe
                        src="https://www.youtube.com/embed/4v-rIjHpyEk?controls=0&autoplay=1&mute=1&start=200&loop=1&&playlist=4v-rIjHpyEk"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        className="w-full aspect-video h-1/2 opacity-10"
                    >
                    </iframe>
                </div>
            </div> */}
            <div className="flex items-center justify-center">
                <div className="flex justify-center h-[500px] flex-col px-10 text-white pt-40">
                    <span className="text-3xl font-extrabold tracking-tight md:text-[40px] md:leading-[1.1] lg:col-span-2 lg:text-[64px] lg:leading-[1.125em] lg:tracking-[-0.0375em]">Welcome to&nbsp;
                        <span className="font-bold bg-gradient-to-r from-pink-500 via-blue-500 to-pink-500 bg-clip-text bg-2xl animate-background-pan [-webkit-text-fill-color:transparent]">
                            kicks.football
                        </span>
                        !
                    </span>
                    <p className="mt-3 text-lg tracking-tight text-gray-300">
                        Match highlights, match, player, team and league stats, and forums and pools
                    </p>
                    <p className="mt-3 text-lg tracking-tight text-gray-300">
                        Your <span className="font-semibold">one stop</span> shop for all things football
                    </p>
                    <div>
                        <Link to="football/dashboard" className="z-2">
                            <button className="btn btn-outline text-orange-400 hover:bg-orange-400 w-[200px] mt-3">Go to Dashboard</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="w-full pt-10 text-white z-2">
                {news.isSuccess && news.data.items.length > 0 &&
                    <div className="w-full px-10 md:mx-auto lg:w-10/12 2xl:w-8/12">
                        <h2 className="text-3xl font-semibold">Latests Football News</h2>
                        <div className="flex flex-col items-center mt-5">
                            <div className="w-full">
                                <div className="flex flex-col lg:flex-row">
                                    <div className="w-full my-5 lg:w-6/12 lg:px-10">
                                        <img src={news.data.items[0].enclosures[0]?.url} alt={news.data.items[0].title} />
                                    </div>
                                    <div className="w-full my-5 lg:w-6/12 gl:px-10">
                                        <span className="mb-5 badge">News of the day</span>
                                        <br />
                                        <a href={news.data.items[0]?.url} target="_blank" rel="noreferrer">
                                            <span className="text-3xl font-semibold hover:underline">{news.data.items[0].title}</span>
                                        </a>
                                        <p>{news.data.items[0].description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
                                {news.data.items.slice(1).map(article => (
                                    <div className="flex flex-col py-5 my-5 border-b-2 border-slate-400" key={article.id}>
                                        {article.enclosures.length > 0 &&
                                            <img src={article.enclosures[0]?.url} alt={article.title} className="mb-5" />
                                        }
                                        <a href={article.url} target="_blank" rel="noreferrer">
                                            <span className="text-3xl font-semibold hover:underline">{article.title}</span>
                                        </a>
                                        {article.description !== "null" &&
                                            <p>{article.description}</p>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default Homepage;