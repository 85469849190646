import { db } from "firebase-configs/config";
import { doc, updateDoc } from "firebase/firestore";
import { UserContext } from "layouts/App";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
var filter = require('leo-profanity');

export default function AdditionalUserInfo() {

    const [username, setUsername] = useState("");
    const [receiveNews, setReceiveNews] = useState(false);
    const [loading, setLoading] = useState(false);

    const user = useContext(UserContext);

    const navigate = useNavigate();

    const handleAdditionalInfo = async (e) => {
        e.preventDefault();
        if (filter.check(username)) {
            toast.error("Please check your username. Profanity is not allowed in usernames.")
            return;
        }
        try {
            setLoading(true);
            await updateDoc(doc(db, "users", user.userFirebase.uid), {
                username: username,
                receiveNews: receiveNews
            });
            setLoading(false);
            navigate('/');

            toast.success("Your username was successfully updated!");
        } catch (error) {
            toast.error("There was an error updating your username");
        } finally {
            setUsername("");
        }
    }

    return (
        <>
            <div className="container h-full px-4 py-4 mx-auto">
                <div className="flex items-center content-center justify-center h-full">
                    <div className="w-full px-4 lg:w-4/12">
                        <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-slate-200">
                            <div className="flex-auto px-4 py-10 lg:px-10">
                                <div className="mb-3 text-lg font-semibold">Addition Info</div>
                                <div className="mb-3 text-sm">Please provide a username to participate in forums and chats.</div>
                                <form onSubmit={handleAdditionalInfo}>
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block mb-2 text-xs font-bold uppercase text-slate-600"
                                            htmlFor="grid-password"
                                        >
                                            Username
                                        </label>
                                        <input
                                            required
                                            type="username"
                                            className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-slate-300 text-slate-600 focus:outline-none focus:ring"
                                            placeholder="Provide a username..."
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                        {filter.check(username) &&
                                            <label className="label">
                                                <span className="text-red-600 label-text-alt">Try something else...</span>
                                            </label>
                                        }
                                    </div>
                                    <div className="my-1">
                                        <label className="inline-flex items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                value={receiveNews}
                                                onChange={(e) => setReceiveNews(e.target.checked)}
                                                className="w-5 h-5 ml-1 transition-all duration-150 ease-linear border-0 rounded form-checkbox text-slate-700"
                                            />
                                            <span className="ml-2 text-sm font-semibold text-slate-600">
                                                Receive occasional site news and promotions
                                            </span>
                                        </label>
                                    </div>
                                    <div className="mt-6 text-center">
                                        <button
                                            className="flex items-center justify-center w-full px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-slate-800 active:bg-slate-600 hover:shadow-lg focus:outline-none"
                                            type="submit"
                                            disabled={filter.check(username)}
                                        >
                                            Submit
                                            {loading &&
                                                <span className="my-auto ml-2 loading loading-spinner loading-sm"></span>
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="relative flex flex-wrap mt-6">
                            <div className="w-1/2">
                                <Link to="/auth/login" className="text-slate-200">
                                    <small>Sign In</small>
                                </Link>
                            </div>
                            <div className="w-1/2 text-right">
                                <Link to="/auth/register" className="text-slate-200">
                                    <small>Create new account</small>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
