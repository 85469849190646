import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const TeamTransfers = ({ transfers, teamId }) => {
    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="card-body">
                <h2 className="text-white card-title">Recent Transfers</h2>
                {transfers.isSuccess &&
                    <>
                        {transfers.data.response.length > 0 &&
                            <>
                                {transfers.data.response.map(transfer => {
                                    transfer.transfers.map(innerTransfer => {
                                        innerTransfer.player = transfer.player;
                                    })
                                    return transfer.transfers;
                                }).flat().sort((a, b) => a.date < b.date ? 1 : -1).slice(0, 21).map(transfer => (
                                    <div className="flex py-2 border-b-2 border-slate-800" key={`${transfer.player.id}${transfer.teams.out.id}${transfer.teams.in.id}`}>
                                        <div className="flex flex-col items-center justify-center w-4/12 text-center md:flex-row md:justify-start">
                                            <img src={transfer.teams.out.logo} alt="Transfered from" className="w-6 mr-2" />
                                            <span>{transfer.teams.out.name}</span>
                                        </div>
                                        <div className="flex flex-col items-center w-4/12">
                                            <span>
                                                <i
                                                    className={`fas fa-long-arrow-alt-right fa-lg ${transfer.teams.out.id == teamId ? 'text-red-600' : 'text-green-600'}`}>
                                                </i>
                                            </span>
                                            <span>{transfer.type}</span>
                                            <span>{transfer.date}</span>
                                            <Link to={`../player/${transfer.player.id}`} className="hover:underline">
                                                <span className="text-center">{transfer.player.name}</span>
                                            </Link>
                                        </div>
                                        <div className="flex flex-col items-center justify-center w-4/12 text-center md:flex-row md:justify-end">
                                            <img src={transfer.teams.in.logo} alt="Transfered to" className="w-6 mr-2" />
                                            <span>{transfer.teams.in.name}</span>
                                        </div>
                                    </div>
                                ))}
                            </>
                        }
                        {transfers.data.response.length === 0 &&
                            <div className="flex items-center justify-center min-h-[100px]">
                                <span className="text-sm text-gray-400">No Data Available</span>
                            </div>
                        }
                    </>
                }
                {transfers.isLoading &&
                    <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div>
    );
}

export default TeamTransfers;