import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import FixtureMiniCard from "components/Cards/Football/FixtureMiniCard";
import { db } from "firebase-configs/config";
import { collection, orderBy, query, where } from "firebase/firestore";
import { fetchFootballData } from "js/common";
import moment from "moment";
import { useQuery } from "react-query";

export default function HeaderStats() {

    var oneWeekAgo = moment().subtract(7, 'd').format('YYYY-MM-DD');
    var oneWeekLater = moment().add(20, 'd').format('YYYY-MM-DD');
    const hotMatches = useFirestoreQueryData("hot_matches", query(collection(db, "hot_matches"), where('date', '>', new Date(oneWeekAgo)), where('date', '<', new Date(oneWeekLater)), orderBy('date')), { subscribe: true });

    const matches = useQuery("hotMatches", () => fetchFootballData("fixtures", `?ids=${hotMatches.data.map(doc => doc.fixtureid).join('-')}`), {
        enabled: hotMatches.isSuccess && hotMatches.data.length > 0
    });

    return (
        <>
            <div className="ml-2 text-xl font-semibold text-white">
                Hot Matches
            </div>
            <div className="relative flex gap-5 py-3 overflow-x-scroll overflow-y-hidden scrollbar-hide md:flex-wrap">
                {matches.isSuccess &&
                    <>
                        {matches.data.response.map(fixture => (
                            <FixtureMiniCard fixture={fixture} />
                        ))}
                    </>
                }
            </div>
        </>
    );
}
