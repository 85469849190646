import { useState } from "react";

const VideoHighlightCard = ({ video, setSelectedHighlight }) => {

    const [isHovered, setIsHovered] = useState(false);
    return (
        <>
            <label htmlFor="video-highlight-modal">
                <div
                    onMouseEnter={() => { setIsHovered(true) }}
                    onMouseLeave={() => { setIsHovered(false) }}
                    className={`mx-2 ${isHovered ? "scale-125 z-3" : ""} duration-300 cursor-pointer`}
                    onClick={() => setSelectedHighlight(video.title)}
                >
                    <div className="w-72">
                        <img src={video.thumbnail} />
                    </div>
                    {isHovered &&
                        <div className="text-center text-white bg-slate-800">
                            <p>{video.title}</p>
                        </div>
                    }
                </div>
            </label>
        </>
    );

}

export default VideoHighlightCard;