import { db } from "firebase-configs/config";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { sanitize } from "js/common";
import { UserContext } from "layouts/App";
import moment from "moment";
import { useContext, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from 'react-toastify';
var filter = require('leo-profanity');

const LiveChat = ({ chatMessages, relatedEntity }) => {

    const user = useContext(UserContext);

    const chatMessageInput = useRef();
    const submitChatMessage = async (message) => {
        if (!user) {
            toast.error("Sorry, you need to be signed in to do that!");
            chatMessageInput.current.value = '';
            return;
        }
        if (user && !user.userFirestore?.username) {
            toast.error("You need to set a username before you can post");
            chatMessageInput.current.value = '';
            return;
        }
        chatMessageInput.current.value = '';
        await addDoc(collection(db, "chat_message"), {
            text: message,
            fixture: relatedEntity,
            createdOn: serverTimestamp(),
            userId: user.userFirebase.uid,
            username: user.userFirestore.username
        });
    };
    return (
        <div className="card w-full bg-slate-900 text-white h-[650px]">
            <div className="h-full gap-0 card-body">
                <h2 className="text-white card-title">Live Chat</h2>
                <small><p className="text-white">By using this chat you agree to the terms that can be found here. Breaking these terms can result in a ban from live chat or kicks.football entirely. <strong>An account is required to participate.</strong></p></small>
                <div className="flex items-center">
                    <input
                        type="text"
                        placeholder="enter chat message..."
                        className="w-full my-2 text-white bg-transparent border-2 input border-slate-700"
                        ref={chatMessageInput}
                        onKeyDown={(e) => e.key === "Enter" ? submitChatMessage(chatMessageInput.current.value) : ''}
                    />
                    <div className="ml-2 cursor-pointer" onClick={() => submitChatMessage(chatMessageInput.current.value)}>
                        <i className="fas fa-paper-plane"></i>
                    </div>
                </div>
                <div className="px-2 overflow-y-auto">
                    {chatMessages.isSuccess &&
                        <>
                            {chatMessages.data.size > 0 &&
                                <>
                                    {chatMessages.data.docs.slice().reverse().map(doc => (
                                        <div
                                            key={doc.id}
                                            className={`chat ${doc.data().userId && doc.data().userId === user?.userFirebase?.uid ? 'chat-end' : 'chat-start'}`}
                                        >
                                            <div
                                                className={`chat-bubble ${doc.data().userId && doc.data().userId === user?.userFirebase?.uid ? 'chat-bubble-info' : 'chat-bubble-error'}`}
                                            >
                                                {sanitize(doc.data().text)}
                                            </div>
                                            <div className="chat-footer">
                                                {doc.data().userId !== user?.userFirebase?.uid &&
                                                    <span>{sanitize(doc.data().username)} </span>
                                                }
                                                <time className="text-xs opacity-50">{moment(doc.data().createdOn?.toDate()).fromNow()}</time>
                                            </div>

                                        </div>
                                    ))}
                                </>
                            }
                            {chatMessages.data.size === 0 &&
                                <div className="flex items-center justify-center h-[300px]">
                                    <span className="text-sm text-gray-400">Nothing yet... Post something!</span>
                                </div>
                            }
                        </>
                    }
                    {chatMessages.isLoading &&
                        <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                    }
                </div>
            </div>
        </div>
    );
}

export default LiveChat;