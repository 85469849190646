import Skeleton from "react-loading-skeleton";

const TeamVenue = ({ team }) => {
    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="card-body">
                <h2 className="text-white card-title">Home Stadium</h2>
                {team.isSuccess &&
                    <>
                        {team.data.response.length > 0 && (team.data.response[0].venue.capacity || team.data.response[0].venue.surface) &&
                            <div className="flex flex-col">
                                <div className="flex items-center justify-between w-full py-2 border-b-2 border-slate-800">
                                    <span>Capacity</span>
                                    <div className="flex items-center">
                                        <span className="mr-2 text-2xl">
                                            <i className={`fas fa-child ${team.data.response[0].venue.capacity > 1000 ? 'text-white' : 'text-gray-400'}`}></i>
                                            <i className={`fas fa-child ${team.data.response[0].venue.capacity > 10000 ? 'text-white' : 'text-gray-400'}`}></i>
                                            <i className={`fas fa-child ${team.data.response[0].venue.capacity > 20000 ? 'text-white' : 'text-gray-400'}`}></i>
                                            <i className={`fas fa-child ${team.data.response[0].venue.capacity > 40000 ? 'text-white' : 'text-gray-400'}`}></i>
                                            <i className={`fas fa-child ${team.data.response[0].venue.capacity > 80000 ? 'text-white' : 'text-gray-400'}`}></i>
                                        </span>
                                        {team.data.response[0].venue.capacity}
                                    </div>
                                </div>
                                <div className="flex items-center justify-between w-full py-2 border-b-2 border-slate-800">
                                    <span>Surface</span>
                                    <span>
                                        {team.data.response[0].venue.surface === "grass" &&
                                            <img
                                                src={require("assets/img/timeline/surface-grass.svg").default}
                                                alt="surface grass"
                                                className="inline w-8 mr-2"
                                            />
                                        }
                                        {team.data.response[0].venue.surface}
                                    </span>
                                </div>
                            </div>
                        }
                        {team.data.response.length === 0 || (!team.data.response[0].venue.capacity && !team.data.response[0].venue.surface) &&
                            <div className="flex items-center justify-center min-h-[100px]">
                                <span className="text-sm text-gray-400">No Data Available</span>
                            </div>
                        }
                    </>
                }
                {team.isLoading &&
                    <Skeleton count={2} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div>
    );
}

export default TeamVenue;