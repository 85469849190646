
export default function FooterAdmin() {
    return (
        <>
            <footer className="block py-4">
                <div className="container px-4 pb-10 mx-auto md:pb-0">
                    <hr className="mb-4 border-b-1 border-slate-200" />
                    <div className="flex flex-wrap items-center justify-center md:justify-between">
                        <div className="px-4">
                            <div className="py-1 text-sm font-semibold text-center text-white">
                                Made with <i className="fas fa-heart"></i> by Brandon
                            </div>
                        </div>
                        <div className="px-4">
                            <ul className="flex flex-wrap justify-center list-none md:justify-end">
                                <li>
                                    <a
                                        href="#"
                                        className="block px-3 py-1 text-sm font-semibold text-white hover:text-gray-400"
                                    >
                                        Terms of Service
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        className="block px-3 py-1 text-sm font-semibold text-white hover:text-gray-400"
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        className="block px-3 py-1 text-sm font-semibold text-white hover:text-gray-400"
                                    >
                                        Code of Conduct
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer >
        </>
    );
}
