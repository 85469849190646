import PlayerHeader from "components/Cards/Football/PlayerHeader";
import PlayerPhysicalAtributes from "components/Cards/Football/PlayerPhysicalAttributes";
import PlayerSquads from "components/Cards/Football/PlayerSquads";
import PlayerStats from "components/Cards/Football/PlayerStats";
import PlayerTransfers from "components/Cards/Football/PlayerTransfers";
import PlayerTrophies from "components/Cards/Football/PlayerTrophies";
import { fetchFootballData } from "js/common";
import { useQuery } from "react-query";
import { useParams } from "react-router";

const Player = () => {
    const { playerId } = useParams();
    const seasons = useQuery(['players/seasons', playerId], () => fetchFootballData('players/seasons', `?player=${playerId}`), { staleTime: Infinity, cacheTime: Infinity });
    const player = useQuery(['player', playerId], () => fetchFootballData('players', `?id=${playerId}&season=${seasons.data.response[seasons.data.response.length - 1]}`), {
        enabled: seasons.data?.response?.length > 0
    });
    const trophies = useQuery(['trophies', playerId], () => fetchFootballData('trophies', `?player=${playerId}`));
    const transfers = useQuery(['players/tranfers', playerId], () => fetchFootballData('transfers', `?player=${playerId}`));
    const squads = useQuery(['players/squads', playerId], () => fetchFootballData('players/squads', `?player=${playerId}`));

    return (
        <>
            <div className="flex flex-col">
                <div className="w-full px-3">
                    <PlayerHeader player={player} trophies={trophies} squads={squads} transfers={transfers} />
                </div>
                <div className="flex flex-col w-full lg:flex-row">
                    <div className="w-full px-3 pt-3 lg:w-6/12">
                        <PlayerSquads squads={squads} />
                    </div>
                    <div className="w-full px-3 pt-3 lg:w-6/12">
                        <PlayerPhysicalAtributes player={player} />
                    </div>
                </div>
                <div className="w-full px-3 pt-3">
                    <PlayerStats player={player} seasons={seasons} />
                </div>
                <div className="flex flex-col w-full xl:flex-row">
                    <div className="w-full px-3 pt-3 xl:w-6/12">
                        <PlayerTransfers transfers={transfers} />
                    </div>
                    <div className="w-full px-3 pt-3 xl:w-auto xl:grow">
                        <PlayerTrophies trophies={trophies} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Player;