import RadarChart from "components/Charts/RadarChart";
import Skeleton from "react-loading-skeleton";

const MatchPredictions = ({ predictions }) => {
    return (
        <div className="w-full text-white card bg-slate-900">
            <div className="gap-0 card-body">
                <h2 className="text-white card-title">Head to Head Statistics</h2>
                <small><p className="text-white">See how these teams stack up</p></small>
                {predictions.isSuccess &&
                    <>
                        {predictions.data.response.length > 0 &&
                            <div className="flex flex-col md:flex-row">
                                <div className="w-full md:w-6/12">
                                    <RadarChart
                                        id="h2hRadarChart"
                                        labels={["Attack", "Defense", "Stength", "Goals For", "Goals Against"]}
                                        data={{
                                            home: [
                                                predictions.data.response[0].teams.home.last_5.att.replace('%', ''),
                                                predictions.data.response[0].teams.home.last_5.def.replace('%', ''),
                                                predictions.data.response[0].teams.home.last_5.form.replace('%', ''),
                                                predictions.data.response[0].teams.home.last_5.goals.for.total / 20 * 100,
                                                predictions.data.response[0].teams.home.last_5.goals.against.total / 20 * 100
                                            ],
                                            away: [
                                                predictions.data.response[0].teams.away.last_5.att.replace('%', ''),
                                                predictions.data.response[0].teams.away.last_5.def.replace('%', ''),
                                                predictions.data.response[0].teams.away.last_5.form.replace('%', ''),
                                                predictions.data.response[0].teams.away.last_5.goals.for.total / 20 * 100,
                                                predictions.data.response[0].teams.away.last_5.goals.against.total / 20 * 100
                                            ]
                                        }}
                                    />
                                </div>
                                <div className="w-full md:w-6/12">
                                    <div className="flex flex-col px-5">
                                        {Object.keys(predictions.data.response[0].comparison).map((stat, index) => (
                                            <div key={stat}>
                                                <div className="text-lg font-bold text-center">
                                                    {stat === "poisson_distribution" &&
                                                        <div className="block tooltip md:inline" data-tip='Poisson distribution is used to estimate how likely it is that something will happen "X" number of times'>
                                                            <i className="mr-2 fas fa-info-circle cursor-help"></i>
                                                        </div>
                                                    }
                                                    {
                                                        {
                                                            "form": "Strength",
                                                            "att": "Attacking Potential",
                                                            "def": "Defensive Potential",
                                                            "poisson_distribution": "Poisson Distribution",
                                                            "h2h": "Strength H2H",
                                                            "goals": "Goals H2H",
                                                            "total": "Total"
                                                        }[stat]
                                                    }
                                                </div>
                                                <div className="flex flex-row items-center w-full">
                                                    <div className="flex flex-row items-center w-full">
                                                        <div className="self-start mr-auto text-lg font-bold">
                                                            {Object.values(predictions.data.response[0].comparison)[index].home}
                                                        </div>
                                                        <div
                                                            className="h-2 ml-3 bg-red-400 rounded-l-xl"
                                                            style={{ width: `${Object.values(predictions.data.response[0].comparison)[index].home}` }}
                                                        >
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row items-center w-full">
                                                        <div
                                                            className="h-2 mr-3 bg-blue-400 rounded-r-xl"
                                                            style={{ width: `${Object.values(predictions.data.response[0].comparison)[index].away}` }}
                                                        >
                                                        </div>
                                                        <div className="self-start ml-auto text-lg font-bold">
                                                            {Object.values(predictions.data.response[0].comparison)[index].away}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
                {predictions.isLoading &&
                    <Skeleton count={10} baseColor='rgb(39 48 62)' highlightColor='#362d58' borderRadius={"0.3rem"} inline={true} height={"20px"} style={{ marginTop: "6px", marginBottom: "6px" }} />
                }
            </div>
        </div>
    );
}

export default MatchPredictions;