import {
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    getAdditionalUserInfo,
    getRedirectResult,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithRedirect,
    signOut,
    updateProfile
} from "firebase/auth";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { auth, db } from "./config";

//create user using email and password
export const createUserEmailPassword = async (name, email, password, username, receiveNews) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await setDoc(doc(db, "users", userCredential.user.uid), {
            createdOn: serverTimestamp(),
            username: username,
            receiveNews: receiveNews
        });
        await updateProfile(userCredential.user, {
            displayName: name
        });
        sendEmailVerification(userCredential.user);
    } catch (error) {
        throw error;
    }
}

//create user using google sign in
export const signInGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
        await signInWithRedirect(auth, provider);
    } catch (error) {
        throw error;
    }
}

// if using external provider, check if page load is from a redirect
export const redirectResult = async (isNotNewUserCallback, isNewUserCallback) => {
    try {
        const result = await getRedirectResult(auth);
        if (result) {
            const details = getAdditionalUserInfo(result);
            toast.info(`Welcome ${result.user.displayName}, you've signed in successfully!`);
            if (details.isNewUser === true) {
                setDoc(doc(db, "users", result.user.uid), {
                    createdOn: serverTimestamp()
                });
                isNewUserCallback();
                return;
            }
            isNotNewUserCallback();
        }
    } catch (error) {
        if (error.code === "auth/user-disabled")
            toast.error("You account has been disabled. Please contact support to resolve this issue.", { autoClose: 20000 });
        throw error;
    }
}

export const signOutUser = async () => {
    signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((error) => {
        // An error happened.
    });
}

export const signInWithEmailPassword = async (email, password) => {
    try {
        return await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
        throw error;
    }
}

export const resetPassword = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
    } catch (error) {
        throw error;
    }
}
