import { db } from "firebase-configs/config";
import { collection, getCountFromServer, query, where } from "firebase/firestore";
import { UserContext } from "layouts/App.js";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Profile() {

    const user = useContext(UserContext);
    const [postsCount, setPostsCount] = useState();
    const [commentsCount, setCommentsCount] = useState();
    const [chatsCount, setChatsCount] = useState();

    useEffect(() => {
        const posts = query(collection(db, "forum_posts"), where("userId", "==", user?.userFirebase?.uid));
        getCountFromServer(posts).then(snapshot => {
            setPostsCount(snapshot.data().count || 0)
        });
        const comments = query(collection(db, "forum_posts_comments"), where("userId", "==", user?.userFirebase?.uid));
        getCountFromServer(comments).then(snapshot => {
            setCommentsCount(snapshot.data().count || 0)
        });
        const chats = query(collection(db, "chat_message"), where("userId", "==", user?.userFirebase?.uid));
        getCountFromServer(chats).then(snapshot => {
            setChatsCount(snapshot.data().count || 0)
        });
    }, [user])

    return (
        <>
            <Helmet>
                <title>Profile</title>
            </Helmet>
            <main className="profile-page">
                <section className="relative block h-500-px bg-slate-500 rounded-3xl">
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover rounded-3xl"
                        style={{
                            backgroundImage:
                                "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')",
                        }}
                    >
                        <span
                            id="blackOverlay"
                            className="absolute w-full h-full bg-black opacity-50 rounded-3xl"
                        ></span>
                    </div>
                    <div
                        className="absolute bottom-0 left-0 right-0 top-auto w-full overflow-hidden pointer-events-none h-70-px bg-slate-500"
                        style={{ transform: "translateZ(0)" }}
                    >
                    </div>
                </section>
                <section className="relative py-16 bg-slate-500">
                    <div className="container px-4 mx-auto">
                        <div className="relative flex flex-col w-full min-w-0 mb-6 -mt-64 break-words rounded-lg shadow-xl bg-slate-900">
                            <div className="px-6">
                                <div className="flex flex-wrap justify-center">
                                    <div className="flex justify-center w-full px-4 lg:w-3/12 lg:order-2">
                                        <div className="relative">
                                            <img
                                                alt="Profile Image"
                                                src={user?.userFirebase?.photoURL || require("assets/img/user_icon.svg").default}
                                                className="shadow-xl rounded-full h-auto align-middle border-none object-center absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px w-[150px] align-self-center"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full px-4 lg:w-4/12 lg:order-3 lg:text-right lg:self-center">
                                        <div className="px-3 py-6 mt-32 sm:mt-0">
                                            <Link to="/settings"
                                                className="px-4 py-2 mb-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-sky-700 hover:bg-sky-800 hover:shadow-md focus:outline-none sm:mr-2"
                                                type="button"
                                            >
                                                Settings
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="w-full px-4 lg:w-4/12 lg:order-1">
                                        <div className="flex justify-center py-4 pt-8 lg:pt-4">
                                            <div className="p-3 mr-4 text-center">
                                                <span className="block text-xl font-bold tracking-wide text-white uppercase">
                                                    {postsCount}
                                                </span>
                                                <span className="text-sm text-white">
                                                    Forum Posts
                                                </span>
                                            </div>
                                            <div className="p-3 mr-4 text-center">
                                                <span className="block text-xl font-bold tracking-wide text-white uppercase">
                                                    {commentsCount}
                                                </span>
                                                <span className="text-sm text-white">
                                                    Comments
                                                </span>
                                            </div>
                                            <div className="p-3 text-center lg:mr-4">
                                                <span className="block text-xl font-bold tracking-wide text-white uppercase">
                                                    {chatsCount}
                                                </span>
                                                <span className="text-sm text-white">
                                                    Chat Messages
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pb-10 mt-12 text-center">
                                    <h3 className="mb-2 text-4xl font-semibold leading-normal text-white">
                                        {user.userFirebase.displayName}
                                    </h3>
                                    <h6 className="mb-2 text-2xl font-semibold text-white">
                                        {user.userFirestore?.username}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
